import {
  faImage as fasImage,
  faFilePdf as fasFilePdf,
  faFileExcel as fasFileExcel,
  faFileWord as fasFileWord,
  faFile as fasFile,
} from "@fortawesome/pro-solid-svg-icons";
import {
  faImage as falImage,
  faFilePdf as falFilePdf,
  faFileExcel as falFileExcel,
  faFileWord as falFileWord,
  faFile as falFile,
} from "@fortawesome/pro-light-svg-icons";

/**
 * @typedef {import("@fortawesome/fontawesome-common-types").IconDefinition} IconDefinition
 */

export function getActiveTableAndViewUuidFromState(state) {
  const currentTable = state.activeTable.currentTable;
  const currentViewUuid = state.activeTable.currentViewUuid;

  return {
    currentTable,
    currentViewUuid,
  };
}

export function getActiveTableFields(currentActiveTable, currentViewUuid) {
  const views = currentActiveTable.views ?? [];

  const view = views.find((v) => v.uuid === currentViewUuid) ?? views[0];
  return (
    view?.visibleFields.map((v) => v.field) ??
    currentActiveTable.columns.map((c) => c.name)
  );
}

export function getActiveTableFieldsFromState(state) {
  const { currentTable, currentViewUuid } =
    getActiveTableAndViewUuidFromState(state);
  return getActiveTableFields(currentTable, currentViewUuid);
}

/**
 * @param columnType
 * @returns {{accept: ?string, apiType: ?string, solid_icon: IconDefinition, light_icon: IconDefinition}}
 */
export function getActiveTableFileTypeConfig(columnType) {
  return (
    ACTIVE_TABLE_FILE_TYPE_MAP[columnType] ?? {
      solid_icon: fasFile,
      light_icon: falFile,
      accept: undefined,
      apiType: undefined,
    }
  );
}

export const ACTIVE_TABLE_FILE_TYPE_MAP = {
  image: {
    solid_icon: fasImage,
    light_icon: falImage,
    accept: toAcceptString(["jpg", "jpeg", "png", "gif", "bmp", "svg", "webp"]),
    apiType: "image",
  },
  "api-pdf": {
    solid_icon: fasFilePdf,
    light_icon: falFilePdf,
    accept: toAcceptString(["pdf"]),
    apiType: "api-pdf",
  },
  "api-excel": {
    solid_icon: fasFileExcel,
    light_icon: falFileExcel,
    accept: toAcceptString(["xlsx", "xls"]),
    apiType: "api-excel",
  },
  "api-word": {
    solid_icon: fasFileWord,
    light_icon: falFileWord,
    accept: toAcceptString(["docx", "doc"]),
    apiType: "api-word",
  },
};

function toAcceptString(extensions) {
  return extensions ? extensions.map((v) => `.${v}`).join(",") : undefined;
}
