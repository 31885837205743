import Select from "../../../UI/Form/Select/Select";

export default function SplitBy({
  columns,
  handleSplit,
  splitBy,
  label = "Split",
}) {
  const noSplit = { value: null, label: "None..." };
  const options = columns.map(({ field }) => ({ value: field, label: field }));
  const displayOptions = [noSplit, ...options];

  const optionValue =
    typeof splitBy === "string"
      ? displayOptions.find((option) => option.value === splitBy)
      : splitBy;

  return (
    <div style={{ width: 200, position: "relative", top: -14 }}>
      <Select
        options={displayOptions}
        label={label}
        onChange={handleSplit}
        value={optionValue}
        cy="split-select"
        placeholder="Select..."
      />
    </div>
  );
}
