import styled from "@emotion/styled";
import { useTheme } from "emotion-theming";
import QuarterRange from "./QuarterRange";
import SingleDay from "./SingleDay";
import CustomRange from "./CustomRange";
import FutureDaysAndEarlier from "./FutureDaysAndEarlier";

const Container = styled.div(
  ({ theme }) => `
    margin-top: 24px;
    left: 6px;
    & > span {
        color: ${theme.menuPrimary ? theme.textOnPrimary : theme.text.primary};
        padding: 0px 12px;
        font-size: 0.7em;
        margin-left: 10px;
        text-transform: uppercase;

        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }
`
);

export default function CustomDateFilters({
  dateType,
  recentMonthsChange,
  RecentPeriods,
  dateFiltersConfig,
  getPosition,
  dateConfig,
  domain,
}) {
  const theme = useTheme();

  const customElements = {
    sd: (
      <SingleDay
        recentMonthsChange={recentMonthsChange}
        Container={Container}
        theme={theme}
      />
    ),
    cr: (
      <Container>
        <CustomRange dateConfig={dateConfig} />
      </Container>
    ),
    fiscalQuarterRange: (
      <QuarterRange
        dateFiltersConfig={dateFiltersConfig}
        RecentPeriods={RecentPeriods}
        getPosition={getPosition}
        quarterChange={recentMonthsChange}
      />
    ),

    past3YearsToNextDays: (
      <Container>
        <FutureDaysAndEarlier
          recentMonthsChange={recentMonthsChange}
          domain={domain}
        />
      </Container>
    ),
  };

  return customElements[dateType] ?? null;
}
