import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Input from "../../../UI/Form/Input/Input";
import SubTitles from "./SubTitles";

const Container = styled.div(
  ({ isDragging, theme }) => `
  display: flex;
  gap: 1rem;
  padding-top: 1rem;
  ${isDragging && `border: 2px dashed ${theme.primary}`};
`
);

const Grouping = styled.div`
  position: relative;
`;

export default function StaticGroupingKeys({
  staticGroupingKeys,
  removeGroupingEffect,
  setChartState,
  Section,
  removeColumn,
  subTitles,
  setSelectedGroup,
  selectedGroup,
  overrides,
  setSelectedColumn,
  selectedColumn,
  dynamicColumnsWidth,
  generalColumnsWidth,
  maxColumnsWidth,
  dynamicGroups,
  romveColumnEffect,
  setNavigationState,
  chart,
  fields,
}) {
  const [isDragging, setIsDragging] = useState(false);

  const onSectionDragEnd = useCallback(
    ({ destination, source }) => {
      if (!destination) {
        return;
      }

      const start = source.index;
      const end = destination.index;

      const groups = dynamicGroups ? [] : [...staticGroupingKeys];
      const columns = [...subTitles];

      const [draggedGroup] = groups.splice(start, 1);
      const [draggedColumn] = columns.splice(start, 1);

      if (!dynamicGroups) {
        groups.splice(end, 0, draggedGroup);
      }

      columns.splice(end, 0, draggedColumn);

      setChartState({
        ...chart,
        ...(!dynamicGroups && { staticGroupingKeys: groups }),
        subTitles: columns,
      });

      setIsDragging(false);
    },
    [chart, dynamicGroups, setChartState, staticGroupingKeys, subTitles]
  );

  const selectGrouping = useCallback(
    (e, group, groupIndex) => {
      e.stopPropagation();
      setSelectedGroup((prev) =>
        group + "_" + groupIndex === prev ? null : group + "_" + groupIndex
      );
      setSelectedColumn && setSelectedColumn(null);
    },
    [setSelectedColumn, setSelectedGroup]
  );

  const removeGrouping = (e, groupIndex) => {
    e.stopPropagation();
    setChartState({
      ...chart,
      staticGroupingKeys: chart.staticGroupingKeys.filter(
        (_, i) => i !== groupIndex
      ),
      // when we remove grouping we also need to remove columns under this grouping
      subTitles: chart.subTitles.filter((_, i) => i !== groupIndex),
    });
  };

  const onDynamicGroupingChange = (e, group, groupIndex) => {
    setChartState({
      ...chart,
      staticGroupingKeys: chart.staticGroupingKeys.map((g, i) =>
        i === groupIndex
          ? group.includes("::BORDERED")
            ? e.target.value + "::BORDERED"
            : e.target.value
          : g
      ),
    });
  };

  return (
    <DragDropContext
      onDragEnd={onSectionDragEnd}
      onDragStart={() => setIsDragging(true)}
    >
      <Droppable droppableId="droppable" direction="horizontal">
        {(provided) => (
          <Container
            {...provided.droppableProps}
            ref={provided.innerRef}
            isDragging={isDragging}
          >
            {(dynamicGroups ?? staticGroupingKeys).map((group, groupIndex) => (
              <Draggable
                key={"group" + groupIndex}
                draggableId={`group${groupIndex}`}
                index={groupIndex}
              >
                {(provided, snapshot) => (
                  <Grouping
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <Section
                      shake={removeGroupingEffect}
                      isActive={selectedGroup === group + "_" + groupIndex}
                      onClick={(e) => selectGrouping(e, group, groupIndex)}
                      color="white"
                    >
                      {removeGroupingEffect && (
                        <FontAwesomeIcon
                          icon={["fas", "times-circle"]}
                          onClick={(e) => removeGrouping(e, groupIndex)}
                        />
                      )}
                      {!dynamicGroups ? (
                        <Input
                          value={(group ?? "").replace("::BORDERED", "")}
                          placeholder="<Empty>"
                          inPlaceEdit
                          ignoreValue
                          hideEditIcon
                          onChange={(e) =>
                            onDynamicGroupingChange(e, group, groupIndex)
                          }
                        />
                      ) : (
                        <span>{(group ?? "").replace("::BORDERED", "")}</span>
                      )}
                    </Section>
                    {subTitles && (
                      <SubTitles
                        staticGroupingKeys={dynamicGroups || staticGroupingKeys}
                        removeGroupingEffect={
                          removeGroupingEffect || romveColumnEffect
                        }
                        removeColumn={removeColumn}
                        subTitles={subTitles}
                        groupIndex={groupIndex}
                        setChartState={setChartState}
                        overrides={overrides}
                        setSelectedColumn={setSelectedColumn}
                        selectedColumn={selectedColumn}
                        dynamicColumnsWidth={dynamicColumnsWidth}
                        generalColumnsWidth={generalColumnsWidth}
                        maxColumnsWidth={maxColumnsWidth}
                        setSelectedGroup={setSelectedGroup}
                        setNavigationState={setNavigationState}
                        chart={chart}
                        fields={fields}
                      />
                    )}
                  </Grouping>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </Container>
        )}
      </Droppable>
    </DragDropContext>
  );
}
