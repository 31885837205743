import React from "react";
import styled from "@emotion/styled";

const Line = styled.line`
  stroke: ${(props) => props.color || props.theme.successLight};
  stroke-width: ${(props) => props.thickness || 4};
`;
// const Circle = styled.circle`
//   fill: ${props => props.theme.successLight};
// `;

export default function TargetLine(props) {
  const yPos = props.y(props.target);
  return (
    <g>
      <Line
        x1={0}
        y1={yPos}
        x2={props.width}
        y2={yPos}
        thickness={props.thickness}
        color={props.color}
      />
    </g>
  );
}
