import format from "date-fns/format";
import { cloneDeep } from "lodash-es";
import { termMap } from "./charts/xKeyParser";
import { FILTER_TYPE_MULTI_RANGE } from "./constants/constants";

export const nestedPullMatched = (arr, nestedKey, matchKey, matchId) => {
  return arr.reduce((acc, curr) => {
    const match = curr[nestedKey].find((c) => c[matchKey] === matchId);
    return match || acc;
  }, {});
};

// Use this to group by same key matched
export const groupByKey = (key, useKey) => (arr) => {
  const allKeys = arr
    .map((a) => a[key])
    .filter((v, i, s) => s.indexOf(v) === i);
  return allKeys.map((a) => ({
    key: useKey ? key : a,
    values: arr.filter((all) => all[key] === a),
  }));
};

export const zeroPadValues =
  (requiredArray, childrenKey, padKey, fillValue) => (arr) => {
    return arr.map((item, i, s) => {
      return {
        ...item,
        [childrenKey]: requiredArray.map((r) => {
          const hasRealMatch = s[i][childrenKey].find((li) => li[padKey] === r);
          return hasRealMatch || fillValue;
        }),
      };
    });
  };

export const matchOne = (key) => (arr) => {
  return arr.find((a) => a === key);
};

export const doIfElse = (condition, trueFn, falseFn) => (arr) => {
  return condition ? trueFn(arr) : falseFn(arr);
};

export const doIf = (condition, fn) => (arr) => {
  return condition ? fn(arr) : arr;
};

export const assignAsKey = (source, target) => (arr) => {
  if (Array.isArray(source)) {
    return arr.reduce((acc, curr) => {
      source.forEach((s) => {
        acc.push({ ...curr, [target]: s });
      });

      return acc;
    }, []);
  }

  return arr.map((a) => ({ ...a, [target]: a[source] }));
};

export const assignAsKeys = (source, target) => (arr) => {
  return arr.reduce((acc, curr) => {
    source.forEach((s) => {
      acc.push({ ...curr, [target]: curr[s] });
    });

    return acc;
  }, []);
};

export const unique = (arr) => {
  return arr.filter((v, i, s) => s.indexOf(v) === i);
};

export const mapToKey = (key, groupByTerm, term) => (arr) => {
  return arr.map((a) => {
    if (groupByTerm) {
      return a[termMap[term] + key];
    }

    return a[key];
  });
};

export const toNumber = (key) => (arr) => {
  return arr.map((a) => ({ ...a, [key]: +a[key] }));
};

export const summedByKeyMatch = (groupKey, valueKey) => (arr) => {
  const uniques = pipe(mapToKey(groupKey), unique)(arr);
  return uniques.reduce((acc, curr) => {
    const vals = arr
      .filter((a) => a[groupKey] === curr)
      .map((a) => a[valueKey]);
    return [...acc, vals.reduce((va, vc) => va + +vc, 0)];
  }, []);
};

export const summedByKey = (valueKey) => (arr) => {
  return summed(arr.map((a) => a[valueKey]));
};

export const summed = (arr) => {
  return arr.reduce((acc, curr) => acc + +curr, 0);
};

export const maxed = (arr) => {
  return Math.max(...arr);
};

export const minned = (arr) => {
  return Math.min(...arr);
};

export const averaged = (arr) => {
  const total = summed(arr);
  return total / arr.length;
};

export const counted = (arr) => {
  return arr.length;
};

export const differenced = (arr) => {
  return arr[0] - arr[1];
};

export const divided = (arr) => {
  if ((arr[0] !== 0 && !arr[0]) || !arr[1]) return "Err";
  return arr[0] / arr[1];
};

export const diffDiv = (arr) => {
  return arr[0] / arr[1] - arr[2] / arr[3];
};

export const doNothing = (val) => val;

export const first = (arr) => arr[0];
export const previous = (arr) => arr[1] || null;

export const last = (arr) => arr[arr.length - 1];

export const scoop = (keys) => (arr) => {
  return arr.reduce((acc, curr) => {
    return [
      ...acc,
      ...curr[keys[0]].reduce((a, c) => {
        return [...a, c[keys[1]]];
      }, []),
    ];
  }, []);
};

export const matchingArrayKeysAndObject =
  (keys, matchProperty, matchValue) => (arr) => {
    const doMatch = (val) => val[matchProperty] === matchValue;
    const key1Index = arr.findIndex(
      (cont) => cont[keys[1]] && cont[keys[1]].find(doMatch)
    );

    if (key1Index === -1) return null;

    return arr.reduce(
      (acc, curr) => {
        const match = curr[keys[1]] && curr[keys[1]].find(doMatch);
        const matchIndex = curr[keys[1]] && curr[keys[1]].findIndex(doMatch);
        return match
          ? { ...acc, [keys[1] + "Key"]: matchIndex, obj: match }
          : acc;
      },
      { [keys[0] + "Key"]: key1Index }
    );
  };

export const zeroMax = (arr) => {
  return [0, Math.max(...arr)];
};

export const extent = (arr) => {
  const min = minned(arr);
  return [min > 0 ? 0 : min, maxed(arr)];
};

export const pipe =
  (...fns) =>
  (args) =>
    fns.reduce((arg, fn) => fn(arg), args);

export const validPipe = (...fns) =>
  fns.reduce((arg, fn) => {
    return fn && arg;
  }, true);

export const dateSort = (key) => (arr) => {
  arr.sort((a, b) => a[key] - b[key]);
  return arr;
};

export const sortByValues = (arr) => {
  arr.sort((a, b) => a - b);
  return arr;
};

export const groupUnder =
  (keys, rightKeys = [], xKeys) =>
  (arr) => {
    const hasManyKeys = Array.isArray(xKeys);

    const left = keys.map((k) => ({
      key: k,
      rightAxisValues: [],
      values: arr.map((a) => ({
        value: a[hasManyKeys ? a.xValue : k],
        xValue: a.xValue,
      })),
    }));

    const right = rightKeys.map((k) => ({
      key: k,
      values: [],
      rightAxisValues: arr.map((a) => ({ value: a[k], xValue: a.xValue })),
    }));

    return [...left, ...right];
  };

export const makePercent = (section, total) => 1 - (total - section) / total;

export const allKeys = (data) => {
  return data.reduce((acc, curr) => {
    const currKeys = Object.keys(curr);
    return unique([...currKeys, ...acc]);
  }, []);
};

export const removeByKey = (key) => (arr) => arr.filter((s, i) => i !== key);

// @todo this should be own file specific to date range setting

export const getStartEndDate = (dateString = "") => {
  const splited = dateString.split(" ");
  const [year, quarter] = Number.isInteger(+splited[0])
    ? splited
    : [].concat(splited).reverse();

  if (!quarter && year) {
    return {
      start: format(new Date(year, 0, 1), "yyyy-MM-dd"),
      end: format(new Date(year, 11, 31), "yyyy-MM-dd"),
    };
  }

  if (quarters(year)[quarter]) {
    return {
      start: format(quarters(year)[quarter].start, "yyyy-MM-dd"),
      end: format(quarters(year)[quarter].end, "yyyy-MM-dd"),
    };
  }

  return {};
};

const quarters = (year) => {
  return {
    Q1: {
      start: new Date(year, 0, 1),
      end: new Date(year, 2, 31),
    },
    Q2: {
      start: new Date(year, 3, 1),
      end: new Date(year, 5, 30),
    },
    Q3: {
      start: new Date(year, 6, 1),
      end: new Date(year, 8, 30),
    },
    Q4: {
      start: new Date(year, 9, 1),
      end: new Date(year, 11, 31),
    },
  };
};

export const mergeExpandedBars =
  (data, expandedData, xKey, expandedXKey) => (bar) => {
    if (!expandedData?.length || !bar) {
      return data;
    }

    const withExpandedBars = [];

    data.forEach((d) => {
      withExpandedBars.push(d);
      if (d[xKey] === bar[xKey]) {
        expandedData.forEach((ed) => {
          withExpandedBars.push({ ...ed, [xKey]: ed[expandedXKey] });
        });
      }
    });

    return withExpandedBars;
  };

export const buildDrilldownDateFilters = (type, dateKey) => {
  const { start, end } = getStartEndDate(dateKey);

  if (!start || !end) {
    return [];
  }

  return [
    {
      type,
      value: start,
    },
    {
      type,
      value: end,
    },
  ];
};

export const buildDrilldownFilters = ({
  bar,
  xKey,
  dynamicFilter,
  parameterizedFilterPrefix = "",
}) => {
  if (!bar || !bar[xKey]) {
    return null;
  }

  const filters = [];

  if (bar[xKey]) {
    filters.push({ type: parameterizedFilterPrefix + xKey, value: bar[xKey] });
  }

  if (bar[dynamicFilter]) {
    filters.push({
      type: parameterizedFilterPrefix + dynamicFilter,
      value: bar[dynamicFilter],
    });
  }

  return filters;
};

export const buildGroupAccessRequestBody = (dataSources, group) => {
  const allUnrestrictedWithoutFieldRestrictions = dataSources.every(
    (v) => !v.restricted && !v.allowedValues?.length
  );
  const allRestricted = dataSources.every((v) => v.restricted);
  const dataSourcesAccess =
    allRestricted || allUnrestrictedWithoutFieldRestrictions
      ? []
      : dataSources
          .filter((res) => !res.restricted)
          .map((res) => ({
            dataSourceUuid: res.uuid,
            displayName: res.displayName,
            allowedValues: res.isFiltered ? res.allowedValues : [],
          }));

  return {
    ...group,
    action: "update",
    accessRules: {
      dataSourcesAccess,
    },
  };
};

export const convertNumbersToBoolean = (data, key, isBoolean) => {
  if (!isBoolean) {
    return data;
  }

  return data
    .filter((d) => d[key] != null)
    .map((d) => ({ ...d, [key]: !!+d[key] }));
};

export const orderSpecialTypes = (data, sortKey, order, type) => {
  const clonned = cloneDeep(data);

  if (type === "weekRange") {
    return clonned.sort((a, b) => {
      if (a[sortKey] && b[sortKey]) {
        const splitA = new Date(a[sortKey].split(" ")[0]);
        const splitB = new Date(b[sortKey].split(" ")[0]);

        return order === "ASC" ? splitA - splitB : splitB - splitA;
      }

      return true;
    });
  }

  return data;
};

// Multi range filter working only with numbers and we need clear string first.
export function castToNumber(value = "") {
  if (isNaN(+value)) {
    return +value.replace("+", "").replace("$", "").replace("%", "");
  }

  return +value;
}

export const isMultiRangeFilterType = (type) =>
  type === FILTER_TYPE_MULTI_RANGE;

export const addScaleDate = () => (arr) => {
  return arr.map((row) => ({ ...row, scaleDate: makeScaleDate(row.xValue) }));
  function makeScaleDate(realDate) {
    const month = realDate.getMonth();
    const day = realDate.getDate();
    return new Date("2022", month, day);
  }
};

export const switchAxes = (lineGroups, rightAxisGroupByValues) => {
  if (!rightAxisGroupByValues) {
    return lineGroups;
  }

  return lineGroups.map((lineGroup) => {
    const useRightAxis = rightAxisGroupByValues.includes(lineGroup.key);
    if (useRightAxis) {
      const { values, ...rest } = lineGroup;
      return { ...rest, rightAxisValues: values };
    }

    return lineGroup;
  });
};

export function addSuffixToFilePath(filePath, suffix) {
  const parts = filePath.split(/\//g);
  let lastPart = parts.pop();
  const lastDotIndex = lastPart.lastIndexOf(".");
  if (lastDotIndex > 0) {
    // If the file has an extension, then add the suffix before that part.

    lastPart = `${lastPart.substring(
      0,
      lastDotIndex
    )}${suffix}${lastPart.substring(lastDotIndex)}`;
  } else {
    lastPart = lastPart + suffix;
  }

  parts.push(lastPart);

  return parts.join("/");
}

export function filterOutNullValues(data, yKey, yKeys) {
  if (yKey) {
    return data.filter((item) => item[yKey] !== null);
  }

  if (yKeys) {
    return data.filter((item) => yKeys.find((yKey) => item[yKey] !== null));
  }

  return data;
}

export const getWithOrOutNegatives = (withNegative, data, yKey) => {
  if (!yKey) {
    return data;
  }

  return withNegative ? data : data.filter((item) => +item[yKey] >= 0);
};

export const getDynamicData = (conifg, data, yKey) => {
  // If no dynamic sort configuration is provided, return the original data
  if (!conifg) {
    return data;
  }

  const { dynamicFirst, mappings, column } = conifg;

  let filteredData = [];

  // If dynamicFirst is true, filter data where the column value is not in mappings
  if (dynamicFirst) {
    filteredData = data.filter((d) => !mappings.includes(d[column]));
  }

  // Filter data for each mapping value
  const mappedData = mappings.map((mapping) =>
    data.filter((d) => d[column] === mapping)
  );

  // Concatenate the arrays and ensure null yKey values are set to 0
  const result = []
    .concat(dynamicFirst ? [filteredData] : [], mappedData)
    .flat()
    .map((item) => (item[yKey] === null ? { ...item, [yKey]: 0 } : item));

  return result;
};

// convert domain to work with combined positive and negative values
export const getCombinedDomain = (withNegative, yDomain) => {
  const [min, max] = yDomain;

  if (withNegative && min < 0) {
    const multiplier = max < 0 ? 1 : -0.2;
    return [Math.min(min, max * multiplier), max];
  } else {
    return [min, max];
  }
};
