import { useDispatch } from "react-redux";
import { getMenuFilterValues } from "../store/actions/layout";
import { useEffect, useState } from "react";
import useDebounceChange from "./useDebounceChange";

export default function useFilterPagination(filter = {}, options) {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");

  const usePagination = filter.totalCount > filter.values?.length;

  // return filters back when close item
  useEffect(() => {
    return () => {
      if (usePagination) {
        fetchFilterValues();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { debounce } = useDebounceChange();

  const fetchFilterValues = (searchTerm, page) => {
    debounce(
      () => dispatch(getMenuFilterValues(filter, searchTerm, page)),
      1000
    );
  };

  // handle search change for selects
  const onSelectInputChange = (value, { action }, page) => {
    if (action === "input-change") {
      setSearchValue(value);
      if (usePagination) {
        fetchFilterValues(value, page);
        setPage(page ?? 1);
      }
    }
  };

  return {
    fetchFilterValues,
    page,
    setPage,
    usePagination,
    searchValue,
    onSelectInputChange,
  };
}
