import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import SelectedFilters from "../Filters/SelectedFilters";
import { FILTER_TYPE_MULTI_RANGE } from "../../utils/constants/constants";
import FilterGroups from "./components/FilterGroups";
import useOnClickOutside from "../../hooks/useOnClickOutside";

const MenuDropdown = styled.div(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    padding: 0.375rem 0.75rem 1.125rem;
    color: ${theme.menuPrimary ? theme.textOnPrimary : theme.text.secondary};
    border-bottom: 1px solid ${
      theme.menuPrimary ? "rgba(255,255, 255, 0.1)" : theme.divider
    };
`
);

const MenuDropdownTitle = styled.div(
  () => `
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    font-size: 0.8em;
    text-transform: uppercase;
    cursor: pointer;
    text-align: left;
    user-select: none;

    & p {
        margin: 0;
    }
`
);

const Icon = styled.div`
  margin-right: 8px;
  font-size: 12px;
  width: 18px;
  text-align: center;
`;

const FilterGroupContainer = styled.div`
  position: relative;
  display: flex;
  font-size: 12px;
  text-transform: none;
  font-weight: 400;
`;

export default function FilterGroup(props) {
  const {
    name,
    open,
    children,
    cy,
    filter,
    select,
    additionalTheme,
    loadValues,
    selectedValues,
    otherFieldValues,
  } = props;

  const [showFilters, setShowFilters] = useState(!!open);
  const [showGroups, setShowGroups] = useState(false);
  const loading = filter?.loading;
  const [valuesLoaded, setValuesLoaded] = useState(!!filter?.values);
  const filterType = filter?.type;

  const { groups } = additionalTheme ?? {};
  const filterGroups = (groups ?? {})[filter?.uuid];

  const ref = useRef(null);
  useOnClickOutside(ref, () => setShowGroups(false));

  useEffect(() => {
    if (
      loadValues &&
      otherFieldValues &&
      filterType !== FILTER_TYPE_MULTI_RANGE
    ) {
      setValuesLoaded(false);
    }
  }, [loadValues, otherFieldValues, filterType]);

  useEffect(() => {
    (async () => {
      const pendingLazyLoad = loadValues && !loading;
      if (pendingLazyLoad && showFilters && !valuesLoaded) {
        try {
          await loadValues(otherFieldValues);
        } finally {
          setValuesLoaded(true);
        }
      }
    })();
  }, [loadValues, loading, otherFieldValues, showFilters, valuesLoaded]);

  function handleShowGroups(e) {
    e.stopPropagation();
    setShowGroups(!showGroups);
  }

  return (
    <MenuDropdown data-cy="menu-dropdown">
      <MenuDropdownTitle
        onClick={() => setShowFilters(!showFilters)}
        data-cy={cy}
      >
        <p>{name}</p>
        <FilterGroupContainer>
          <div ref={ref}>
            {!!filterGroups?.length && (
              <Icon onClick={handleShowGroups}>
                <FontAwesomeIcon
                  icon={["fal", showGroups ? "folder-open" : "folder"]}
                />
              </Icon>
            )}

            <FilterGroups
              showGroups={showGroups}
              filterGroups={filterGroups}
              select={select}
              filterValues={filter?.values}
            />
          </div>

          <Icon>
            <FontAwesomeIcon
              icon={["fas", showFilters ? "caret-up" : "caret-down"]}
            />
          </Icon>
        </FilterGroupContainer>
      </MenuDropdownTitle>
      {showFilters ? children : null}

      <SelectedFilters
        filter={filter}
        select={select}
        showFilters={showFilters}
        additionalTheme={additionalTheme}
        selectedValues={selectedValues}
      />
    </MenuDropdown>
  );
}

FilterGroup.propTypes = {
  name: PropTypes.string.isRequired,
  open: PropTypes.bool,
};
