import { getRidOfAggregation } from "../../../charts/TableView/Elements/EditableMenu";
import { cloneDeep, omit, orderBy } from "lodash-es";
import { setNonExistingOverrides } from "./tableEditorHelper";
import { getAggregationPrefix } from "../SettingsMenu/Layout/Column/RegularColumn";

function checkMathOperations(overrides) {
  const incorrect = overrides
    .filter((o) => o.ops)
    .map((o) => o.ops)
    .some((o) => !o.alias || !((o.fields && o.fields[0].name) || o.value));

  return incorrect ? "Math operations is missing" : null;
}

function checkDates(chart) {
  if (chart.allDates) {
    return null;
  }

  if (chart.dateKey) {
    return null;
  }

  if (chart.dateKeys?.start && chart.dateKeys?.end) {
    return null;
  }

  return `Date ${chart.isParameterized ? "keys" : "key"} is wrong`;
}

function checkGroupings(staticGroupingKeys) {
  if (staticGroupingKeys?.length === 0) {
    return "Please add at least one grouping";
  }

  return null;
}

export default function tableValidation(chart, page, block) {
  return {
    ops: checkMathOperations(chart.overrides),
    dates: checkDates(chart),
    pageUuid: !page?.uuid ? "Page uuid is missing" : null,
    blockUuid: !block?.uuid ? "Block uuid is missing" : null,
    staticGroupingKeys: checkGroupings(chart.staticGroupingKeys),
  };
}

function stringToArray(str) {
  return str.includes("fn::") ? str.split("::") : str;
}

export function getRestOverrides(newSubTitles, overrides = []) {
  return newSubTitles
    .flat()
    .reduce((acc, curr) => {
      acc.push(stringToArray(curr));
      return acc;
    }, [])
    .flat(Number.MAX_SAFE_INTEGER)
    .map((f) => getRidOfAggregation(f, overrides));
}

// get rid of some unnecessary properties
// because we sending them separately
const needlessPropertyList = [
  "uuid",
  "displayName",
  "sortOrder",
  "name",
  "visualizationId",
  "loading",
  "error",
  "data",
  "editable",
  "addAction",
  "refreshing",
];

const removableFalsyProperties = [
  "hide",
  "limit",
  "totals",
  "totalsOnly",
  "uniqueValueKey",
  "rawRows",
  "ignoreMenuFilters",
  "showHideColumns",
  "limitPercentAmount",
  "allDates",
  "dateKeys",
  "freezeLeft",
  "freezeWidth",
  "emptyFreezeLeft",
  "freezeNextColumn",
  "staticGroupingKeys",
  "usePagination",
  "dynamicFilterValue",
  "exportFormat",
  "exportType",
];

// for drilldowns we should not remove visualizationId
// they are unique uuid, we using them as keys for drilldown data in redux
function mapPropertiesToRemovable(chart) {
  return needlessPropertyList.filter((prop) => {
    if (prop === "visualizationId") {
      return !(chart.visualizationId || "").includes("row-visualization");
    }
    return true;
  });
}

function deleteAll(chart) {
  mapPropertiesToRemovable(chart).forEach((key) => {
    delete chart[key];
  });
}

function deleteFalsies(chart) {
  removableFalsyProperties.forEach((key) => {
    const canDelete =
      chart[key] === null || chart[key] === undefined || chart[key] === false;

    if (canDelete) {
      delete chart[key];
    }
  });
}

function deleteFormatOverridesTempData(chart) {
  if (chart.formatOverrides) {
    chart.formatOverrides.forEach((formatOverride) => {
      delete formatOverride.index;
      delete formatOverride.value;
      delete formatOverride.label;
    });
  }
}

/**
 * Gets the index of the override in non-aggregated keys.
 * If the override is not found, returns the length of overrides array.
 * @param {Array} nonAggregatedKeys - Array of non-aggregated keys.
 * @param {Object} override - Override object.
 * @param {Array} overrides - Array of overrides.
 * @returns {number} - Index of the override.
 */
function getIndex(nonAggregatedKeys, override, overrides) {
  const index = nonAggregatedKeys.findIndex((key) => key === override.name);

  if (index === -1) {
    return overrides.length;
  }

  return index;
}

/**
 * Removes the 'sortIndex' property from the override object.
 * @param {Object} override - Override object.
 * @returns {Object} - Override object without 'sortIndex'.
 */
function removeSortIndexFromOverrides(override) {
  const { sortIndex, ...rest } = override;
  return rest;
}

/**
 * Sorts overrides in the chart based on the order of subTitles.
 * @param {Object} chart - Chart object containing 'overrides' and 'subTitles'.
 */
function sortOverridesBySubTitlesOrder(chart) {
  const { overrides, subTitles } = chart;

  // Flatten subTitles array
  const keys = (subTitles ?? []).flat();

  // Check if overrides or keys are not available
  if (!overrides || !overrides.length || !keys.length) {
    return;
  }

  // Get non-aggregated keys
  const nonAggregatedKeys = keys.map((key) =>
    getRidOfAggregation(key, overrides)
  );

  // Add 'sortIndex' property to each override
  const indexedOverrides = overrides.map((override) => ({
    ...override,
    sortIndex: getIndex(nonAggregatedKeys, override, overrides),
  }));

  // Order overrides by 'sortIndex' and remove 'sortIndex' property
  const ordered = orderBy(indexedOverrides, "sortIndex", "asc").map(
    removeSortIndexFromOverrides
  );

  // Update chart with ordered overrides
  chart.overrides = ordered;
}

/**
 * Adds an aggregation prefix to a subtitle based on provided overrides and subtitle key.
 *
 * @param {Array} overrides - An array of overrides containing aggregation information.
 * @param {string} subTitleKey - The key for the subtitle.
 * @returns {string} - The modified subtitle with the aggregation prefix.
 */
function addAggregationPrefix(overrides, subTitleKey) {
  // Obtain the non-aggregated version of the subtitle
  const nonAggregatedSub = getRidOfAggregation(subTitleKey, overrides);

  // Find the override with matching subtitle key and extract aggregation information
  const { aggregation } =
    (overrides ?? []).find((override) => nonAggregatedSub === override.name) ??
    {};

  // Combine the aggregation prefix with the non-aggregated subtitle
  return getAggregationPrefix(aggregation) + nonAggregatedSub;
}

function getIsParameterized(chart, queries) {
  // Check if the chart is parameterized, and if so, no validation or modification is required

  return (
    queries.find((query) => query.uuid === chart.queryId)?.type ===
    "parameterized"
  );
}

/**
 * Validates and adds aggregation prefixes to subtitles based on provided chart.
 *
 * @param {Object} chart - The chart object containing information about subtitles and overrides.
 * @param {Array} queries - An array of queries.
 */
function validateAggregationPrefixesOnSubTitles(chart, queries) {
  if (getIsParameterized(chart, queries) || !chart.subTitles) {
    return;
  }

  chart.subTitles = (chart.subTitles ?? [[]]).map((subTitle) =>
    subTitle.map((sub) => addAggregationPrefix(chart.overrides, sub))
  );
}

/**
 * Removes unused overrides from the chart configuration.
 * Unused overrides are those not related to any subtitle, dynamic subtitle key,
 * dynamic filter, unique value key, row group key, or grouping key.
 *
 * @param {Object} chart - The chart configuration object.
 * @param {Array} queries - An array of queries associated with the chart.
 * @param {Array} dynamicFilters - An array of dynamic filters.
 */
function removeUnusedOverrides(
  chart,
  queries,
  dynamicFilters = [],
  joinConditionFields = []
) {
  // Check if the chart is parameterized, if so, no action needed
  if (getIsParameterized(chart, queries)) {
    return;
  }

  // Destructure chart properties with default values
  const {
    subTitles,
    dynamicSubTitleKeys,
    uniqueValueKey = "",
    rowGroupKey = "",
    groupingKey = "",
    overrides = [],
    tooltipConfig,
  } = chart;

  // if dynamicSubTitleKeys is defined then use only it not both with subTitles
  const columns = dynamicSubTitleKeys ?? (subTitles ?? []).flat();

  // Combine all keys related to subtitles, dynamic subtitles, dynamic filters, tooltipRows, and keys
  const keys = [
    ...columns,
    ...(dynamicFilters ?? []),
    uniqueValueKey,
    rowGroupKey,
    groupingKey,
    ...joinConditionFields,
    ...(tooltipConfig?.tooltipRows ?? []).map((row) => row.key),
  ].filter(Boolean);

  // Remove aggregation suffix from all keys
  const nonAggregatedKeys = keys.flatMap((key) => {
    if (key.includes("::")) {
      const formulaKeys = key.split("::");
      return formulaKeys.map((key) => getRidOfAggregation(key, overrides));
    }

    return getRidOfAggregation(key, overrides);
  });

  // Filter overrides to keep only those associated with non-aggregated keys
  chart.overrides = overrides.filter((override) =>
    nonAggregatedKeys.includes(override.name)
  );
}

function removeUnusedOpsFromOverrides(chart) {
  chart.overrides = (chart?.overrides ?? []).map((curr) =>
    curr.ops ? curr : omit(curr, "ops")
  );
}

function recursiveUpdate(chart, queries = [], joinConditionFields) {
  if (chart) {
    // remove properties in any case
    deleteAll(chart);

    // remove properties when they falsy
    deleteFalsies(chart);

    // remove temporary properties
    deleteFormatOverridesTempData(chart);

    // overrides order should match with columns order for correct export
    sortOverridesBySubTitlesOrder(chart);

    // add non existing overrides
    setNonExistingOverrides([], chart);

    // remove unused overrides
    removeUnusedOverrides(chart, queries, [], joinConditionFields);

    // remove unused math ops from overrides
    removeUnusedOpsFromOverrides(chart);

    if (queries) {
      // add aggregation prefix for subtitle which somehow lost it
      validateAggregationPrefixesOnSubTitles(chart, queries);
    }

    if (chart.filters) {
      chart.filters = chart.filters.filter((filter) => !filter.removeOnSave);
    }

    if (chart.dynamicDrilldowns) {
      const keys = Object.keys(chart.rowExpandVisualizationParams);
      keys.forEach((key) => {
        const drilldownParams = chart.rowExpandVisualizationParams[key];
        const { dynamicFilters } = drilldownParams;

        // add non existing overrides
        setNonExistingOverrides(dynamicFilters, chart);
        // remove unused overrides
        removeUnusedOverrides(
          chart,
          queries,
          dynamicFilters,
          joinConditionFields
        );

        recursiveUpdate(drilldownParams, queries, joinConditionFields);
      });
    } else if (chart.hasRowExpand) {
      const drilldownParams = chart.rowExpandVisualizationParams;
      const { dynamicFilters } = drilldownParams;

      // add non existing overrides
      setNonExistingOverrides(dynamicFilters, chart);
      // remove unused overrides
      removeUnusedOverrides(
        chart,
        queries,
        dynamicFilters,
        joinConditionFields
      );

      recursiveUpdate(drilldownParams, queries, joinConditionFields);
    }
  }
}

export function clearChartConfig(config, queries, joinConditionFields) {
  const clonedChart = cloneDeep(config);
  recursiveUpdate(clonedChart, queries, joinConditionFields);

  return clonedChart;
}

export function checkIsRegularColor(chart, column, isReversed, isBadDebt) {
  return (
    !(chart.coloredColumns ?? []).includes(column) &&
    !isReversed &&
    !isBadDebt &&
    !(chart.dynamicShadeBackgrounds ?? []).includes(column)
  );
}

export function checkIsColored(chart, column, isBadDebt) {
  return (
    ((chart.coloredColumns ?? []).includes(column) || isBadDebt) &&
    !column.includes("ReverseColor")
  );
}

export function checkIsSpecialColored(chart, column) {
  return chart.enumColorsSettings?.find((setting) => setting.column === column);
}
