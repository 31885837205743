import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faLongArrowLeft,
  faMapMarkerAlt,
  faCheck,
  faCheckCircle,
  faTimesCircle,
  faHome,
  faSignal,
  faReceipt,
  faLifeRing,
  faCog,
  faComments,
  faBell,
  faChevronDown,
  faChevronUp,
  faChevronRight,
  faChevronLeft,
  faSearch,
  faSquare,
  faArrowUp,
  faArrowDown,
  faEye,
  faTimes,
  faGripHorizontal,
  faFileCsv,
  faChartPie as fasChartPie,
  faChartArea,
  faChartLine as fasChartLine,
  faChartBar as fasChartBar,
  faMale,
  faArrowRight,
  faArrowLeft,
  faInfoCircle,
  faExclamationCircle,
  faCaretRight,
  faCaretLeft,
  faCaretDown,
  faCaretUp,
  faSignOutAlt,
  faTachometerAlt,
  faInfo,
  faBars,
  faTheaterMasks,
  faUser,
  faEnvelope,
  faKey,
  faUserTag,
  faLock,
  faUsers,
  faPencilAlt,
  faTrashAlt,
  faUpload,
  faFile,
  faAngleDoubleDown,
  faClock,
  faPlusCircle,
  faMinusCircle,
  faCalendarWeek,
  faPercent,
  faSpinner,
  faImages,
  faImage,
  faHourglass,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
  faExclamationTriangle,
  faFilter,
  faCircle as fasCircle,
  faEllipsisH,
  faSortAmountUp,
  faSortAmountDownAlt,
  faSync,
  faEnvelopeOpenText,
  faBookmark,
  faCloudDownloadAlt,
  faArrowCircleRight,
  faEyeSlash,
  faUnlock,
  faQuestion,
  faPlus,
  faThumbsDown,
  faHourglassStart,
  faTh,
  faCode,
  faSave,
  faEquals,
  faNotEqual,
  faGreaterThan,
  faGreaterThanEqual,
  faLessThan,
  faLessThanEqual,
  faSatelliteDish,
  faUndo,
  faServer,
  faMinus as faMinusSolid,
  faPlayCircle,
  faPauseCircle,
  faLockOpen,
  faSlidersH,
  faIdCard,
  faFileAlt,
  faSortNumericDown,
  faThumbtack,
  faCompressAlt,
  faExpandAlt,
  faBook,
  faArrowsAlt,
  faPencilRuler as fasPencilRuler,
  faSort as fasSort,
  faThList,
  faThumbsUp,
  faToggleOn,
  faExchangeAlt,
  faTextSize,
  faCalculatorAlt,
  faUserRobot,
  faTriangle,
  faArrowAltLeft,
  faLightSwitch,
  faBrackets,
  faBug,
  faWindowRestore,
  faWindow,
  faArrowAltUp,
  faArrowAltDown,
  faFunction,
  faTachometer as fasTachometer,
  faDownload,
  faCalendarAlt,
  faCommentAltDots as fasCommentAltDots,
  faSpiderBlackWidow,
  faCameraMovie,
  faHandSparkles,
  faBolt,
  faTrash,
  faAlarmClock,
  faBackspace,
  faCalendar,
  faAlignLeft,
  faAlignRight,
  faAlignCenter,
  faHashtag,
  faThLarge,
  faDivide,
  faShapes,
  faRepeatAlt,
  faSuperscript,
  faLink,
  faCopy,
  faSlidersHSquare,
  faInfoSquare,
  faText,
  faEllipsisV,
  faCalendarDay,
  faFlashlight,
  faStar as fasStar,
  faAlignSlash,
} from "@fortawesome/pro-solid-svg-icons";

import {
  faCheckSquare,
  faColumns as farColumns,
  faTrashAlt as farTrashAlt,
  faTimesCircle as farTimesCircle,
  faCircle as farCircle,
  faQuestionCircle,
  faEdit,
  faFileExcel,
  faEye as farEye,
  faDollarSign,
  faMapPin,
  faShareAlt,
  faVial,
  faPlay,
  faSort,
  faSyncAlt,
  faBan,
  faSave as farSave,
  faPalette,
  faPaintBrush,
  faStar as farStar,
  faListOl,
  faGripLines,
  faCommentAltDots,
  faBusinessTime,
  faSearchPlus,
  faChevronDoubleLeft as farChevronDoubleLeft,
  faChevronDoubleRight as farChevronDoubleRight,
  faTelescope,
  faFolderTree,
  faLongArrowLeft as farLongArrowLeft,
  faListAlt,
  faUsersClass,
} from "@fortawesome/pro-regular-svg-icons";

import {
  faTimes as falTimes,
  faKey as falKey,
  faPlus as falPlus,
  faPaperclip,
  faTrashAlt as falTrashAlt,
  faTimesCircle as falTimesCircle,
  faChevronDoubleRight,
  faRocket,
  faExclamationCircle as falExclamationCircle,
  faExclamationTriangle as falExclamationTriangle,
  faSquare as falSquare,
  faCheckSquare as falCheckSquare,
  faEye as falEye,
  faEyeSlash as falEyeSlash,
  faFolder,
  faMinus,
  faInfoCircle as falInfoCircle,
  faTrafficCone,
  faArrowUp as falArrowUp,
  faArrowDown as falArrowDown,
  faPlusSquare,
  faEraser,
  faTable as falTable,
  faCubes,
  faQuestion as falQuestion,
  faQuestionSquare,
  faDollarSign as falDollarSign,
  faDatabase as falDatabase,
  faFileSpreadsheet,
  faEllipsisH as falEllipsisH,
  faClock as falClock,
  faCog as falCog,
  faPlusCircle as falPlusCircle,
  faMinusCircle as falMinusCircle,
  faCogs,
  faChevronLeft as falChevronLeft,
  faQuestionCircle as falQuestionCircle,
  faCheck as falCheck,
  faHourglassHalf as falHourglassHalf,
  faPrintSlash as falPrintSlash,
  faInboxIn as falInboxIn,
  faUser as falUser,
  faChevronDown as falChevronDown,
  faChevronUp as falChevronUp,
  faArrowRight as falArrowRight,
  faArrowLeft as falArrowLeft,
  faFolderOpen as falFolderOpen,
  faFolder as falFolder,
  faChevronRight as falChevronRight,
  faGlobeAmericas,
  faTrash as falTrash,
  faAsterisk,
  faCircle,
  faSync as falSync,
  faListUl,
  faListAlt as falListAlt,
  faTablet,
  faPlay as falPlay,
  faPlayCircle as falPlayCircle,
  faBolt as falBolt,
  faKeyboard as falKeyboard,
  faText as falText,
  faLock as falLock,
  faLockOpen as falLockOpen,
  faImage as falImage,
  faFileInvoiceDollar as falFileInvoiceDollar,
  faTextWidth as falTextWidth,
  faWatchCalculator as falWatchCalculator,
  faCalendar as falCalendar,
  faToggleOn as falToggleOn,
  faMobileAndroid as falMobileAndroid,
  faMobileAndroidAlt as falMobileAndroidAlt,
  faTextSize as falTextSize,
  faChevronDoubleUp,
  faChevronDoubleDown,
  faSave as falSave,
  faRobot,
  faCheckCircle as falCheckCircle,
  faCommentAltEdit,
  faPencil as falPencil,
  faSigma as falSigma,
} from "@fortawesome/pro-light-svg-icons";
import { faCube } from "@fortawesome/pro-light-svg-icons/faCube";

import {
  faChartPie as fadChartPie,
  faChartBar as fadChartBar,
  faChartLine as fadChartLine,
  faTable as fadTable,
  faStopwatch20 as fadStopwatch20,
  faPencilRuler as fadPencilRuler,
  faTachometer,
  faCircle as fadCircle,
  faCheckCircle as fadCheckCircle,
  faCalendarEdit,
  faTasksAlt,
  faSigma,
} from "@fortawesome/pro-duotone-svg-icons";

library.add(
  falSigma,
  faSigma,
  faFlashlight,
  faCalendarDay,
  faCalendar,
  faLongArrowLeft,
  faMapMarkerAlt,
  faCheck,
  faHome,
  faSignal,
  faReceipt,
  faLifeRing,
  faCog,
  faComments,
  faBell,
  faChevronDown,
  faChevronUp,
  faChevronRight,
  faSearch,
  faSquare,
  faArrowUp,
  faArrowDown,
  faEye,
  faTimes,
  faCheckSquare,
  faGripHorizontal,
  faFileCsv,
  fasChartPie,
  faChartArea,
  fasChartLine,
  fasChartBar,
  faMale,
  faArrowRight,
  faArrowLeft,
  faInfoCircle,
  faCaretDown,
  faCaretUp,
  faCaretRight,
  faCaretLeft,
  faSignOutAlt,
  faTachometerAlt,
  faInfo,
  faBars,
  faTheaterMasks,
  faUser,
  faEnvelope,
  faKey,
  faUserTag,
  faLock,
  faUsers,
  faPencilAlt,
  faTrashAlt,
  faFile,
  faAngleDoubleDown,
  faCheckCircle,
  faTimesCircle,
  faClock,
  faPlusCircle,
  falPlusCircle,
  faMinusCircle,
  faTextSize,
  faDollarSign,
  faCalculatorAlt,
  faUpload,
  faPercent,
  faCalendarWeek,
  faSpinner,
  falTimes,
  falKey,
  faImages,
  faImage,
  faHourglass,
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faAngleRight,
  faAngleLeft,
  faExclamationTriangle,
  faUserRobot,
  falPlus,
  faPaperclip,
  faTriangle,
  faArrowAltLeft,
  farColumns,
  farTrashAlt,
  falTrashAlt,
  farTimesCircle,
  falTimesCircle,
  faChevronDoubleRight,
  faChevronLeft,
  faFilter,
  fasCircle,
  faIdCard,
  farCircle,
  faSortAmountUp,
  faSortAmountDownAlt,
  faEllipsisH,
  faMapPin,
  faExclamationCircle,
  faShareAlt,
  faVial,
  faSync,
  faEnvelopeOpenText,
  faBookmark,
  faCloudDownloadAlt,
  faArrowCircleRight,
  faQuestionCircle,
  faEyeSlash,
  faRocket,
  faEdit,
  faUnlock,
  faQuestion,
  faLightSwitch,
  faPlus,
  faThumbsDown,
  faThumbsUp,
  faHourglassStart,
  faTh,
  faCode,
  faSave,
  falExclamationCircle,
  falCheckSquare,
  falSquare,
  faPlay,
  falPlay,
  falEye,
  falEyeSlash,
  faEquals,
  faNotEqual,
  faBrackets,
  faGreaterThan,
  faGreaterThanEqual,
  faLessThan,
  faLessThanEqual,
  faSort,
  faFileExcel,
  faSatelliteDish,
  faFolder,
  faMinus,
  faSyncAlt,
  faBug,
  falInfoCircle,
  faTrafficCone,
  falArrowUp,
  falArrowDown,
  faBan,
  farSave,
  faPlusSquare,
  faPalette,
  faPaintBrush,
  faEraser,
  falTable,
  faCubes,
  faCube,
  farEye,
  faCogs,
  faUndo,
  faWindowRestore,
  faServer,
  faMinusSolid,
  faPauseCircle,
  faWindow,
  faLockOpen,
  faSlidersH,
  faFileAlt,
  faSortNumericDown,
  faListOl,
  falDollarSign,
  faThumbtack,
  falChevronLeft,
  faArrowAltUp,
  faArrowAltDown,
  falChevronLeft,
  faThumbtack,
  falDollarSign,
  faSlidersH,
  faQuestionSquare,
  fadChartPie,
  fadChartBar,
  fadChartLine,
  fadTable,
  fadStopwatch20,
  fadPencilRuler,
  falQuestion,
  farStar,
  fasStar,
  falDollarSign,
  faFileAlt,
  falDatabase,
  faFileSpreadsheet,
  falEllipsisH,
  falClock,
  faGripLines,
  falCog,
  faTachometer,
  faCompressAlt,
  faExpandAlt,
  faFunction,
  faBook,
  faArrowsAlt,
  falQuestionCircle,
  falCheck,
  falHourglassHalf,
  falPrintSlash,
  fasSort,
  falInboxIn,
  falUser,
  faDownload,
  faArrowsAlt,
  faCalendarAlt,
  faCommentAltDots,
  fasCommentAltDots,
  faSpiderBlackWidow,
  faBusinessTime,
  faSearchPlus,
  falChevronDown,
  falChevronUp,
  falArrowRight,
  falArrowLeft,
  falFolderOpen,
  falFolder,
  falChevronUp,
  faThList,
  faCameraMovie,
  faExchangeAlt,
  faBolt,
  faPlay,
  faPlayCircle,
  falChevronRight,
  farChevronDoubleRight,
  farChevronDoubleLeft,
  faToggleOn,
  faCameraMovie,
  faExchangeAlt,
  faHandSparkles,
  faTelescope,
  falExclamationTriangle,
  faGlobeAmericas,
  faTrash,
  faAlarmClock,
  falMinusCircle,
  faAlarmClock,
  faBackspace,
  faFolderTree,
  faAlignLeft,
  faAlignRight,
  faAlignCenter,
  faAlignSlash,
  faHashtag,
  faThLarge,
  faDivide,
  faShapes,
  faBackspace,
  faFolderTree,
  faRepeatAlt,
  farLongArrowLeft,
  faLink,
  faCopy,
  faListAlt,
  faInfoSquare,
  falTrash,
  faAsterisk,
  faCircle,
  fadCircle,
  fadCheckCircle,
  faSync,
  falSync,
  faListUl,
  faListAlt,
  faSlidersHSquare,
  faUsersClass,
  falListAlt,
  faInfoSquare,
  faText,
  faCalendarEdit,
  faTasksAlt,
  faEllipsisV,
  faTablet,
  faInfoSquare,
  falPlayCircle,
  faRobot,
  falPlayCircle,
  faTasksAlt,
  falBolt,
  falKeyboard,
  falText,
  falLock,
  falLockOpen,
  falImage,
  falFileInvoiceDollar,
  falTextWidth,
  falTextSize,
  falWatchCalculator,
  falCalendar,
  falToggleOn,
  falMobileAndroid,
  falMobileAndroidAlt,
  faChevronDoubleUp,
  faChevronDoubleDown,
  falSave,
  falPencil,
  faRobot,
  falCheckCircle,
  faCommentAltEdit
);

export {
  falTable,
  fasChartBar,
  fasChartLine,
  fasChartPie,
  fasPencilRuler,
  fasTachometer,
  faSuperscript,
  falBolt,
};
