import { evaluate } from "mathjs";
import formatter from "../../../utils/formatters/formatter";
import { rightAligns } from "../../../utils/constants/constants";

export default function tableOpenMathFunctionConvertor(formula, dataRow) {
  const formulaItems = formula.split("::");
  const [, format, ...rest] = formulaItems;

  // remove label from exec
  rest.pop();

  const isReverse = formula.includes("::ReverseColor");

  const mapFormulaToString = rest
    // remove ReverseColor from math operations
    .filter((item) => item !== "ReverseColor")
    .reduce((acc, curr) => {
      return (acc += dataRow.hasOwnProperty(curr) ? dataRow[curr] : curr);
    }, "");

  // check if there all numbers (mathjs will crash on expression like: 1 + null)
  const numbersOnly = rest
    .filter((item) => !mathSigns.includes(item))
    .every((col) => {
      if (dataRow.hasOwnProperty(col)) {
        return dataRow[col] !== null && !isNaN(+dataRow[col]);
      }
      return false;
    });

  const value = numbersOnly ? evaluate(mapFormulaToString) : null;

  return {
    formatted: isFinite(value) ? formatter(value, format) : "--",
    value: isFinite(value) ? value : null,
    align: getValueAlign(format),
    isReverse,
  };
}

function getValueAlign(format) {
  const isD3Format = format && format.includes(".");
  if (rightAligns.find((f) => f === format) || isD3Format) {
    return "right";
  }

  if (format === "bollean") {
    return "center;";
  }

  return "left";
}

const mathSigns = ["+", "-", "/", "*", "(", ")", "ReverseColor", "sqrt", "pow"];
