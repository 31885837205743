import styled from "@emotion/styled";
import { SELECT_ALL_FILTERS } from "../../../utils/constants/constants";
import { scrollbarDe } from "../../../styles/styledSnippets";
import { useState } from "react";

const Block = styled.div`
  width: 150px;
  height: 140px;
  position: absolute;
  top: 16px;
  right: 0;
  padding: 10px;
  background: ${(props) => props.theme.background.primary};
  border: 1px solid ${(props) => props.theme.divider};
  z-index: 2;
  border-radius: 5px;
`;

const Container = styled.div(
  ({ theme }) => `
  width: 160px;
  height: 135px;
  position: absolute;
  top: 7px;
  left: 7px;
  cursor: default;
  overflow-y: auto;
  ${scrollbarDe(theme)};
`
);

const GroupItem = styled.div(
  ({ theme, isActive }) => `
  &:hover {
    color: ${theme.primary};
    cursor: pointer;
  }
  ${isActive && `color: ${theme.primary};`};
  margin-bottom: 7px;
`
);

const Clear = styled.div(
  ({ theme }) => `
  position: absolute;
  bottom: 3px;
  left: 70px;
  &:hover {
    color: ${theme.text.primary};
    cursor: pointer;
    text-decoration: underline;
  }
`
);

export default function FilterGroups(props) {
  const { showGroups, filterGroups, filterValues, select } = props;
  const [actives, setActives] = useState([]);

  if (!showGroups) {
    return null;
  }

  function getSelectedFilters(array, clearAll) {
    return filterValues.map((filter) => {
      if (clearAll) {
        return { ...filter, checked: false };
      }

      return {
        ...filter,
        checked: !filter.checked
          ? array.includes(filter.value)
          : filter.checked,
      };
    });
  }

  function selectFilterPresset(group, index) {
    const key = group.name + index;

    if (actives.includes(key)) {
      return;
    }

    setActives([...actives, key]);
    select(getSelectedFilters(group.values), SELECT_ALL_FILTERS);
  }

  function clearSelectedFilters() {
    const nothingToUncheck = filterValues.every((filter) => !filter.checked);

    if (nothingToUncheck) {
      return setActives([]);
    }

    setActives([]);
    select(getSelectedFilters([], true), SELECT_ALL_FILTERS);
  }

  return (
    <Block>
      <Container onClick={(e) => e.stopPropagation()}>
        {filterGroups.map((group, index) => (
          <GroupItem
            key={group.name + index}
            onClick={() => selectFilterPresset(group, index)}
            isActive={actives.includes(group.name + index)}
          >
            {group.name}
          </GroupItem>
        ))}
      </Container>
      <Clear onClick={clearSelectedFilters}>Clear</Clear>
    </Block>
  );
}
