import produce from "immer";
import { dateRangeOperators } from "../../../Pages/DataManagement/DataExplorer/DataExplorerUI/ExplorerInputs/FieldOperatorList";

export default function prepareSettings(rawSettings) {
  const settings = conformOrders(rawSettings);
  const strippedFilters = filtersWithValues(settings);
  const strippedHavings = havingsWithDefinedOperators(settings);

  let nextFilters = convertSpecialTypes(strippedFilters);
  nextFilters = convertToWithNull(nextFilters);

  let ret = { ...settings, filters: nextFilters, havings: strippedHavings };
  if (!ret.filters.length) {
    delete ret.filters;
  }
  if (!ret.havings.length) {
    delete ret.havings;
  }
  return ret;
}

function conformOrders(settings) {
  if (!settings?.orders) return settings;
  const order = settings.orders.reduce((acc, curr) => {
    return { ...acc, [curr.name]: curr.sort };
  }, {});
  const draftSettings = { ...settings, order };
  delete draftSettings.orders;
  return draftSettings;
}

function filtersWithValues(settings) {
  return (settings?.filters ?? []).filter(
    (fil) => fil.subQueryValues || fil.values?.length
  );
}

function havingsWithDefinedOperators(settings) {
  return (settings?.havings ?? [])
    .filter((having) => having.operator)
    .filter((having) => having.values?.length);
}

function convertSpecialTypes(filters) {
  return produce(filters, (draftState) => {
    draftState.forEach((filter) => {
      if (dateRangeOperators.includes(filter.operator)) {
        delete filter.operator;
      }
    });
  });
}

export function convertToWithNull(filters) {
  return produce(filters, (draftFilters) => {
    for (const filter of draftFilters) {
      if (2 <= filter.values?.length && filter.values.includes(null)) {
        filter.values = filter.values.filter((v) => v !== null);
        filter.withNull = true;
      }
    }
  });
}
