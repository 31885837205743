import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "@emotion/styled";
import { inputBorders, inputMenu } from "../../../../../styles/styledSnippets";
import { buckets } from "../../../../../utils/constants/dataTypes";
import ExplorerSelectPortal from "../../NavigationTable/ExplorerSelectPortal";
import useOnClickOutside from "../../../../../hooks/useOnClickOutside";

const Container = styled.div`
  position: relative;
`;

const Option = styled.div`
  color: ${(props) => props.theme.text.secondary};
  font-size: 13px;
  font-weight: 400;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.text.primary};
  }

  svg {
    margin-right: 14px;
  }
`;

const Menu = styled.div`
  ${(props) => inputMenu(props)}
  display: inline-block;
  position: absolute;
  top: ${(props) => props.dimensions.top + props.dimensions.height}px;
  left: ${(props) => props.dimensions.left}px;
  white-space: nowrap;
`;

const Value = styled.div`
  width: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 13px;
  ${(props) => inputBorders(props)}

  path {
    fill: ${(props) => props.theme.text.secondary};
  }
`;

const operators = {
  string: [
    { value: "==", label: "Equal", icon: "equals" },
    { value: "!=", label: "Not Equal", icon: "not-equal" },
    { value: "like", label: "Partial Match", icon: "search" },
  ],
  number: [
    { value: "==", label: "Equal", icon: "equals" },
    { value: ">", label: "Greater", icon: "greater-than" },
    { value: ">=", label: "Greater or Equal", icon: "greater-than-equal" },
    { value: "<", label: "Less", icon: "less-than" },
    { value: "<=", label: "Less or Equal", icon: "less-than-equal" },
  ],
  date: [
    { value: ">=", label: "Since", icon: "angle-double-right" },
    { value: "<=", label: "Before", icon: "angle-double-left" },
    { value: "==", label: "Equal", icon: "equals" },
    { value: "range", label: "Range", icon: "brackets" },
    { value: "lastDays", label: "Last Days…", icon: "calendar-day" },
    { value: "nextDays", label: "Next Days…", icon: "calendar-day" },
  ],
  boolean: [{ value: "==", label: "Equal", icon: "equals" }],
};

export const dateRangeOperators = ["range"];
export const LAST_X_DAYS_OPERATOR = "lastDays";
export const NEXT_X_DAYS_OPERATOR = "nextDays";

export default function FieldOperatorList(props) {
  const [showList, setShowList] = useState(false);
  const [dimensions, setDimensions] = useState(null);
  const ref = useRef(null);
  const listRef = useRef(null);
  useOnClickOutside(ref, () => setShowList(false));

  const handleSelect = (val) => {
    setShowList(false);
    props.setValue(val.value);
  };

  const typeBucket = buckets[props.type] || "string";

  const icon = () =>
    props.value
      ? operators[typeBucket].find((op) => op.value === props.value)?.icon ||
        "bug"
      : "question";

  const handleToggle = () => {
    setDimensions(listRef.current.getBoundingClientRect());
    setShowList(!showList);
  };

  return (
    <Container>
      <Value data-cy="operator-select" onClick={handleToggle} ref={listRef}>
        <FontAwesomeIcon icon={["fas", icon()]} />
      </Value>

      {showList ? (
        <ExplorerSelectPortal>
          <Menu dimensions={dimensions} ref={ref}>
            {operators[typeBucket].map((op) => (
              <Option
                data-cy="operator-option"
                key={op.value}
                onClick={() => handleSelect(op)}
              >
                <FontAwesomeIcon icon={["fas", op.icon]} />
                {op.label}
              </Option>
            ))}
          </Menu>
        </ExplorerSelectPortal>
      ) : null}
    </Container>
  );
}

FieldOperatorList.propTypes = {
  type: PropTypes.string,
  value: PropTypes.string,
  setValue: PropTypes.func,
  position: PropTypes.number,
};
