import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import MultiRangeSlider from "./MultiRangeSlider";
import { minBy, maxBy } from "lodash-es";

// @todo, this needs to be broken out into reusable component for slider
export default function MultiRangeFilter({ onChange, filter }) {
  const multiRangeFilters = useSelector(
    (state) => state.layout.multiRangeFilters
  );
  const { type, format, values, usesCustomValues } = filter;

  // Unless we are using custom values for the ranges, let's grab the smallest
  // and largest number among the values and treat those as the min/max limits.
  const limitMin = useMemo(() => {
    return usesCustomValues
      ? filter.limitMin
      : minBy(values, (v) => v.value)?.value;
  }, [filter.limitMin, usesCustomValues, values]);
  const limitMax = useMemo(() => {
    return usesCustomValues
      ? filter.limitMax
      : maxBy(values, (v) => v.value)?.value;
  }, [filter.limitMax, usesCustomValues, values]);

  // We are creating on the fly checked values on changes in the reducer
  const [checkedMin, checkedMax] = values.filter((v) => v.checked);
  const checked = {
    min: checkedMin?.value ?? limitMin,
    max: checkedMax?.value ?? limitMax,
  };

  function handleChange(nextMin, nextMax) {
    if (filter.min !== nextMin || filter.max !== nextMax) {
      onChange(
        {
          ...filter,
          min: nextMin,
          max: nextMax,
          type: values[0]?.type,
        },
        type
      );
    }
  }

  return (
    <MultiRangeSlider
      onChange={handleChange}
      type={type}
      format={format}
      values={values}
      min={limitMin}
      max={limitMax}
      multiRangeFilters={multiRangeFilters}
      checked={checked}
    />
  );
}
