import { customFilterTypes } from "./constants/filterConstants";
import { orderBy, keyBy } from "lodash-es";
import { sortIfDaysOfWeek } from "./constants/constants";

export function sortMenuFilters(values) {
  return sortByDate(values);
}

function sortByDate(values) {
  if (!values.length) {
    return values;
  }

  const type = customFilterTypes.find((d) => d.type === values[0].type);

  if (type) {
    const elements = values.map((x) => ({
      ...x,
      sortValue: type.fn(x.value),
    }));

    const sorted = orderBy(elements, "sortValue", type.direction);

    return sorted.map((x) => {
      delete x.sortValue;
      return x;
    });
  }

  return sortIfDaysOfWeek(values);
}

/**
 * @param {import('./queries').QueryField[]} queryFields
 * @param currentFields {string[]}
 */
export function assembleEmptySingleQueryFiltersFromActiveTableStore(
  queryFields,
  currentFields
) {
  const ret = [];

  const queryFieldsByName = keyBy(queryFields, "name");
  for (const fieldName of currentFields) {
    if (fieldName === "sketchimage") {
      // we will want to add proper support for automatically hiding
      // api-image type active table columns rather than do this hack.
      continue;
    }
    const fieldInfo = queryFieldsByName[fieldName];
    if (!fieldInfo) {
      continue;
    }

    const row = {
      fieldName,
      fieldDisplayName: fieldInfo?.mapping.displayName,
      values: null,
    };
    if (fieldInfo?.mapping.type) {
      row.fieldType = fieldInfo.mapping.type;
    }

    ret.push(row);
  }

  return ret;
}
