import { summed } from "../../utils/func";
import { getDataReducer } from "./getDataReducer";

export default function (
  names,
  mappedWithTotals,
  allXKeys,
  sortedData,
  mappedXKey,
  yValueKeys,
  breakdownKey,
  yKeys
) {
  // console.log({
  //   names,
  //   mappedWithTotals,
  //   allXKeys,
  //   sortedData,
  //   mappedXKey,
  //   yValueKeys,
  //   breakdownKey,
  //   yKeys,
  // });
  // breakdown segments in order for single yValueKey
  const keys =
    yKeys ||
    names ||
    mappedWithTotals.map((a) => a.segmentKey).filter((a) => a); // ['LocationA', 'LocationB', 'LocationC']

  const convertedArray = allXKeys.reduce((acc, curr) => {
    const atDate = sortedData.filter((d) => d[mappedXKey] === curr);

    const obj = {
      first: atDate.reduce(
        getDataReducer(yValueKeys && yValueKeys[0], breakdownKey, yKeys),
        {}
      ),
      xKey: curr,
    };
    if (yValueKeys && yValueKeys[1]) {
      obj.second = atDate.reduce(
        getDataReducer(yValueKeys[1], breakdownKey, null),
        {}
      );
    }
    return [...acc, obj];
  }, []);

  // totals for yScale
  const totals = allXKeys.map((k) => ({
    x: k,
    total: getTotals(k, allXKeys, sortedData, mappedXKey, yValueKeys, yKeys),
    labelTotal: getTotals(
      k,
      allXKeys,
      sortedData,
      mappedXKey,
      yValueKeys,
      yKeys
    ),
    // labelTotal:
    //   yKey &&
    //   summed(sortedData.filter((l) => l[mappedXKey] === k).map((d) => d[yKey])),
  }));
  return { keys, convertedArray, totals };
}

function getTotals(k, allXKeys, sortedData, mappedXKey, yValueKeys, yKeys) {
  if (yKeys) {
    const row = sortedData.find((d) => d[mappedXKey] === k);
    return yKeys.reduce((acc, curr) => {
      return acc + +row[curr] || 0;
    }, 0);
  } else {
    return summed(
      sortedData
        .filter((l) => l[mappedXKey] === k)
        .map((d) =>
          (() => {
            let ret = 0;
            const toIterate = totalIterated(yValueKeys);
            for (const keyOrObject of toIterate) {
              const key =
                typeof keyOrObject === "object"
                  ? keyOrObject.source
                  : keyOrObject;
              ret += d[key];
            }
            return ret;
          })()
        )
    );
  }
}

function totalIterated(yValueKeys) {
  if (!yValueKeys) return []; // temp only
  return !Array.isArray(yValueKeys[0]) ? [yValueKeys[0]] : yValueKeys[0];
}
