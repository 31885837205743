import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { selectAllComparisonFilters } from "../../../store/actions/layout";
import SelectAllCheckboxItem from "./SelectAllCheckboxItem";

export default function ComparisonSelectAll({
  filters = [],
  type = "checkbox",
  comparisonModeItem,
  selectPowerEditorModeFilter,
}) {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const allSelected = filters.every((filter) => filter.checked);

  useEffect(() => {
    setChecked(allSelected);
  }, [allSelected]);

  if (!comparisonModeItem || Number.isFinite(comparisonModeItem.count)) {
    return null;
  }

  const key = filters[0]?.key;
  const inComparisonList = comparisonModeItem.options.includes(key);

  const selectAll = () => {
    setChecked(!checked);
    const shouldReloadCharts = !selectPowerEditorModeFilter;

    dispatch(
      selectAllComparisonFilters(
        key,
        !checked,
        allSelected ? 0 : filters.length,
        shouldReloadCharts
      )
    );

    if (selectPowerEditorModeFilter) {
      selectPowerEditorModeFilter();
    }
  };

  return (
    <SelectAllCheckboxItem
      inComparisonList={inComparisonList}
      filters={filters}
      allSelected={allSelected}
      checked={checked}
      selectAll={selectAll}
      type={type}
    />
  );
}
