import React, { useEffect, useRef, useState, useMemo } from "react";
import ExplorerSelectPortal from "../../NavigationTable/ExplorerSelectPortal";
import ExploreList from "./ExploreList";
import styled from "@emotion/styled";
import { inputBorders } from "../../../../../styles/styledSnippets";
import PropTypes from "prop-types";

const Input = styled.input`
  font-size: 12px;
  ${(props) => inputBorders(props)}
  width: 100%;
  box-sizing: border-box;

  &:focus {
    border: 2px solid #2684ff;
    outline: none;
  }
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

export default function PortalSelectInput(props) {
  const {
    showValues,
    setShowValues,
    uniqueOptions,
    keyName,
    selectedValue,
    onChangeValue,
    placeholder,
    cy,
    searchMode,
    filter,
    loading,
    isParameterized,
  } = props;

  const [tempValue, setTempValue] = useState("");
  const [dimensions, setDimensions] = useState(null);
  const [filtering, setFiltering] = useState(false);
  const ref = useRef(null);
  const listRef = useRef(null);
  const selected = selectedValue?.[keyName];

  const handleSelectOption = (option) => {
    onChangeValue(option);
    setFiltering(false);
    setTempValue(String(option?.[keyName]));
  };

  function handleSetShowValues() {
    const dims = getDimensions();
    setDimensions(dims);
    setShowValues(true);

    function getDimensions() {
      return ref?.current ? getWrapperDimensions() : defaultDimensions;
    }

    function getWrapperDimensions() {
      return ref.current.getBoundingClientRect();
    }
  }

  useEffect(() => {
    setTempValue(selected === undefined ? "" : String(selected));
  }, [selected]);

  const handleBlur = () => {
    if (searchMode) return;

    const shouldChangeValue = tempValue && tempValue !== selected;
    const sameType = typeof tempValue === typeof val;

    if (shouldChangeValue && sameType) {
      onChangeValue(tempValue);
    }
    setShowValues(false);
    // run query with input value for parameterized
    if (isParameterized) {
      handleSelectOption({ [keyName]: tempValue });
    }
  };

  const filteredOptions = useMemo(() => {
    const values = filter?.values;
    const foundValues = (option) =>
      values?.find((value) => option[keyName] === value);

    if (!filtering) {
      if (switchingToFilterableOperator()) return [];
      return uniqueOptions.filter((option) => {
        return !values || !foundValues(option);
      });
      function switchingToFilterableOperator() {
        return !Array.isArray(uniqueOptions);
      }
    }

    return uniqueOptions.filter((option) => {
      const isIncludedTempValue = String(option[keyName])
        .toLowerCase()
        .includes(tempValue.toLowerCase());

      return !foundValues(option) && isIncludedTempValue;
    });
  }, [tempValue, uniqueOptions, keyName, filtering, filter]);

  function handleInputChange(e) {
    setTempValue(e.target.value);
    setFiltering(true);
    if (searchMode) {
      onChangeValue({ [keyName]: e.target.value });
    }
  }

  // only for parameterized queries, send request in enter
  function handleKeyDown(e) {
    if (isParameterized && e.code === "Enter") {
      handleBlur();
    }
  }

  return (
    <Wrapper ref={ref}>
      {props.children}
      <Input
        value={tempValue}
        placeholder={placeholder || "Set value..."}
        onChange={handleInputChange}
        onFocus={handleSetShowValues}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        data-cy={cy}
      />

      {showValues && !isParameterized ? (
        <ExplorerSelectPortal>
          <div ref={listRef}>
            <ExploreList
              list={filteredOptions}
              keyName={keyName}
              handleSelect={handleSelectOption}
              dimensions={dimensions}
              loading={loading}
            />
          </div>
        </ExplorerSelectPortal>
      ) : null}
    </Wrapper>
  );
}

PortalSelectInput.propTypes = {
  showValues: PropTypes.bool,
  setShowValues: PropTypes.func,
  uniqueOptions: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  keyName: PropTypes.string,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChangeValue: PropTypes.func,
  placeholder: PropTypes.string,
  cy: PropTypes.string,
};

const defaultDimensions = {
  left: 100,
  top: 100,
};
