import styled from "@emotion/styled";
import Select from "../../../../../UI/Form/Select/Select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo } from "react";
import { getRidOfAggregation } from "../../../../../charts/TableView/Elements/EditableMenu";
import { getAggregationPrefix } from "../../Layout/Column/RegularColumn";
import { getOverrideByName } from "../../Layout/helper";
import { omit } from "lodash-es";

const Container = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: center;
  border: 1px solid ${(props) => props.theme.divider};
  padding: 5px 10px 10px 10px;
  border-radius: 5px;
  position: relative;

  & > svg:first-of-type {
    font-size: 16px;
    margin: 22px 10px 0px 10px;
  }

  & > svg:last-of-type {
    color: ${(props) => props.theme.notification.errorMain};
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 5px;
  }
`;

export default function DrilldownDynamicFiltersMapping(props) {
  const {
    currentQuery,
    parentQuery,
    currentChart,
    onMappingChange,
    parentChart,
  } = props;

  // prefix for parameterized filters from the current chart, if it exists
  const prefix = currentChart?.parameterizedFilterPrefix ?? "";

  const dynamicFilterValue = currentChart.dynamicFilterValue ?? {};

  // Memoized keys based on the type of current query
  const keys = useMemo(() => {
    if (currentQuery.type === "parameterized") {
      return currentQuery.parameters ?? [];
    }

    // For other types, flatten and return fields from all data sources
    return currentQuery.dataSources.flatMap((ds) => ds.fields);
  }, [currentQuery]);

  // Memoized values based on the type of parent query
  const values = useMemo(() => {
    if (parentQuery.type === "parameterized") {
      return parentQuery.parameterizedFields ?? [];
    }

    // For other types, flatten and return fields from all data sources
    return parentQuery.dataSources.flatMap((ds) => ds.fields);
  }, [parentQuery]);

  // Function to get a specific key option by name, considering parameterized filter prefix
  function getKeyOption(keys, name) {
    // Find and return the key that matches the name or the prefixed name
    return (keys ?? []).find(
      (key) => key.name === name || key.name === prefix + name
    );
  }

  // Function to get a specific value option by name after removing aggregation
  function getValueOption(values, name) {
    // Remove aggregation from the name using a helper function
    const nonAggregated = getRidOfAggregation(name, parentChart.overrides);

    // Find and return the value that matches the non-aggregated name
    return (values ?? []).find((value) => value.name === nonAggregated);
  }

  // Handler for updating the mapping accordingly
  function onKeyChange(key, option) {
    // Create a copy of the dynamic filter value
    const object = { ...dynamicFilterValue };

    // removing prefix because queryBuilder will add it
    const name = (option.name ?? "").replace(prefix, "");

    // Delete the old key and add the new option to the object
    delete Object.assign(object, { [name]: object[key] })[key];

    // Trigger the mapping change with the updated object
    onMappingChange(object);
  }

  // Handler for updating the mapping with aggregation prefix
  function onValueChange(key, option) {
    // Get the override corresponding to the option name
    const override = getOverrideByName(parentChart.overrides, option.name);

    // Retrieve the prefix for the aggregation if it exists
    const prefix = getAggregationPrefix(override?.aggregation);

    // Trigger the mapping change with the updated value, prepending the aggregation prefix
    onMappingChange({ ...dynamicFilterValue, [key]: prefix + option.name });
  }

  // Handler for removing the mapping by key
  function onRemoveMapping(key) {
    onMappingChange(omit(dynamicFilterValue, key));
  }

  // show label or original column name
  function getLabelIfExist(option) {
    return option.mapping?.displayName || option.name;
  }

  return (
    <>
      {Object.keys(dynamicFilterValue).map((key, index) => (
        <Container key={key + index}>
          <Select
            label="Key for drill down"
            options={keys}
            getOptionLabel={getLabelIfExist}
            getOptionValue={(option) => option.name}
            value={getKeyOption(keys, key)}
            onChange={(option) => onKeyChange(key, option)}
            isClearable={false}
          />

          <FontAwesomeIcon icon={["fas", "link"]} />

          <Select
            label="Field name from parent"
            options={values}
            getOptionLabel={getLabelIfExist}
            getOptionValue={(option) => option.name}
            value={getValueOption(values, dynamicFilterValue[key])}
            onChange={(option) => onValueChange(key, option)}
            isClearable={false}
          />
          <FontAwesomeIcon
            icon={["far", "times-circle"]}
            onClick={() => onRemoveMapping(key)}
          />
        </Container>
      ))}
    </>
  );
}
