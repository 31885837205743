import { omit } from "lodash-es";
import { getRidOfAggregation } from "../../../../../../charts/TableView/Elements/EditableMenu";
import Select from "../../../../../../UI/Form/Select/Select";
import { aggregations } from "../../../../../../utils/constants/constants";
import { getCurrentAggregation } from "../../helper";

export default function GroupingAggregation(props) {
  const { isParameterized, column, multipleColumns, setChartState, chart } =
    props;

  const { overrides } = chart;

  if (isParameterized) {
    return null;
  }

  function setGroupingAggregation(option, column) {
    const aggregation = option.value;
    const nonAggregatedName = getRidOfAggregation(column, overrides);
    const aggregatedColumn = option.prefix + nonAggregatedName;

    const newOverrides = overrides.map((override) => {
      if (override.name === nonAggregatedName) {
        return !aggregation
          ? omit(override, "aggregation")
          : { ...override, aggregation };
      }

      return override;
    });

    const keys = multipleColumns.map((key) =>
      key === column ? aggregatedColumn : key
    );

    setChartState({
      ...chart,
      rowGroupKey: keys.join("__"),
      overrides: newOverrides,
    });
  }

  // aggretagion select value
  function getAggregationValue(column) {
    return getCurrentAggregation(column, overrides);
  }

  return (
    <div style={{ width: "34%" }}>
      <Select
        options={[{ label: "None", prefix: "" }, ...aggregations]}
        value={getAggregationValue(column)}
        label="Aggregate"
        onChange={(option) => setGroupingAggregation(option, column)}
        isDisabled={!column}
      />
    </div>
  );
}
