import { getRidOfAggregation } from "../../../../../../charts/TableView/Elements/EditableMenu";
import Select from "../../../../../../UI/Form/Select/Select";
import { columnFormats } from "../../../../../../utils/constants/constants";
import { getNestedFormat } from "../../helper";

export default function GroupingFormat(props) {
  const { getOverride, chart, setChartState, column } = props;
  const { overrides } = chart;

  function setGroupingFormat(option, column) {
    const nonAggregatedName = getRidOfAggregation(column, overrides);

    setChartState({
      ...chart,
      overrides: overrides.map((override) => {
        if (override.name === nonAggregatedName) {
          return {
            ...override,
            mapping: { ...(overrides.mapping ?? {}), type: option.value },
          };
        }
        return override;
      }),
    });
  }

  // format select value
  function getMappingType(column) {
    const override = getOverride(column);
    return getNestedFormat(override.mapping?.type);
  }

  return (
    <div style={{ width: "85%" }}>
      <Select
        options={columnFormats}
        value={getMappingType(column)}
        onChange={(option) => setGroupingFormat(option, column)}
        label="Format"
      />
    </div>
  );
}
