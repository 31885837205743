import React, { useCallback } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import SimpleTooltip from "../../UI/SimpleTooltip/SimpleTooltip";
import formatter from "../../utils/formatters/formatter";
import { scrollbarDe } from "../../styles/styledSnippets";
import { generateDynamicLegendFigure } from "./helper";
import { orderBy } from "lodash-es";

const LegendContainer = styled.div(
  ({ theme, highTop, horizontal, wrapping, legendHeight }) => `
  padding: 12px;
  box-sizing: border-box;
  margin-left: 5px;
  margin-top: ${highTop ? -30 : 0}px;
  display: flex;
  flex-direction: ${horizontal ? "row" : "column"};
  flex-wrap: ${wrapping ? "wrap" : "nowrap"};
  position: relative;

  ${
    legendHeight &&
    `
    max-height: ${legendHeight}px;
    min-height: ${legendHeight}px;
    overflow-y: auto;
    ${scrollbarDe(theme)};
  `
  };
 
`
);

const Label = styled.div`
  margin: 0 18px 0 6px;
  font-size: 12px;
  text-align: left;
  color: ${({ theme }) => theme.text.secondary};
  white-space: ${(props) => (props.wrapping ? "nowrap" : "wrap")};
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

function Legend(props) {
  const {
    colors,
    colorFunction,
    sections,
    highTop,
    wrapping,
    horizontal,
    border,
    legendItems,
    hide,
    legendTooltips,
    groupByFormat,
    meta,
    term,
    ignoreDateTerm,
    legendHeight,
  } = props;

  const matchLegendItem = useCallback(
    (section, i) => {
      return legendItems.find((l) => l.name === section)?.color ?? colors(i);
    },
    [colors, legendItems]
  );

  const matchColorFunctionOrArray = useCallback(
    (i) => {
      return colorFunction ? colors(i) : colors[i];
    },
    [colorFunction, colors]
  );

  const getItemColor = useCallback(
    (section, index) => {
      return legendItems
        ? matchLegendItem(section, index)
        : matchColorFunctionOrArray(index);
    },
    [legendItems, matchColorFunctionOrArray, matchLegendItem]
  );

  const renderLegend = useCallback(
    (children, key) => {
      if (legendTooltips) {
        return (
          <SimpleTooltip
            key={key}
            label={legendTooltips.find((lt) => lt.name === key)?.popup}
            position="top"
          >
            {children}
          </SimpleTooltip>
        );
      }
      return children;
    },
    [legendTooltips]
  );

  const getLabel = useCallback(
    (section) => {
      if (legendItems) {
        const item = legendItems?.find((l) => l.name === section);
        return item?.label ?? item?.override ?? item?.name;
      }

      const label = getMetaVersion() ?? section;
      function getMetaVersion() {
        const match = meta && meta.fields.find((f) => f.alias === section);
        return match?.label;
      }
      const format = groupByFormat || term;

      return format && !ignoreDateTerm ? formatter(label, format) : label;
    },
    [groupByFormat, legendItems, term, ignoreDateTerm, meta]
  );

  const getType = (section) => {
    return (legendItems ?? []).find((item) => item.name === section)?.type;
  };

  if (!sections || !sections.length || hide) {
    return null;
  }

  const indexedSections = sections.map((section) => ({
    name: section,
    index: (legendItems ?? []).findIndex((item) => item.name === section),
  }));

  const orderedSections = orderBy(indexedSections, "index").map(
    (section) => section.name
  );

  return (
    <LegendContainer
      wrapping={wrapping}
      highTop={highTop}
      border={border}
      horizontal={horizontal}
      legendHeight={legendHeight}
    >
      {orderedSections.map((section, index) => {
        const label = getLabel(section);
        const type = getType(section);
        const Figure = generateDynamicLegendFigure(type);

        if (!label || label === "undefined" || label === "null") {
          return null;
        }

        return renderLegend(
          <Flex key={section || index} data-cy="legend-item" align>
            <Figure
              color={getItemColor(section, index)}
              data-cy="legend-square"
            />
            <Label wrapping={wrapping}>{label}</Label>
          </Flex>,
          section
        );
      })}
    </LegendContainer>
  );
}

Legend.propTypes = {
  colors: PropTypes.oneOfType([PropTypes.func, PropTypes.array]),
  sections: PropTypes.array,
  colorFunction: PropTypes.bool,
  horizontal: PropTypes.bool,
  wrapping: PropTypes.bool,
};

export default React.memo(Legend);
