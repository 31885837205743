import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "@emotion/styled";
import { scrollbarConfig } from "../../../styles/styledSnippets";
import ActiveTableColumnSelectorControls from "./ActiveTableColumnSelectorControls";
import DraggableColumnTile from "./DraggableColumnTile";
import useActiveTableColumnSettings from "./useActiveTableColumnSettings";

const ColumnSelector = styled.div`
  display: flex;
  margin-bottom: 12px;
  align-items: flex-start;
  svg path {
    fill: white;
  }
  & > div {
    padding-bottom: 4px;
    ${(props) =>
      scrollbarConfig({
        background: props.theme.background.secondary,
        thumbBorderSecondary: true,
      })(props)}
  }
`;

const addSize = 24;
const Add = styled.div`
  height: ${addSize}px;
  width: ${addSize}px;
  border-radius: 50%;
  background: ${(props) => props.theme.notification.infoMain};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 6px;
  flex-shrink: 0;
  flex-grow: 0;
  position: relative;
  top: 1px;
`;

export default function ActiveTableColumnSelector(props) {
  const {
    setNextColumns,
    handleSetActive,
    hasTempField,
    columns,
    active,
    handleAddColumn,
    hideAdd,
    hideVisible,
    hideChecklist,
  } = props;
  const {
    maxChars,
    increaseTileSize,
    decreaseTileSize,
    stringMatch,
    handleStringMatchChange,
    filteredColumns,
    showEditable,
    toggleShowEditable,
    showVisible,
    toggleShowVisible,
  } = useActiveTableColumnSettings(columns);

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(filteredColumns);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setNextColumns(items);
  };

  // disable drag and drop when user using search to filter columns
  // length of columns change and position will be wrong
  const isDragDisabled = stringMatch.length > 0;

  return (
    <div>
      <ActiveTableColumnSelectorControls
        increaseTileSize={increaseTileSize}
        decreaseTileSize={decreaseTileSize}
        stringMatch={stringMatch}
        handleStringMatchChange={handleStringMatchChange}
        showEditable={showEditable}
        toggleShowEditable={toggleShowEditable}
        showVisible={showVisible}
        toggleShowVisible={toggleShowVisible}
        hideVisible={hideVisible}
        hideChecklist={hideChecklist}
        maxChars={maxChars}
      />
      <ColumnSelector>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable
            droppableId="columns"
            direction="horizontal"
            renderClone={(provided, snapshot, rubric) => (
              <DraggableColumnTile
                provided={provided}
                handleSetActive={handleSetActive}
                active={active}
                col={filteredColumns[rubric.source.index]}
                maxChars={maxChars}
                dragging
              />
            )}
          >
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{
                  display: "flex",
                  overflowX: "auto",
                  overflowY: "visible",
                }}
              >
                {filteredColumns
                  .filter((item) => (showVisible ? item.visible : true))
                  .map((col, index) => (
                    <Draggable
                      key={col.field + index}
                      draggableId={col.field + "id" + index}
                      index={index}
                      isDragDisabled={isDragDisabled}
                    >
                      {(provided) => (
                        <DraggableColumnTile
                          provided={provided}
                          handleSetActive={handleSetActive}
                          active={active}
                          col={col}
                          maxChars={maxChars}
                        />
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        {!hasTempField && !hideAdd ? (
          <div>
            <Add data-cy="add-field" onClick={handleAddColumn}>
              <FontAwesomeIcon icon={["fal", "plus"]} />
            </Add>
          </div>
        ) : null}
      </ColumnSelector>
    </div>
  );
}
