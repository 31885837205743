export default function (comparisonMode, dashboardId, nonStrict) {
  if (!comparisonMode || !dashboardId) {
    return false;
  }

  const matchedComparisonSettings = getComparisonModeItem(
    comparisonMode,
    dashboardId
  );

  if (!matchedComparisonSettings || !comparisonModeOnPage()) {
    return false;
  }

  if (!matchedComparisonSettings.checked) {
    return true;
  } else if (nonStrict) {
    return matchedComparisonSettings.checked <= matchedComparisonSettings.count;
  } else if (!Number.isFinite(matchedComparisonSettings.count)) {
    return !matchedComparisonSettings.checked;
  } else {
    return matchedComparisonSettings.checked < matchedComparisonSettings.count;
  }

  function comparisonModeOnPage() {
    if (!matchedComparisonSettings.pages) return true;
    return !!matchedComparisonSettings.pages.find(
      (page) => page === dashboardId
    );
  }
}

export function getComparisonModeItem(comparisonMode, tabUuid) {
  return (comparisonMode ?? []).find((item) =>
    item.pages?.find((page) => page === tabUuid)
  );
}
