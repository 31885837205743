import { arrFormula } from "./valueParser";

export default (arr, config, lowerValueKey) => {
  const {
    multiKey,
    filter,
    valueKey,
    multiKeyFormulas,
    valueKey1,
    valueKey2,
    groupBy,
  } = config;

  if (groupBy) {
    const total = arr.map((d) => d[valueKey]).reduce((acc, curr) => acc + curr);
    const value = arr
      .filter((d) => d[groupBy] === config.title)
      .map((d) => d[valueKey]);
    if (config.requiresTotals) {
      return [value[0] / total];
    } else return value;
  }

  if (valueKey1 && valueKey2) {
    return arr.reduce((acc, curr) => {
      acc.push(curr[valueKey1]);
      acc.push(curr[valueKey2]);
      return acc;
    }, []);
  }

  if (lowerValueKey) return arr.map((item) => item[lowerValueKey]);

  if (!multiKey) return filterMapped(filter, valueKey)(arr);

  const splitted = multiKey.map((m) => arr.map((a) => a[m]));

  return multiKeyFormulas
    ? multiKeyFormulasMapped(multiKeyFormulas, multiKey)(splitted)
    : splitted;
};

const filterMapped = (filter, valueKey) => (arr) => {
  const fil = filter ? arr.filter((a) => a[filter.key] === filter.value) : arr;
  return fil.map((a) => a[valueKey]);
};

const multiKeyFormulasMapped = (formulas, keys) => (arr) => {
  return arr.map((s, i) => {
    const match = formulas.find((f) => f.name === keys[i]);
    return match ? arrFormula(s, match.formula) : s;
  });
};

function periodOverPeriod(data, keys, key) {
  return {
    [keys[0]]: data[0][key],
    [keys[1]]: data[1][key],
  };
}

export const buildCardBasedOnFormula = (
  data,
  config,
  key,
  index,
  arrayFormula
) => {
  if (arrayFormula) {
    if (arrayFormula === "previous") return data[1];
    if (arrayFormula === "first") return data[0];
    if (arrayFormula === "last") return data[data.length - 1];
    return data[0];
  }
  if (!config || !config[index]) {
    return data[0];
  }

  const { formula, keys } = config[index];

  const operations = {
    PERIOD_OVER_PERIOD: periodOverPeriod(data, keys, key),
  };

  return operations[formula] ?? data[0];
};
