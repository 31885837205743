import Switcher from "../../../../../UI/Switcher/Switcher";

export default function ZebraBackgroundSettings(props) {
  const { chart, setChartState } = props;

  const { hideRowZebraBackground, hideColumnZebraBackground } = chart;

  function changeZebraBackground(key) {
    setChartState({
      ...chart,
      [key]: !chart[key],
    });
  }

  return (
    <>
      <br />
      <Switcher
        rightLabel="Hide row zebra background"
        checked={!!hideRowZebraBackground}
        handleSwitchChange={() =>
          changeZebraBackground("hideRowZebraBackground")
        }
      />

      <br />

      <Switcher
        rightLabel="Hide column zebra background"
        checked={!!hideColumnZebraBackground}
        handleSwitchChange={() =>
          changeZebraBackground("hideColumnZebraBackground")
        }
      />
    </>
  );
}
