import React from "react";
import styled from "@emotion/styled";
import { themeColor } from "../../styles/styledSnippets";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useBooleanToggle from "../../hooks/useBooleanToggle";
import IOButton from "../../UI/Form/Button/IOButton";
import { faSpiderBlackWidow } from "@fortawesome/pro-solid-svg-icons";

const OuterContainer = styled.div`
  background: ${themeColor("errorMain")};
  border-radius: 6px;
  padding: 18px 24px;
  margin-bottom: 12px;
  color: white;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;
const circleSize = 52;
const Emoji = styled.div`
  margin-right: 16px;
  font-size: 36px;
  height: ${circleSize}px;
  width: ${circleSize}px;
  border-radius: 50%;
  border: 1px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.2);
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 4px;
`;

const Details = styled.div`
  font-weight: 200;
  cursor: pointer;
  font-size: 12px;
  margin-left: 18px;
  svg {
    font-size: 8px;
    color: yellow;
    margin-right: 6px;
  }
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
`;

export default function DataError(props) {
  const { dataError, dataErrorDetail, closeMessage } = props;
  const [showDetails, setShowDetails] = useBooleanToggle(false);
  if (!dataError && !dataErrorDetail) return null;
  const allKeys = Object.keys(dataErrorDetail);

  return (
    <OuterContainer>
      <Container>
        <Emoji>
          <FontAwesomeIcon icon={faSpiderBlackWidow} />
        </Emoji>
        <div>
          {showDetails ? (
            <DataErrorDetails errorKeys={allKeys} errors={dataErrorDetail} />
          ) : (
            <>
              <Title>{dataError}</Title>
              <Details onClick={setShowDetails}>
                <FontAwesomeIcon icon={["fal", "chevron-double-right"]} />
                details...
              </Details>
            </>
          )}
        </div>
      </Container>

      <IOButton cancel onClick={closeMessage}>
        Got it
      </IOButton>
    </OuterContainer>
  );
}

const FieldTitle = styled.div`
  font-weight: 500;
`;

const ErrorItem = styled.div`
  font-size: 12px;
  font-weight: 400;
`;

const DataErrorDetails = (props) => {
  return props.errorKeys.map((k) => (
    <div key={k}>
      <FieldTitle>
        <b>{k}</b>
      </FieldTitle>
      {props.errors[k].map((m, i) => (
        <ErrorItem key={i}>&#x2022; {m}</ErrorItem>
      ))}
    </div>
  ));
};
