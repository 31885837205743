import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/material.css";
import "tippy.js/animations/scale.css";
import "tippy.js/themes/light.css";
import "tippy.js/themes/light-border.css";
import "tippy.js/animations/shift-away.css";
import "tippy.js/animations/shift-toward.css";
import "tippy.js/animations/perspective.css";
import styled from "@emotion/styled";

// freeze left column is not working properly when wrapped by tooltip
// this make sticky container wrapper
const Container = styled.div(
  ({ freeze }) => `
  ${
    freeze &&
    `
      position: sticky;
      left: 0;
      z-index: 2;
    `
  }
`
);

export default function TippyTooltipWrapper({
  children,
  content,
  freeze,
  ...rest
}) {
  return (
    <Tippy
      placement="top"
      animation="scale"
      arrow={true}
      theme="material"
      // Need to ensure it can be tabbed to directly after with no clipping issues
      appendTo="parent"
      content={content}
      {...rest}
    >
      <Container freeze={freeze}>{children}</Container>
    </Tippy>
  );
}
