import React, { useCallback } from "react";
import getVisualizationLabel, {
  getVisualizationHeaderDescription,
} from "../../utils/getVisualizationLabel";
import Th from "./Elements/Th";
import { last } from "../../utils/func";
import { useTheme } from "emotion-theming";
import tableFunctionConvertor from "./functions/tableFunctionConvertor";
import { useSelector } from "react-redux";
import { getTextAlignByColumnType } from "./functions/tableMapper";

const hasBackground = (hasNumeration, clearBackground) => (index) => {
  return clearBackground
    ? "none"
    : hasNumeration
    ? index % 2 === 0 && index !== 0
    : index % 2;
};

export default function ColumnHeaders(props) {
  const {
    headers,
    subTitles,
    hiddenSubTitles,
    hasNumeration,
    meta,
    isSortable,
    setSortKey,
    sortKey,
    sortOrder,
    freezeLeft,
    freezeWidth,
    rollup,
    dynamicHeaderFormat,
    emptyFreezeLeft,
    expanded,
    generalColumnsWidth = 0,
    dynamicColumnsWidth = {},
    allowGroupsRightBorder,
    clearBackground,
    headerColor,
    headerColorConfig,
    dynamicSubtitleTerm,
    stickyHeaders,
    dynamicHeadersAlign = {},
    editable,
    uuid,
    fromFullDetails,
    beforeCells,
    hideSubTitleHeaders,
    freezeNextColumn,
    maxColumnsWidth,
    enumColorsSettings,
    dateFilters,
    horizontalTotals,
    hideColumnZebraBackground,
    textAlignOverride,
  } = props;

  const backPartial = hasBackground(hasNumeration, clearBackground);
  const theme = useTheme();
  const term = useSelector((state) => state.layout.currentDateTerm);

  const getHeaderFormulaDefinition = useCallback(
    (header) => {
      return tableFunctionConvertor(header, {}, null, null, meta).description;
    },
    [meta]
  );

  const getHeaderRegularDefinition = useCallback(
    (header) => {
      return getVisualizationHeaderDescription(meta?.fields, header);
    },
    [meta?.fields]
  );

  // for subtitles case [["1", "2", "3"...]]
  const oneLineSubtitles = subTitles?.length === 1 && subTitles[0].length > 1;

  const getFreezeParameter = useCallback(
    (innerIndex, outerIndex) => {
      if (!freezeLeft) {
        return false;
      }

      const indexes = [0];
      if (freezeNextColumn) {
        indexes.push(1);
      }

      return oneLineSubtitles ? indexes.includes(innerIndex) : outerIndex === 0;
    },
    [freezeLeft, freezeNextColumn, oneLineSubtitles]
  );

  const getEnumColumnColor = useCallback(
    (header) => {
      if (!enumColorsSettings) {
        return;
      }

      // if use for entry column is enabled
      return enumColorsSettings.find(
        (item) => item.column === header && item.useForColumn
      );
    },
    [enumColorsSettings]
  );

  const vals = subTitles
    ? subTitles.map(
        (subTitle, index) =>
          (!headers || index < headers.length) &&
          subTitle.map((item, i) => {
            const isFormula = item?.includes("fn::") || item?.includes("omf::");
            const hasBorder =
              allowGroupsRightBorder && i === subTitle.length - 1;

            const uniqueKey = item + index + "-" + i;

            if (isFormula) {
              return (
                <Th
                  key={uniqueKey}
                  background={backPartial(oneLineSubtitles ? i : index)}
                  headerColor={headerColor}
                  headerColorConfig={headerColorConfig}
                  pointer={isSortable}
                  onClick={
                    isSortable ? () => setSortKey(item, uniqueKey, index) : null
                  }
                  sorting={isSortable && sortKey === uniqueKey}
                  sortOrder={sortOrder}
                  generalColumnsWidth={generalColumnsWidth}
                  dynamicColumnsWidth={dynamicColumnsWidth[item]}
                  maxColumnsWidth={maxColumnsWidth[item]}
                  expanded={expanded}
                  borderRight={hasBorder || item.includes("::BORDERED")}
                  definition={getHeaderFormulaDefinition(item)}
                  stickyHeaders={stickyHeaders}
                  textAlign={getTextAlignByColumnType(
                    item,
                    isFormula,
                    dynamicHeadersAlign,
                    meta,
                    textAlignOverride
                  )}
                  editable={editable}
                  column={item}
                  visualizationUuid={uuid}
                  fromFullDetails={fromFullDetails}
                  hideSubTitleHeaders={hideSubTitleHeaders}
                  hideColumnZebraBackground={hideColumnZebraBackground}
                >
                  {last(item.split("::"))}
                </Th>
              );
            }

            if (
              hiddenSubTitles &&
              hiddenSubTitles.some((hidden) => hidden === item)
            ) {
              return (
                <Th
                  key={uniqueKey}
                  background={backPartial(index)}
                  generalColumnsWidth={generalColumnsWidth}
                  dynamicColumnsWidth={dynamicColumnsWidth[item]}
                  maxColumnsWidth={maxColumnsWidth[item]}
                  borderRight={hasBorder}
                  headerColor={headerColor}
                  definition={getHeaderRegularDefinition(item)}
                  stickyHeaders={stickyHeaders}
                  freeze={freezeLeft && index === 0}
                  freezeWidth={freezeWidth}
                  textAlign={dynamicHeadersAlign?.[item]}
                  editable={editable}
                  column={item}
                  visualizationUuid={uuid}
                  fromFullDetails={fromFullDetails}
                  hideSubTitleHeaders={hideSubTitleHeaders}
                  headerColorConfig={headerColorConfig}
                  hideColumnZebraBackground={hideColumnZebraBackground}
                />
              );
            }

            return (
              <Th
                pointer={isSortable}
                onClick={
                  isSortable ? () => setSortKey(item, uniqueKey, index) : null
                }
                key={uniqueKey}
                background={backPartial(oneLineSubtitles ? i : index)}
                sorting={isSortable && sortKey === uniqueKey}
                sortOrder={sortOrder}
                freeze={getFreezeParameter(i, index)}
                freezeWidth={freezeWidth}
                rollup={rollup}
                dynamicHeaderFormat={dynamicHeaderFormat}
                generalColumnsWidth={generalColumnsWidth}
                dynamicColumnsWidth={dynamicColumnsWidth[item]}
                maxColumnsWidth={maxColumnsWidth[item]}
                borderRight={hasBorder}
                headerColor={headerColor}
                definition={getHeaderRegularDefinition(item)}
                stickyHeaders={stickyHeaders}
                textAlign={getTextAlignByColumnType(
                  item,
                  false,
                  dynamicHeadersAlign,
                  meta,
                  textAlignOverride
                )}
                editable={editable}
                column={item}
                visualizationUuid={uuid}
                fromFullDetails={fromFullDetails}
                hideSubTitleHeaders={hideSubTitleHeaders}
                freezeNextColumn={freezeNextColumn && i === 1}
                headerColorConfig={headerColorConfig}
                enumBackground={getEnumColumnColor(item)}
                isHorizontalTotal={horizontalTotals?.header === item}
                hideColumnZebraBackground={hideColumnZebraBackground}
              >
                {index === 0 && emptyFreezeLeft
                  ? null
                  : getDynamicSubtitle(
                      item,
                      term,
                      meta,
                      dynamicSubtitleTerm,
                      dateFilters
                    )}
              </Th>
            );
          })
      )
    : null;

  const backgroundNone = hideColumnZebraBackground || clearBackground;

  return (
    <tr
      style={{
        background: backgroundNone
          ? "none"
          : `rgba(0,0,0,${theme.type === "dark" ? 0.2 : 0.15})`,
      }}
    >
      {beforeCells}
      {vals}
    </tr>
  );
}

function getDynamicSubtitle(
  header,
  term,
  meta,
  dynamicSubtitleTerm = [],
  dateFilters
) {
  if (dynamicSubtitleTerm.includes(header)) {
    return term;
  }

  return getVisualizationLabel(meta?.fields, header, dateFilters);
}
