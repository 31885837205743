import React, { createRef, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useDispatch, useSelector } from "react-redux";
import ChartFactory from "../../ChartFactory/ChartFactory";
import { exportXlsx } from "../../../utils/exportTableToExcel";
import { removeExpandRowsOnExport } from "../../../store/actions";
import getVisualizationLabel from "../../../utils/getVisualizationLabel";
import { hexToRgba } from "../../../styles/colorConvertor";
import { apiExport } from "../../../store/actions/dashboard/dashboard";
import Flex from "../../../UI/Flex/Flex";
import { applyPinnedRowOffset, getExistingPinnedRows } from "./Tr";

const ContainerOverrideColors = styled.div(
  ({ theme }) => `
  background: ${theme.background.primary};
  .expand-all {
    top: 0;
    left: 50px;
  }
`
);

const DetailVizualizationWrapper = styled.div(
  ({ theme, expandedRowBackground, noBorder }) => `
  border: ${
    noBorder ? "none" : `2px solid ${expandedRowBackground || theme.primary}`
  };
  background: ${!noBorder && hexToRgba(expandedRowBackground, "5%")};
  padding: 20px 0px;
`
);

export const Span = styled.span(
  ({ theme, freezeLeft }) => `
  display: flex;
  font-size: 12px;
  text-decoration: none;
  color: ${theme.text.secondary};
  font-style: italic;
  margin: 10px 10px 0px 10px;
  ${
    freezeLeft &&
    `
    position: sticky;
    left: 12px;
    width: fit-content;
  `
  };

  &:hover {
      color: ${theme.text.primary};
  }
  cursor: pointer;
`
);

const BreakdownBy = styled.div`
  font-size: 12px;
  font-style: italic;
  padding: 1px 5px;
`;

export default React.memo(function ExpandedRowVisualization({
  config,
  setRowIndexes,
  title,
  activeTab,
  dateFilters,
  menuFilters,
  breakdownBy,
  pinnedRows,
  row,
  tHeadHeight,
  totals,
  hasParentHeaders,
  dataValues,
  rowRef,
}) {
  // freeze breakdownBy label to do not update it when next row is open with different key
  const [staticBreakdownBy] = useState(breakdownBy);

  const { rowExpandedCharts, currentVisId } = useSelector(
    (state) => state.dashboard
  );
  const { visualizationId, type, canEdit, term, freezeLeft } = config;
  const ref = createRef(null);
  const dispatch = useDispatch();

  const chart =
    rowExpandedCharts[config.uuid]?.[visualizationId] ??
    rowExpandedCharts[visualizationId];

  // this need for more then 1 level drill down
  useEffect(() => {
    if (!chart && currentVisId) {
      setRowIndexes([]);
    }
  }, [chart, currentVisId, setRowIndexes]);

  if (!chart) {
    return null;
  }

  const {
    loading,
    refreshing,
    error,
    data,
    filters,
    exportType = "FE",
    exportFormat,
  } = chart;
  const staticWidth = loading || refreshing || error || !data.length;

  const setSelectedRowFilter = (filters) => {
    return filters.reduce((acc, curr) => {
      const name = (curr.type || "").replace("Filtered", "");
      const label = getVisualizationLabel(config.meta?.fields, name);

      if (label) {
        acc.push({
          displayName: label,
          values: [
            {
              checked: true,
              label: curr.value,
            },
          ],
        });
      }

      return acc;
    }, []);
  };

  const isApiExort = exportType === "API";

  const handleExport = () => {
    const tables = Array.from(ref.current.getElementsByTagName("table"));
    const othersExpanded = tables.filter(
      (table) => table.id !== visualizationId
    );

    const filename = title || chart.filename || "IO-Export";
    const selectedDynamicFilter = setSelectedRowFilter(filters);

    // this need for more then 1 level drill down
    if (othersExpanded.length) {
      const ids = othersExpanded.map((mle) =>
        mle.closest("tr").id.replace("tr", "")
      );

      return dispatch(
        removeExpandRowsOnExport(
          ids,
          visualizationId,
          chart.nonConvertPercentsBack,
          filename,
          activeTab,
          dateFilters,
          [...menuFilters, ...selectedDynamicFilter]
        )
      );
    }

    exportXlsx(
      visualizationId,
      !chart.nonConvertPercentsBack,
      filename,
      activeTab,
      dateFilters,
      [...menuFilters, ...selectedDynamicFilter]
    );
  };

  /**
   * Exports data from API using the apiExport function with the specified chart type and format.
   *
   * @param {Boolean} allRecords
   */
  function exportFromApi(allRecords) {
    dispatch(apiExport(chart, allRecords, exportFormat));
  }

  const chartFactory = (
    <>
      {staticBreakdownBy && (
        <BreakdownBy>Breakdown by: {staticBreakdownBy}</BreakdownBy>
      )}
      <ChartFactory
        type={type}
        key={visualizationId}
        chart={{
          ...chart,
          id: visualizationId,
          fileName: title,
          stickyHeaders: false,
        }}
        term={term}
        canEdit={canEdit}
        title={title}
        width={staticWidth ? "calc(100vw - 250px)" : "100%"}
      />
      <Flex>
        {visualizationId && (
          <Span
            onClick={isApiExort ? () => exportFromApi(false) : handleExport}
            freezeLeft={freezeLeft}
          >
            Export
          </Span>
        )}
        {isApiExort && (
          <Span onClick={() => exportFromApi(true)} freezeLeft={freezeLeft}>
            Export with all columns
          </Span>
        )}
      </Flex>
    </>
  );

  const pinnedRow = applyPinnedRowOffset({
    pinnedRows: getExistingPinnedRows(pinnedRows, dataValues),
    row,
    tHeadHeight,
    totals,
    hasParentHeaders,
    ref: rowRef,
  });

  const styles = {};
  // pin expanded row
  if (pinnedRow && pinnedRow.position === "top") {
    styles.position = "sticky";
    styles.top = pinnedRow.offset + pinnedRow.rowHeight; // expanded row is under pinned
    styles.zIndex = 21;
  }

  return (
    <tr ref={ref} id={visualizationId + "tr"} style={styles}>
      <td colSpan={Number.MAX_SAFE_INTEGER} style={{ padding: 0 }}>
        <ContainerOverrideColors>
          <DetailVizualizationWrapper
            expandedRowBackground={config.expandedRowBackground}
          >
            {chartFactory}
          </DetailVizualizationWrapper>
        </ContainerOverrideColors>
      </td>
    </tr>
  );
});
