import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "@emotion/styled";
import AddDrilldown from "./DrilldownModal/AddDrilldown";
import DrilldownInformation from "./DrilldownInformation";
import LevelIndicatorIcon from "./LevelIndicatorIcon";
import {
  canAddDrilldownItem,
  canClickDrilldownItem,
  cleanDrilldownParent,
  getPath,
} from "../../utils/tableEditorHelper";

const DrillDownContainer = styled.div(
  ({ theme, level, background, canClick }) => `
  width: 160px;
  height: 200px;
  background: ${background ?? theme.blueGray.blueGray300};
  border-radius: 5px;
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: center;
  color: white;
  box-sizing: border-box;
  justify-content: space-between;
  margin-left: ${level * 30}px;
  margin-top: 20px;
  position: relative;
  ${canClick && `cursor: pointer;`};
`
);

const DrilldownTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  width: 100%;
  svg {
    cursor: ${(props) => (props.canClick ? "pointer" : "default")};
  }
`;

const NestedLine = styled.div(
  ({ theme }) => `
  height: 70px;
  width: 17px;
  left: 10px;
  bottom: -70px;
  position: absolute;
  border-left: 2px dotted ${theme.divider};
  border-bottom: 2px dotted ${theme.divider};
`
);

export default function DrilldownItem(props) {
  const {
    chart,
    level = 0,
    queries,
    levelIndicator,
    updatePreview,
    levelData,
    setEditLevel,
    localChart,
    setTemporaryChartConfig,
    parent,
    drilldownParent,
    setDrilldownParent,
    indicatorParent = "",
    path = "",
    setParent,
  } = props;

  const {
    rowExpandVisualizationParams,
    hasRowExpand,
    expandedRowBackground,
    dynamicFilters,
    dynamicDrilldowns,
  } = chart;

  const levelName = level ? `Level ${level}` : "Top Level";
  const drilldownKeys = dynamicDrilldowns
    ? Object.keys(rowExpandVisualizationParams)
    : [""];

  const getQueryName = () => {
    const query = (queries || []).find((query) => query.uuid === chart.queryId);
    return [query?.name ?? ""];
  };

  const getFilteredBy = () => {
    return dynamicFilters ?? [];
  };

  const canClick = canClickDrilldownItem(
    levelData,
    level,
    levelIndicator,
    drilldownParent,
    path,
    drilldownKeys
  );

  const canAdd = canAddDrilldownItem(
    level + 1,
    levelIndicator,
    drilldownParent,
    indicatorParent
  );

  const onDrilldownItemClick = () => {
    if (canClick) {
      updatePreview(level, level ? parent : "", dynamicFilters);
      setDrilldownParent((prev) => cleanDrilldownParent(prev, level + 1));
    }
  };

  const renderDynamicDrildowns = (key, index) => {
    const params = key
      ? rowExpandVisualizationParams[key]
      : rowExpandVisualizationParams;

    return (
      chart.hasRowExpand && (
        <DrilldownItem
          {...props}
          chart={{
            ...chart,
            ...params,
          }}
          level={level + 1}
          key={key + index}
          parent={key}
          indicatorParent={key || indicatorParent}
          path={getPath(key || indicatorParent, path)}
        />
      )
    );
  };

  const onEditClick = (e) => {
    e.stopPropagation();
    if (canClick) {
      if (parent) {
        setDrilldownParent({ ...drilldownParent, [level]: parent });
        setParent(parent);
      }
      setTemporaryChartConfig({ ...localChart, addAction: false });
      setEditLevel(level);
    }
  };

  const drawLine = hasRowExpand || canAdd;

  return (
    <>
      <DrillDownContainer
        level={level}
        background={expandedRowBackground}
        onClick={onDrilldownItemClick}
        canClick={canClick}
        data-cy="drill-down-section"
      >
        <DrilldownTitle canClick={canClick}>
          <span>{levelName}</span>

          {!!level && (
            <FontAwesomeIcon
              icon={["fas", "pencil-alt"]}
              onClick={onEditClick}
            />
          )}
        </DrilldownTitle>

        {!!(parent && level) && (
          <DrilldownInformation title="Breakdown by:" values={[parent]} level />
        )}

        <LevelIndicatorIcon
          levelIndicator={levelIndicator}
          level={level}
          color={expandedRowBackground}
          drilldownParent={drilldownParent}
          indicatorParent={indicatorParent}
        />

        <DrilldownInformation title="Query" values={getQueryName()} level />

        <DrilldownInformation
          title="Filtered by"
          values={getFilteredBy()}
          level={level}
        />

        {!!(drawLine || canAdd) && <NestedLine />}
      </DrillDownContainer>

      <AddDrilldown
        level={level + 1}
        setEditLevel={setEditLevel}
        chart={localChart}
        setTemporaryChartConfig={setTemporaryChartConfig}
        canAdd={canAdd}
        drilldownParent={drilldownParent}
        setDrilldownParent={setDrilldownParent}
        setParent={setParent}
      />

      {drilldownKeys.map(renderDynamicDrildowns)}
    </>
  );
}
