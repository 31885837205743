import React from "react";
import RemoveDot from "../../../../../UI/Form/InputSet/RemoveDot";
import AddDot from "../../../../../UI/Form/InputSet/AddDot";
import PortalSelectInput from "./PortalSelectInput";
import PropTypes from "prop-types";

export default function ExploreListInput(props) {
  const {
    filter,
    setShowValues,
    showValues,
    uniques: uniqueOptions,
    handleSetValue,
    handleRemoveValue,
    handleAddValue, // show new value input
    showHandleAddValue,
    valueIndex,
    fixedInputCount,
    loading,
    isParameterized,
  } = props;

  const handleRemoveValueAction = () => {
    handleRemoveValue(valueIndex);
    handleAddValue(false);
  };

  const searchMode = filter.operator === "like";

  function onChangeValue(option) {
    if (option) {
      handleSetValue(valueIndex, option[filter.name]);
    }
  }

  function handleSetShowValues(active) {
    setShowValues(active ? (searchMode ? null : valueIndex) : null);
  }

  function currentSelectedValue() {
    if (filter?.values) {
      return { [filter.name]: filter.values[valueIndex] };
    }

    return null;
  }
  const selectedValue = currentSelectedValue();

  const passProps = {
    setShowValues: handleSetShowValues,
    showValues: showValues === valueIndex,
    valueIndex,
    uniqueOptions,
    filter,
    keyName: filter.name,
    selectedValue,
    onChangeValue,
    searchMode,
    loading,
    cy: "explorer-filter-value",
    isParameterized,
  };

  return (
    <PortalSelectInput {...passProps}>
      {valueIndex && !fixedInputCount ? (
        <RemoveDot onClick={handleRemoveValueAction} />
      ) : null}

      {showHandleAddValue ? (
        <AddDot onClick={() => handleAddValue(true)} />
      ) : null}
    </PortalSelectInput>
  );
}

ExploreListInput.propTypes = {
  valueIndex: PropTypes.number.isRequired,
};
