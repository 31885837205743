import Modal from "../../../UI/Modal/Modal";
import AlertMessage from "../../../UI/Alerts/AlertMessage";

export default function PendingModal({ message, setShow }) {
  if (!message) {
    return null;
  }

  return (
    <Modal close={() => null} title="Update notification">
      <AlertMessage outline status="info" message={message} />
      <div></div>
    </Modal>
  );
}
