import { getRidOfAggregation } from "../../../../../../charts/TableView/Elements/EditableMenu";
import Select from "../../../../../../UI/Form/Select/Select";
import { isColumnInUse } from "../../../../utils/tableEditorHelper";
import { getAggregationPrefix } from "../../Column/RegularColumn";

export default function GroupingColumn(props) {
  const {
    chart,
    setChartState,
    isParameterized,
    repeatingOptions,
    column,
    multipleColumns,
    removeOldOverride,
    getOverride,
  } = props;

  const { overrides } = chart;

  function setGroupingColumn(option, oldValue) {
    const name = option.value;
    const override = getOverride(name);
    const prefix = getAggregationPrefix(override.aggregation);

    const keys = multipleColumns.map((key) =>
      key === oldValue ? prefix + name : key
    );

    let newOverrides = [...overrides];

    if (!override.name) {
      newOverrides.push({ name });
    }

    const isOldNameInUse = isColumnInUse(oldValue, {
      ...chart,
      rowGroupKey: keys.join("__"),
    });

    if (!isOldNameInUse) {
      newOverrides = removeOldOverride(newOverrides, oldValue);
    }

    return setChartState({
      ...chart,
      rowGroupKey: keys.join("__"),
      overrides: newOverrides,
    });
  }

  return (
    <div style={{ width: isParameterized ? "100%" : "65%" }}>
      <Select
        options={repeatingOptions}
        simpleValue={getRidOfAggregation(column, overrides)}
        label="Grouping column (Rows)"
        onChange={(option) => setGroupingColumn(option, column)}
      />
    </div>
  );
}
