import { useCallback, useEffect, useState } from "react";
import DatePickerContainer from "../../../UI/DatePicker/DatePickerContainer";
import { getPastYearsToNextDaysDateRange } from "../../../utils/formatters/dateFormatter";
import useDebounceChange from "../../../hooks/useDebounceChange";
import Input from "../../../UI/Form/Input/Input";

export default function FutureDaysAndEarlier({ recentMonthsChange, domain }) {
  const [days, setDays] = useState(0);
  const [validationError, setValidationError] = useState(false);

  const { debounce } = useDebounceChange();

  const onDaysChange = useCallback(
    (days) => {
      setDays(days);

      if (!days || !Number.isInteger(+days) || +days < 0) {
        return setValidationError("This value must be an positive integer.");
      }

      const { start, end } = getPastYearsToNextDaysDateRange(days, 3);
      debounce(() => recentMonthsChange({ from: start, to: end }), 1000);
      setValidationError(false);
    },
    [debounce, recentMonthsChange]
  );

  useEffect(() => {
    if (!days) {
      const defaultDays = defaultDaysQuantityByDomain[domain] ?? 1;
      onDaysChange(defaultDays);
    }
  }, [recentMonthsChange, days, onDaysChange, domain]);

  return (
    <DatePickerContainer>
      <Input
        onChange={(e) => onDaysChange(e.target.value)}
        value={days}
        invalid={validationError}
        errorMessage={validationError}
        data-cy="future-days-input"
      />
    </DatePickerContainer>
  );
}

const defaultDaysQuantityByDomain = {
  clife: 20,
};
