import React, { useEffect, useState, useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../store/actions/index";
import ActiveTable from "./ActiveTable";
import ActiveTableEntryScreen from "./ActiveTableCreation/ActiveTableEntryScreen";
import Spinner from "../../UI/Spinner/Spinner";
import { useParams } from "react-router";
import ActiveTableNewTableForm from "./ActiveTableCreation/ActiveTableNewTableForm";
import { getCheckedFilterValues } from "../../store/actions/dashboard/dashboard";
import { getMenuFiltersFromStateByVisualizationUuid } from "../../store/actions/queryBuilder/queryBuilder";
import PendingModal from "./components/PendingModal";
import {
  useLaravelEchoPrivateChannel,
  useLaravelEchoListen,
} from "../../hooks/useLaravelEcho";

export default function ActiveTableVisualization(props) {
  const visualizationUuid = props.chart.uuid;
  const activeTable = useSelector((state) => state.activeTable);

  const {
    errorMessage,
    activeTableDataLoading,
    activeTableUpdateLoading,
    tables,
    currentTable,
    data,
    queryFields,
    tableToJoinToColumns,
    dataError,
    dataErrorDetail,
  } = activeTable;

  const {
    role,
    groups: currentUserGroups,
    uuid,
  } = useSelector((state) => state.auth);
  const { groups } = useSelector((state) => state.userManagement);
  const [isProcessing, setIsProcessing] = useState(false);
  const activeTableUuid = props.chart.activeTableUuid;
  const error = errorMessage;

  const { tabs } = useSelector((state) => state.layout);
  const menuFilters = useSelector((state) =>
    getMenuFiltersFromStateByVisualizationUuid(state, visualizationUuid)
  );
  const checkedMenuFilters = getCheckedFilterValues(menuFilters);

  const { dashboardId, pageUuid } = useParams();
  const user = { role, groups: currentUserGroups, uuid };

  const [newTableName, setNewTableName] = useState("");
  const [createType, setCreateType] = useState(null);
  const [rescanPendingMessage, setRescanPendingMessage] = useState("");
  const [forceUpdateKey, setForceUpdateKey] = useState(Math.random());

  const dispatch = useDispatch();
  const queryId = props.chart.queryId;

  const onSettingsChange = useCallback(() => {
    setForceUpdateKey(Math.random());
  }, []);
  const laravelEchoChannel = useLaravelEchoPrivateChannel(
    `active-table.${activeTableUuid}`
  );
  useLaravelEchoListen(
    laravelEchoChannel,
    "ActiveTableUpdated",
    onSettingsChange
  );

  // When switching tabs, we need to clear all table data to prevent previously rendered data from being displayed.
  useEffect(() => {
    dispatch(actions.loadConnections());
  }, [dispatch]);

  useEffect(() => {
    if (forceUpdateKey) {
      // Just for adding this to deps.
    }
    if (activeTableUuid) {
      dispatch(actions.getActiveTable(activeTableUuid, queryId)); // Dispatching these separately is causing a race condition
      setIsProcessing(false);
    } else if (error) {
      setIsProcessing(false);
    }
    return () => {
      dispatch(actions.clearActiveTable());
    };
  }, [dispatch, activeTableUuid, queryId, error, forceUpdateKey]);

  function reloadData(viewUuid, view) {
    const currentView =
      view ?? currentTable.views.find((v) => v.uuid === viewUuid);

    dispatch(
      actions.loadActiveTableData(
        props.chart.queryId,
        currentView,
        menuFilters,
        props.chart
      )
    );
  }

  function handleSubmit(joinedMode) {
    setIsProcessing(true);
    const { slug } = tabs.find((tab) => tab.uuid === pageUuid) ?? {};
    // for json chart editor we need to get slug and for dashbord dashboardId
    const pageSlug = dashboardId ?? slug;

    dispatch(
      actions.createNewActiveTable(
        newTableName,
        visualizationUuid,
        pageSlug,
        createType,
        joinedMode
      )
    );
  }

  function handleCreateRow(payload, key) {
    dispatch(
      // key is uuid of row or uniqueUuid which manually set in reducer
      // we cant use joinId column name because its nt unique
      actions.createRow(activeTableUuid, payload, currentTable.columns, key)
    );
  }

  function handleUpdateRow(payload, changedFieldName) {
    dispatch(
      actions.updateRow(
        activeTableUuid,
        payload,
        currentTable.columns,
        changedFieldName
      )
    );
  }

  function handleUpdateSettings(config) {
    dispatch(actions.updateActiveTableConfig(config));
  }

  function updateColumnConfig(config) {
    dispatch(
      actions.updateActiveTableColumn(
        props.chart.queryId,
        config,
        checkedMenuFilters,
        setRescanPendingMessage
      )
    );
  }

  function closeMessage() {
    dispatch({ type: "CLEAR_ACTIVE_TABLE_DATA_MESSAGE" });
  }

  function saveView(nextSettings) {
    dispatch(
      actions.saveActiveTableView(
        activeTableUuid,
        nextSettings,
        currentTable?.views ?? [],
        reloadData // reload table when filter in settings changed
      )
    );
  }

  const activeTableReady =
    activeTableUuid && currentTable?.uuid === activeTableUuid;

  // If current Active Table table exists
  if (activeTableReady) {
    return (
      <div>
        <ActiveTable
          config={currentTable}
          data={data}
          queryFields={queryFields}
          tableToJoinToColumns={tableToJoinToColumns}
          createRow={handleCreateRow}
          updateRow={handleUpdateRow}
          updateSettings={handleUpdateSettings} // all settings
          updateColumnConfig={updateColumnConfig} // column only
          user={user}
          accessGroups={groups}
          dataError={dataError}
          dataErrorDetail={dataErrorDetail}
          closeMessage={closeMessage}
          views={currentTable?.views}
          queryId={props.chart.queryId}
          saveView={saveView}
          reloadData={reloadData}
          checkedMenuFilters={checkedMenuFilters}
          activeTableDataLoading={activeTableDataLoading}
          activeTableUpdateLoading={activeTableUpdateLoading}
          forceUpdateKey={forceUpdateKey}
          activeTableWebsocketChannel={laravelEchoChannel}
        />

        <PendingModal message={rescanPendingMessage} />
      </div>
    );
  } else if (props.chart.activeTableUuid) {
    return <Spinner />;
  }

  if (!createType) {
    return <ActiveTableEntryScreen setCreateType={setCreateType} />;
  }

  return (
    <ActiveTableNewTableForm
      activeTable={activeTable}
      handleSubmit={handleSubmit}
      newTableName={newTableName}
      setNewTableName={setNewTableName}
      existing={createType === "existing"}
      isProcessing={isProcessing}
      activeTables={tables}
    />
  );
}
