import React from "react";
import TableMainCellLink from "../TableMainCellLink";
import styled from "@emotion/styled";
import { lightenDarkenColor } from "../../../styles/colorConvertor";
import ProgressHorizontal from "./ProgressHorizontal";
import {
  getCellBackground,
  getEnumColors,
  getPinnedRows,
} from "../functions/tableMapper";
import TableKeySwatch from "./TableKeySwatch";
import ExpandRowIcon from "./ExpandRowIcon";
import { scaleLinear } from "d3-scale";
import { interpolateRdYlGn } from "d3-scale-chromatic";
import ZoomInDrilldown from "./ZoomInDrilldown";
import UnselectFilterIcon from "./UnselectFilterIcon";
import DrilldownIcon from "./DrilldownIcon";
import TableTooltip from "./TableTooltip";
import TippyTooltipWrapper from "../../Tooltip/TippyTooltipWrapper";

function getNumber(value) {
  if (typeof value === "string") {
    return +value.replace("%", "").replace("$", "");
  }

  return value;
}

function getColor(theme, color, coloredValues) {
  if (color === "neutral") {
    return "#7703E3";
  }

  const isPositive = coloredValues.reverse
    ? getNumber(color) < coloredValues.positive
    : getNumber(color) > coloredValues.positive;
  const isNegative = coloredValues.reverse
    ? getNumber(color) > coloredValues.negative
    : getNumber(color) < coloredValues.negative;

  if (isPositive) {
    return theme.notification.successMain;
  }

  if (isNegative) {
    return theme.notification.errorMain;
  }

  return null;
}

function getBackground(theme, isRowExpanded, expandedRowBackground) {
  if (isRowExpanded) {
    return expandedRowBackground ?? theme.primary;
  }

  return theme.stickyBackground;
}

const Td = styled.td(
  ({
    color,
    background,
    theme,
    freeze,
    freezeWidth,
    maxWidth,
    isRowExpanded,
    cellBackground,
    borderRight,
    isBold,
    initialColumnAlign,
    groupingKey,
    rowData,
    expandedRowBackground,
    freezeNextColumn,
    apiStyles,
    hideColumnZebraBackground,
    showTooltip,
  }) => `
    position: relative;
    ${showTooltip ? null : `box-sizing: border-box`};
    background: ${
      background ? `rgba(0,0,0,${theme.type === "dark" ? 0.15 : 0.05})` : null
    };
    background: ${cellBackground};
    border-right: ${
      borderRight ? `4px solid ${theme.divider} !important` : "none"
    };
    font-weight: ${isBold || color === "neutral" ? 700 : "inherit"};
    ${
      freeze
        ? `
        position: sticky;
        left: ${freezeNextColumn ? freezeWidth : 0}px;
        width: ${freezeWidth}px;
        ${
          !rowData &&
          `background: ${
            hideColumnZebraBackground
              ? theme.background.primary
              : getBackground(theme, isRowExpanded, expandedRowBackground)
          };`
        };
        z-index: 2;
        background: ${theme.freezeBackground};
    `
        : null
    };
    ${
      isRowExpanded
        ? `
        width: ${groupingKey ? 0 : freezeWidth}px;
      `
        : null
    };
    a {
        color: ${lightenDarkenColor(theme.primary, 60)};
    }
    text-align: ${initialColumnAlign} !important;
    ${
      maxWidth &&
      `
        min-width: ${maxWidth}px;
        max-width: ${maxWidth}px;
        white-space: normal !important;
      `
    };
    ${apiStyles} !important;
`
);

export default React.memo(function TableTd(props) {
  const {
    freezeLeft,
    freezeWidth,
    hasZebraBackground,
    cellValueObject,
    emptyFreezeLeft,
    color,
    theme,
    dataRow,
    linkColumns,
    cellKey,
    i,
    maxColumnsWidth = {},
    hasRowExpand,
    rowIndexes,
    index,
    coloredCellFields = [],
    progressCellFields = [],
    pinnedRows,
    borderRight,
    boldColumns,
    badDebt,
    enumColorsSettings = [],
    colors,
    tableRowIndex,
    initialColumnAlign,
    groupingKey,
    groupingIndex,
    dynamicShadeBackgrounds = [],
    dynamicShadeBackgroundsBaseZero = [],
    progressBarTotals,
    progressBarsSettings,
    coloredEdge,
    row,
    zoomInDrilldownConfig,
    rowData,
    setRowData,
    isRowExpanded,
    freezeNextColumn,
    noTotalCalcKeys,
    rowMainKey,
    cellBackgroundSchema,
    tooltipConfig,
    meta,
    horizontalTotals,
    hideColumnZebraBackground,
    textAlignOverride,
  } = props;

  const cellBackground = getCellBackground(
    coloredCellFields,
    cellKey,
    cellValueObject?.value,
    theme
  );

  function setColor() {
    return getColor(theme, color, coloredEdge);
  }

  const enumColorStyles = getEnumColors({
    enumColorsSettings,
    cellKey,
    cellValueObject,
    row,
    groupingIndex,
  });

  const colorRes = setColor();

  function getTextAlign() {
    return (textAlignOverride ?? {})[cellKey] ?? (cellValueObject || {}).align;
  }

  // Color is coming from here always now
  const styles = {
    color: colorRes,
    textAlign: getTextAlign(),
    ...enumColorStyles,
  };

  const isDynamicShade = dynamicShadeBackgrounds.find((d) => d === cellKey);
  if (isDynamicShade) {
    const isBaseZero = dynamicShadeBackgroundsBaseZero.find(
      (d) => d === cellKey
    );

    // tweaked zero to make numbers line up a little more
    const lowEnd = isBaseZero ? -0.3 : -1;
    const scale = scaleLinear().domain([lowEnd, 1]).range([0, 1]);
    const output = scale(cellValueObject?.value);
    styles.background = interpolateRdYlGn(output);
    if (output < 0.7 && output > 0.3) {
      styles.color = "black";
    } else if (theme.type !== "dark") {
      // for light theme default color is black and it not looks good
      // on dynamic shades
      styles.color = "white";
    }
  }

  // cellBackgroundSchema utilizes the D3 color interpolation function
  // to create a gradient background determined by the cell's value.
  const {
    columns = [],
    valueDomain = [],
    colorRange = [],
  } = cellBackgroundSchema ?? {};

  // Check if the current cellKey is included in the specified columns
  const inBackgroundSchema = columns.includes(cellKey);

  // Apply background styling if the cellKey is in the specified columns
  if (inBackgroundSchema) {
    // Create a linear scale based on the specified valueDomain and colorRange
    const scale = scaleLinear().domain(valueDomain).range(colorRange);

    // Apply background color based on the scaled value of cellValueObject's value
    styles.background = scale(cellValueObject?.value);

    // Set text color to white for better visibility on the colored background
    styles.color = "white";
  }

  // makes horizontal totals same background and color like regular totals calculated on FE
  if (horizontalTotals?.header === cellKey) {
    styles.background = "black";
    styles.color = "white";
    styles.fontWeight = "bold";
  }

  const setPinIcon = () => {
    const pinnedRow = getPinnedRows(dataRow, pinnedRows);

    if (!pinnedRow || pinnedRow.hideIcon) {
      return false;
    }

    if (pinnedRow.label) {
      return dataRow[cellKey] === pinnedRow.label;
    }

    return dataRow[cellKey] === pinnedRow.value;
  };

  const getFreezeParameter = (index) => {
    if (!freezeLeft) {
      return false;
    }

    const indexes = [0];
    if (freezeNextColumn) {
      indexes.push(1);
    }

    return indexes.includes(index);
  };

  const showTooltip = tooltipConfig?.showOnColumn === cellKey;

  const tdElement = (
    <Td
      freeze={getFreezeParameter(i)}
      freezeWidth={freezeWidth}
      key={cellKey + "-" + i}
      color={color || null}
      background={hasZebraBackground}
      maxWidth={maxColumnsWidth[cellKey] ?? null}
      style={styles}
      isRowExpanded={isRowExpanded && i === 0}
      cellBackground={cellBackground}
      borderRight={borderRight}
      isBold={boldColumns?.includes(cellKey)}
      initialColumnAlign={i === 0 ? initialColumnAlign : null}
      groupingKey={groupingKey}
      coloredEdge={coloredEdge}
      rowData={rowData}
      freezeNextColumn={freezeNextColumn && i === 1}
      apiStyles={i === 0 ? dataRow.style : null}
      hideColumnZebraBackground={hideColumnZebraBackground}
      showTooltip={showTooltip}
    >
      <UnselectFilterIcon
        index={i}
        value={cellValueObject?.formatted}
        updatePreview={props.updatePreview}
      />
      <ExpandRowIcon
        i={i}
        index={index}
        rowIndexes={rowIndexes}
        hasRowExpand={!groupingKey && hasRowExpand}
        tableRowIndex={tableRowIndex}
      />

      {colors && i === 0 ? (
        <TableKeySwatch background={colors[tableRowIndex - 1]} />
      ) : null}

      {!!(
        progressCellFields.includes(cellKey) &&
        !cellValueObject.isProgressBarHidden
      ) && (
        <ProgressHorizontal
          width={cellValueObject?.formatted}
          reverseColor={color}
          badDebt={badDebt}
          progressBarTotals={progressBarTotals}
          cellKey={cellKey}
          progressBarsSettings={progressBarsSettings}
        />
      )}

      {emptyFreezeLeft && i === 0 ? null : (
        <TableMainCellLink
          dataRow={dataRow}
          value={cellValueObject?.formatted}
          rawValue={cellValueObject?.value}
          linkColumns={linkColumns}
          cellKey={cellKey}
          pinIcon={setPinIcon()}
          noTotalCalcKeys={noTotalCalcKeys}
          rowMainKey={rowMainKey}
        />
      )}

      <DrilldownIcon enumColorStyles={enumColorStyles} />

      {/* for groupingKey we have separate drilldown icon and zoom */}
      {zoomInDrilldownConfig && !groupingKey && (
        <ZoomInDrilldown
          row={row}
          i={i}
          setRowData={setRowData}
          attachedURLParameter={zoomInDrilldownConfig.attachedURLParameter}
        />
      )}
    </Td>
  );

  if (showTooltip) {
    return (
      <TippyTooltipWrapper
        content={
          <TableTooltip
            row={(row.values ?? [])[0]}
            tooltipConfig={tooltipConfig}
            meta={meta}
          />
        }
        freeze={getFreezeParameter(i)}
      >
        {tdElement}
      </TippyTooltipWrapper>
    );
  }

  return tdElement;
});
