import { useState, useEffect, useCallback } from "react";

export default function useResetDateOptions() {
  const [timeoutId, setTimeoutId] = useState(0);
  const [needRefresh, setNeedRefresh] = useState(false);
  const [clickListener, setClickListener] = useState(null);

  const scheduleRefresh = useCallback(() => {
    const now = new Date();
    const tomorrow = new Date();

    // Set the time we want to auto refresh the page
    tomorrow.setDate(now.getDate() + 1);
    tomorrow.setHours(0, 1, 0, 0); // 12:01:00.000 AM
    // Calculate the difference in milliseconds
    const timeUntilTomorrow = tomorrow.getTime() - now.getTime();
    // Set a timeout to refresh the page
    const timeoutId = setTimeout(() => {
      setNeedRefresh(true);

      // Also re-schedule the refresh whenever the user interacts with the page
      function listener() {
        setNeedRefresh(false);
        scheduleRefresh();
      }

      window.addEventListener("click", listener, { once: true });
      setClickListener(listener);
    }, timeUntilTomorrow);
    setTimeoutId(timeoutId);
  }, []);

  useEffect(() => {
    // Schedule the first refresh when the page loads
    scheduleRefresh();
  }, [scheduleRefresh]);

  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);

  useEffect(() => {
    return () => {
      if (clickListener) {
        window.removeEventListener("click", clickListener);
      }
    };
  }, [clickListener]);

  return {
    needRefresh,
  };
}
