import isEqual from "react-fast-compare";
import { validPipe as validate } from "../../../../../utils/func";

export default (
  settings,
  prevSettings,
  selectedQuery,
  filters = [],
  prevFilters
) => {
  return validate(
    hasQuerySelected(selectedQuery),
    isInitialized(prevSettings),
    hasChangedSettings(prevSettings, settings, prevFilters, filters),
    hasIncompleteFilter(filters, prevFilters),
    hasIncompleteRange(filters)
  );
};

function hasQuerySelected(selectedQuery) {
  return !!selectedQuery;
}

function isInitialized(prevSettings) {
  return !!prevSettings?.filters;
}

function hasChangedSettings(settings, prevSettings, prevFilters, filters) {
  return !isEqual(settings, prevSettings) || !isEqual(prevFilters, filters);
}

function hasIncompleteFilter(filters, prevFilters) {
  if (!filters) return true;
  if (prevFilters && hasResetName(filters, prevFilters)) return true;

  return !filters.find((f) => {
    if (f.subQueryValues) {
      return !f.name;
    }

    return (
      !f.name || !f.operator || !f.values?.length || f.values[0] === undefined
    );
  });
}

function hasResetName(filters, prevFilters) {
  const nameChanged = filters
    .map((fil) => fil.name)
    .find((fil) => prevFilters.find((p) => p.name && p.name !== fil));
  if (nameChanged) return true;
}

function hasIncompleteRange(filters) {
  if (!filters) return true;
  return !filters
    .filter((f) => f.operator === "range")
    .find((f) => !f.values[0] || !f.values[1]);
}
