import styled from "@emotion/styled";
import Flex from "../../../../../../UI/Flex/Flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  findFieldInOverrides,
  isColumnInUse,
} from "../../../../utils/tableEditorHelper";
import { getRidOfAggregation } from "../../../../../../charts/TableView/Elements/EditableMenu";
import IOButton from "../../../../../../UI/Form/Button/IOButton";
import GroupingColumn from "./GroupingColumn";
import GroupingAggregation from "./GroupingAggregation";
import GroupingFormat from "./GroupingFormat";

const Container = styled.div(
  ({ theme }) => `
  background: ${theme.divider};
  padding: 10px 5px;
  border-radius: 5px;
  position: relative;
  margin-bottom: 20px;

  & > svg {
    font-size: 22px;
    position: absolute;
    cursor: pointer;
    color: ${theme.notification.errorMain};
    right: 10px;
    bottom: 18px;
  }
`
);

export default function MultipleRowGroupKeys(props) {
  const { repeatingOptions, chart, setChartState, isParameterized } = props;
  const { rowGroupKey, overrides } = chart;
  const multipleColumns = (rowGroupKey ?? "").split("__");

  function getOverride(name) {
    return (
      findFieldInOverrides(overrides, getRidOfAggregation(name, overrides)) ??
      {}
    );
  }

  // Helper function to remove old column override from the list
  function removeOldOverride(overrides, oldValue) {
    return overrides.filter((override) => override.name !== oldValue) ?? {};
  }

  // Helper function to update chart state with the new column added
  function updateChartWithNewColumn(name) {
    setChartState({
      ...chart,
      rowGroupKey: [...multipleColumns, name].join("__"),
    });
  }

  function removeColumn(column) {
    const keys = multipleColumns.filter((key) => key !== column);

    const isNameInUse = isColumnInUse(column, {
      ...chart,
      rowGroupKey: keys.join("__"),
    });

    const nonAggregatedName = getRidOfAggregation(column, overrides);

    return setChartState({
      ...chart,
      rowGroupKey: keys.join("__"),
      ...(!isNameInUse && {
        overrides: removeOldOverride(overrides, nonAggregatedName),
      }),
    });
  }

  const innerProps = {
    chart,
    setChartState,
    isParameterized,
    repeatingOptions,
    multipleColumns,
    removeOldOverride,
    getOverride,
    updateChartWithNewColumn,
  };

  return (
    <>
      {multipleColumns.map((column, index) => (
        <Container key={column + index}>
          <Flex justifyContent="space-between">
            <GroupingColumn {...innerProps} column={column} />
            <GroupingAggregation {...innerProps} column={column} />
          </Flex>

          <br />

          <GroupingFormat {...innerProps} column={column} />

          <FontAwesomeIcon
            icon={["far", "times-circle"]}
            title="Remove current row"
            onClick={() => removeColumn(column)}
          />
        </Container>
      ))}

      <IOButton onClick={() => updateChartWithNewColumn("")} add circle>
        Add more
      </IOButton>

      <br />
    </>
  );
}
