import React, { useEffect, useState, useCallback, useRef } from "react";

import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css";

import ActiveTableNavigationShelf from "./ActiveTableNavigationShelf";
import useActiveTableSettings from "./useActiveTableSettings";
import ActiveTableSettings from "./ActiveTableSettings/ActiveTableSettings";
import isEqual from "react-fast-compare";
import ActiveGridDisplay from "./Grid/ActiveGridDisplay";
import useToggleArray from "../../hooks/useToggleArray";
import { unique } from "../../utils/func";
import DataError from "./DataError";
import useViewSelector from "./ActiveTableViews/useViewSelector";
import usePrevious from "../../utils/usePrevious";
import { omit } from "lodash-es";
import { reportUpdatedRowViaWebsocket } from "../../store/actions/activeTable";
import { useLaravelEchoListen } from "../../hooks/useLaravelEcho";
import { useDispatch, useStore } from "react-redux";
import { handleError } from "../../utils/errorHandling";

export default function ActiveTable(props) {
  const {
    reloadData,
    checkedMenuFilters,
    queryFields,
    tableToJoinToColumns,
    activeTableDataLoading,
    activeTableUpdateLoading,
    activeTableWebsocketChannel,
  } = props;

  const dispatch = useDispatch();
  const { getState } = useStore();

  const [firstLoad, setFirstLoad] = useState(true);

  const availableViews = (props.views ?? []).filter((view) => {
    const { displaySettings } = view ?? {};

    if (!displaySettings.viewVisibilityUserUuid) {
      return true;
    }

    return props?.user?.uuid === displaySettings.viewVisibilityUserUuid;
  });

  const { selectedView, setSelectedView } = useViewSelector({
    views: availableViews,
  });

  // do not consider undefined
  const prevFilters = usePrevious(checkedMenuFilters) ?? [];
  const prevViewUuid = usePrevious(selectedView) ?? null;

  const viewUpdated = !isEqual(prevViewUuid, selectedView);
  const filtersUpdated = !isEqual(prevFilters, checkedMenuFilters);

  // for case when new created table has no any view
  const noViewsAvalable = availableViews.length === 0 && firstLoad;

  // query exec data loading
  useEffect(() => {
    if (viewUpdated || filtersUpdated || noViewsAvalable) {
      reloadData(selectedView);
      setFirstLoad(false);
    }
  }, [selectedView, viewUpdated, filtersUpdated, reloadData, noViewsAvalable]);

  const [settingsMode, setSettingsMode] = useState(false);
  const [collapsed, toggleCollapsed, emptyCollapsed, replaceCollapsed] =
    useToggleArray();
  const adminMode = props.user && props.user.role === "tenant_owner";

  // Row Data: The data to be displayed.
  const [rowData, setRowData] = useState(props.data || []);
  const [splitBy, setSplitBy] = useState(null);
  const [stringMatch, setStringMatch] = useState("");

  const {
    nextConfig,
    columns,
    updateColumn,
    setColumns,
    setAllLocked,
    defaultRow,
    addColumn,
    setOptions,
    setAccessGroups,
    isDirty,
    updateConfig,
    prevConfig,
    validateFieldName,
    convertToApiColumns,
    isInvalid,
  } = useActiveTableSettings(
    props.config,
    props.user,
    queryFields,
    tableToJoinToColumns,
    handleChange
  );

  useEffect(() => {
    setRowData(props.data ?? []);
  }, [props.data]);

  useEffect(() => {
    updateConfig(props.config);
  }, [props.config, updateConfig]);

  function handleChange(event) {
    setRowData((prevRowData) => {
      // row uuid or joinId identifier
      const key = "uniqueRowUuid";

      // editing row
      const row = prevRowData.find((row) => row[key] === event.data[key]);

      if (row) {
        // column name
        const name = event.colDef.field;
        // updated cell value; for some reaason ag grid return empty cell value as null
        // but api is not accept null values so we can change them by empty string
        // if user cleaar the cell its mean empty string
        const value = event.value ?? "";
        // updated row
        const updatedRow = { ...row, [name]: value };

        // if uuid is not exist then its mean we adding row or updating view column data from joined mode
        if (!event.data.uuid) {
          props.createRow(updatedRow, key);
        } else {
          props.updateRow(updatedRow, name);
        }

        if (typeof event.value !== "object" || event.value === null) {
          return prevRowData.map((item) =>
            item[key] === updatedRow[key] ? updatedRow : item
          );
        }
      }

      return prevRowData;
    });
  }

  function handleSplit(value) {
    setSplitBy(value);
  }

  function handleAdd() {
    // setAddedRow(defaultRow); // store this just to keep track of new row (we might want multiples)
    setRowData((d) => [defaultRow, ...d]);
  }

  const activeView = availableViews.find((v) => v.uuid === selectedView);
  const noViews = !availableViews.length;
  const visibleFields = activeView?.visibleFields ?? [];

  // split by from view settings
  const viewSplitBy = activeView?.displaySettings?.splitBy;
  const viewSplitByOption = viewSplitBy
    ? { value: viewSplitBy, label: viewSplitBy }
    : null;

  function updateSettings() {
    props.updateSettings({ ...nextConfig, activeView });
  }

  const handleUpdateColumnConfig = (
    colId,
    nextValue,
    updateMode,
    index,
    nextColumnConfig
  ) => {
    const nextColumns = updateColumn(index, nextColumnConfig);
    const updatedNextConfig = {
      ...nextConfig,
      columns: nextColumns,
      activeView,
    };

    if (colId === "temp") {
      return props.updateColumnConfig(updatedNextConfig);
    } else {
      updateExistingColumn();
    }
    function updateExistingColumn() {
      const prevColumn = prevConfig.columns.find((c) => colId === c.uuid);
      const fieldNameChanged =
        updateMode === "name" && !isEqual(prevColumn.name, nextValue);
      const typeChanged =
        updateMode === "type" && !isEqual(prevColumn.type, nextValue);

      if (fieldNameChanged || typeChanged) {
        props.updateColumnConfig(updatedNextConfig);
      }
    }
  };

  function performDeleteColumn(colId) {
    const nextColumns = columns.filter((col) => col.colId !== colId);
    setColumns(nextColumns);
    props.updateColumnConfig({
      ...nextConfig,
      columns: convertToApiColumns(nextColumns),
      activeView,
    });
  }

  function handleCollapse(mode) {
    if (mode === "expand") {
      emptyCollapsed();
    } else {
      const splitKeys = unique(rowData.map((r) => r[splitBy.value])).map(
        (key, index) => key + "-" + index // to collapse / expand nullable values
      );
      replaceCollapsed(splitKeys);
    }
  }

  const gridReadyColumns = columns.map((c) =>
    omit(c, ["displayName", "joinedOverride", "queryOnly", "displaySettings"])
  );

  const viewFilteredColumns = visibleFields
    .map((visibleField) =>
      gridReadyColumns.find((column) => column.colId === visibleField.colId)
    )
    .filter(Boolean); // Temp fix only, this should sync the view removal when deleting a field

  const inJoinedMode = (function hasJoinedMode() {
    return (
      nextConfig.joinedMode &&
      Object.values(nextConfig.joinedMode).every(Boolean)
    );
  })();

  function getVisibleColumn(name) {
    // if no views we should show all columns in the table so also should show splitBy
    if (!availableViews?.length) {
      return true;
    }

    return viewFilteredColumns.some((col) => col.field === name);
  }

  function getSplitByDefault() {
    return getVisibleColumn(viewSplitBy) ? viewSplitByOption : null;
  }

  const showSplitBy = (function getShowSplitByFlag() {
    if (!rowData.length) {
      return false;
    } else if (!availableViews.length) {
      return true;
    }

    return !!viewFilteredColumns.length;
  })();

  const splitByOption = splitBy ?? getSplitByDefault();
  const abortControllersRef = useRef(new Set());
  useEffect(() => {
    const abortControllers = abortControllersRef.current;
    return () => {
      for (const abortController of abortControllers) {
        abortController.abort();
      }
    };
  }, []);

  const onRowChange = useCallback(
    (row) => {
      const abortController = new AbortController();
      abortControllersRef.current.add(abortController);
      dispatch(
        reportUpdatedRowViaWebsocket(
          row.uuid,
          getState,
          props.queryId,
          selectedView,
          checkedMenuFilters,
          abortController.signal
        )
      ).catch((e) => {
        handleError(dispatch, e, { showToast: false });
      });
    },
    [checkedMenuFilters, dispatch, getState, props.queryId, selectedView]
  );
  useLaravelEchoListen(
    activeTableWebsocketChannel,
    "ActiveTableRowUpdated",
    onRowChange
  );

  return (
    <div data-cy="active-table-container">
      {settingsMode ? (
        <ActiveTableSettings
          setSettingsMode={setSettingsMode}
          columns={columns}
          setColumns={setColumns}
          updateColumn={updateColumn}
          updateColumnConfig={handleUpdateColumnConfig}
          updateSettings={updateSettings}
          performDeleteColumn={performDeleteColumn}
          addColumn={addColumn}
          setOptions={setOptions}
          accessGroups={props.accessGroups}
          setAccessGroups={setAccessGroups}
          setAllLocked={setAllLocked}
          isDirty={isDirty}
          data={rowData}
          validateFieldName={validateFieldName}
          isInvalid={isInvalid}
          activeTableUpdateLoading={activeTableUpdateLoading}
        />
      ) : (
        <div>
          <ActiveTableNavigationShelf
            handleAdd={handleAdd} // Add a row
            openSettings={() => setSettingsMode(true)}
            adminMode={adminMode}
            handleSplit={handleSplit}
            splitBy={splitByOption}
            columns={columns}
            handleCollapse={handleCollapse}
            setStringMatch={setStringMatch}
            views={availableViews}
            selectedView={selectedView}
            setSelectedView={setSelectedView}
            queryId={props.queryId}
            handleSaveView={props.saveView}
            user={props.user}
            stringMatch={stringMatch}
            inJoinedMode={inJoinedMode}
            showSplitBy={showSplitBy}
            getVisibleColumn={getVisibleColumn}
          />
        </div>
      )}
      <DataError
        dataError={props.dataError}
        dataErrorDetail={props.dataErrorDetail}
        closeMessage={props.closeMessage}
      />
      <ActiveGridDisplay
        rowData={rowData}
        columns={noViews ? gridReadyColumns : viewFilteredColumns}
        handleChange={handleChange} // Update row
        splitBy={splitByOption}
        collapsed={collapsed}
        toggleCollapsed={toggleCollapsed}
        stringMatch={stringMatch}
        activeTableDataLoading={activeTableDataLoading}
      />
    </div>
  );
}
