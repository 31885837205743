import { getConditionalFilters } from "./getConditionalFilters";
import {
  setFilterString,
  addFilterValue,
  setParameterizedStringWithValue,
  setWatchlistFilters,
  assembleFilterStringsFromArray,
  renumberFiltersQueryString,
  setSubQueryString,
} from "./queryBuilder";
import { pick, isEmpty } from "lodash-es";
import { stringifyMinimal } from "../../../utils/browser";
import { FILTER_TYPE_DATE_PRESETS } from "../../../utils/constants/constants";
import { xDaysFuncs } from "../dataSettings/dataSettings";

const esc = encodeURIComponent;

export default function (
  query,
  menuFilters,
  activeTab,
  paramValue,
  comparisonMode,
  term
) {
  const {
    isParameterized,
    parameterizedFilterPrefix,
    filters,
    havings = [],
    globalFilterOverride,
    ignoreMenuFilters,
    mainFilterOverride,
    parameterizedDefaults,
    filterFromTo,
    ignoreDateTerm,
    watchlistFilters,
    menuFilterOverrides,
    inGlobalOverride,
    dateKeys,
  } = query;
  const filtersArray = [];

  const activeGf = menuFilters
    .filter(ignoreHiddenFilters)
    .filter(respectWhitelistedFilters)
    .filter(removeBooleanCheckboxFilter)
    .map(convertDatePresetsToDateFilters)
    .map(mapValueTypesIfParameterized)
    .map(deDupeFromOverrides);

  const numberFilters = menuFilters
    .filter((mf) => mf.selectedValue)
    .filter(ignoreHiddenFilters)
    .filter(respectWhitelistedFilters)
    .map((mf) => [
      {
        checked: true,
        value: mf.selectedValue,
        key: mf.name,
        type: mf.name,
      },
    ]);

  function isDatePresets(type) {
    return type === FILTER_TYPE_DATE_PRESETS;
  }

  const gf = [...activeGf, ...numberFilters];

  function convertDatePresetsToDateFilters(item) {
    if (isDatePresets(item.type) && isParameterized) {
      const { start, end } = dateKeys ?? {};

      const [from, to] = item.values;
      return {
        ...item,
        values: [
          { ...from, key: start + item.name, type: start },
          { ...to, key: end + item.name, type: end },
        ],
      };
    }

    return item;
  }

  // we show boolean filter like a checkbox so user can select both values it that case we remove this filter
  function removeBooleanCheckboxFilter(mf) {
    if (mf.type === "checkboxBoolean") {
      return mf.values.filter((v) => v.checked).length === 1;
    }
    return true;
  }

  function ignoreHiddenFilters(mf) {
    if (!activeTab || isEmpty(activeTab)) {
      return false;
    }
    return !mf.hide?.some((uuid) => activeTab?.uuid === uuid);
  }

  function respectWhitelistedFilters(mf) {
    if (!activeTab || isEmpty(activeTab)) {
      return false;
    }

    return mf.show?.length > 0
      ? !!mf.show.some((uuid) => activeTab?.uuid === uuid)
      : true;
  }

  function deDupeFromOverrides(item) {
    return item.values
      .filter((f) => f.checked)
      .filter(removeIfInGlobalOverride)
      .map((f) => ({ ...f, type: globalFilterOverride || f.type }))
      .filter((f) => !ignoreMenuFilters?.includes(f.type));
  }

  function removeIfInGlobalOverride(f) {
    if (!inGlobalOverride) {
      return true;
    }

    return (
      !filters ||
      (filters &&
        !filters.find((qf) => qf.globalFilterOverride || qf.type === f.type))
    );
  }

  function mapValueTypesIfParameterized(mf) {
    return {
      ...mf,
      values: mf.values.map((v) => ({
        ...v,
        type: setTypeIfParameterized(v, mf.type),
      })),
    };
  }

  function setTypeIfParameterized(v, type) {
    if (isDatePresets(type) && isParameterized) {
      return v.key;
    }

    return isParameterized ? (parameterizedFilterPrefix || "") + v.key : v.key;
  }

  // If there are no global filters assigned
  const globalFilters = gf
    .filter((item) => item.length)
    .flat()
    .map(mapMenuFilterOverrides);

  function filterConditional(array) {
    return array.filter((f) => f.conditional);
  }

  function filterNonConditional(array) {
    return array.filter((f) => !f.conditional);
  }

  function mapRestritedValues(field) {
    return {
      ...field,
      value:
        field?.value instanceof String
          ? field.value.replace("$restrict$", paramValue)
          : field?.value,
    };
  }

  const conditionalPart = filterConditional(globalFilters);
  const nonConditionalPart = filterNonConditional(globalFilters);

  const queryFilters = [...conditionalPart, ...(filters || [])];

  const wrapLastXDays = queryFilters.flatMap((curr) => {
    if (xDaysFuncs[curr.operator]) {
      const { start, end } = xDaysFuncs[curr.operator](curr.value);
      return [
        { type: curr.type, value: start },
        { type: curr.type, value: end },
      ];
    }

    return curr;
  });

  function mapMenuFilterOverrides(filter) {
    if (!menuFilterOverrides) return filter;
    const hasOverride = menuFilterOverrides.find(
      (f) => filter.key === f.menuFilter
    );
    return hasOverride
      ? { ...filter, name: hasOverride.dataKey, type: hasOverride.dataKey }
      : filter;
  }

  const [conditionalFilters, commonFilters] = [
    filterConditional(wrapLastXDays),
    filterNonConditional(wrapLastXDays).map(mapRestritedValues),
  ];

  const qFilters = conditionalFilters.length
    ? [...getConditionalFilters(conditionalFilters), ...commonFilters].flat()
    : wrapLastXDays;

  const combinedActiveQueryAndGlobalFilters = mainFilterOverride
    ? [qFilters]
    : [nonConditionalPart, qFilters];

  if (isParameterized) {
    const activeFiltersByKey = assembleFiltersByKey(
      combinedActiveQueryAndGlobalFilters.flat()
    );

    if (parameterizedDefaults) {
      const mappedDefaults = parameterizedDefaults.map((def) => ({
        key: def.key,
        type: def.key,
        value: def.value,
        checked: true,
      }));

      const defaultsWithoutMenuSettings = mappedDefaults.filter(
        (md) => !activeFiltersByKey[md.key]
      );

      defaultsWithoutMenuSettings.forEach((def) => {
        activeFiltersByKey[def.key] = [def.value];
      });
    }

    const dateTerm = ignoreDateTerm ? null : term;

    return setParameterizedStringWithValue(
      activeFiltersByKey,
      filterFromTo,
      menuFilters,
      paramValue,
      comparisonMode,
      dateTerm,
      activeTab?.uuid
    );
  }

  let filtersString = "";
  for (const filters of combinedActiveQueryAndGlobalFilters) {
    filtersString += assembleFilters(filtersArray, filters, filterFromTo);
  }

  filtersString =
    assembleFilterStringsFromArray(filtersArray) +
    renumberFiltersQueryString(filtersString, filtersArray.length) +
    setHavings() +
    setWatchlistFilters(watchlistFilters);

  return filtersString;

  function setHavings() {
    if (!havings.length) {
      return "";
    }

    return `&${stringifyMinimal({
      havings: havings.map((having) =>
        pick(having, ["name", "operator", "values"])
      ),
    })}`;
  }
}

function assembleFiltersByKey(filters, complexFiltersByKey = {}) {
  return filters.reduce((acc, curr) => {
    if (complexFiltersByKey[curr.type]) {
      // Let's handle complex filters separately.
      complexFiltersByKey[curr.type].push(curr);
      return acc;
    }
    const values = acc[curr.type]
      ? [...acc[curr.type], esc(curr.value)]
      : [esc(curr.value)];
    // Remove duplicate operators because the API does not accept an array of operators.
    if (curr.operator && !values.includes(curr.operator)) {
      values.push(curr.operator);
    }

    const filterObj = { ...acc, [curr.type]: values };

    //  need when operators are used on blended query
    if (curr.ds) {
      values.push("ds:" + curr.ds);
    }

    // stackingType need for filters condition (OR, AND)
    if (curr.stackingType) {
      filterObj.stackingType = [curr.stackingType];
    }

    if (curr.aggregated) {
      values.push("aggregated");
    }

    // subQueryValues support
    if (curr.subQueryValues) {
      filterObj[curr.type] = { ...curr.subQueryValues, hasSubQuery: true };
    }

    return filterObj;
  }, {});
}

function assembleFilters(
  filtersArray,
  queryFiltersOrGlobalMenuFilters,
  filterFromTo
) {
  // Filters e.g. ranges, where we'll need an OR filterGroup rather than sending the filter as a simple array of values.
  const complexFiltersByKey = {};

  for (const filter of queryFiltersOrGlobalMenuFilters) {
    if (filter.value && typeof filter.value === "object") {
      complexFiltersByKey[filter.type] = [];
    }
  }

  const activeFiltersByKey = assembleFiltersByKey(
    queryFiltersOrGlobalMenuFilters,
    complexFiltersByKey
  );

  handleComplexFilters();

  return setRegularFilters();

  function getValueStrings(arr) {
    return arr.reduce((a, c) => a + addFilterValue(c, null), "");
  }

  function setRegularFilters() {
    return Object.entries(activeFiltersByKey).reduce((acc, curr) => {
      if (curr[0] === "ds") {
        return acc + getValueStrings(curr[1]);
      }

      // if has subQueryValues then handle payload different
      if (curr[1]?.hasSubQuery) {
        const setSubQuery = setSubQueryString(curr[0], curr[1]);
        return acc + setSubQuery;
      }

      const current =
        filterFromTo?.from === curr[0] ? filterFromTo.to : curr[0];
      const setName = setFilterString(current);

      return acc + setName + getValueStrings(curr[1]);
    }, "");
  }

  // E.g. ranges.
  function handleComplexFilters() {
    const preparedFilters = [];

    for (const filters of Object.values(complexFiltersByKey)) {
      const outerFilters = [];

      for (const filter of filters) {
        const value = filter.value;
        if (value && typeof value === "object") {
          // Handle ranges.
          const innerFilters = [];

          if (value.min != null) {
            innerFilters.push({
              name: filter.type,
              values: [value.min],
              conjunction: filter.conjunction,
              operator: ">=",
            });
          }

          if (value.max != null) {
            innerFilters.push({
              name: filter.type,
              values: [value.max],
              conjunction: filter.conjunction,
              operator: "<=",
            });
          }

          if (innerFilters.length) {
            outerFilters.push({
              groupType: "AND",
              filters: innerFilters,
            });
          }
        } else {
          const outerFilter = {
            name: filter.type,
            values: [value],
          };
          if (filter.operator) {
            outerFilter.operator = filter.operator;
          }
          outerFilters.push(outerFilter);
        }
      }
      const filterObject = {
        groupType: "OR",
        filters: outerFilters,
      };

      preparedFilters.push(filterObject);
    }
    filtersArray.push(...convertFiltersByConjunction(preparedFilters));
  }
}

/**
 * Converts a list of filters by grouping those that contain "OR" conjunctions.
 * If no conjunctions are found, the original filters are returned.
 *
 * @param {Array} filters - The array of filter objects to process.
 * @returns {Array} - The modified array of filters, with "OR" conjunctions processed.
 */
function convertFiltersByConjunction(filters) {
  const conjunctions = filters.filter(hasOrConjunction);
  const regularRanges = filters.filter(hasRegularConjunction);

  if (conjunctions.length === 0) {
    return filters;
  }

  // Combine the filters from conjunctions into a single object
  const converted = conjunctions.reduce(
    (acc, curr) => {
      acc.groupType = curr.groupType;
      acc.filters.push(...curr.filters);
      return acc;
    },
    { filters: [] }
  );

  return [...regularRanges, converted];
}

/**
 * Checks if the given filter item contains any nested filters with "OR" conjunctions.
 *
 * @param {Object} item - The filter object to check.
 * @returns {boolean} - True if any nested filters contain "OR", otherwise false.
 */
function hasOrConjunction(item) {
  return item.filters.some((nestedItem) =>
    nestedItem.filters.some((subItem) => subItem.conjunction === "OR")
  );
}

/**
 * Checks if the given filter item contains any nested filters with conjunctions other than "OR".
 *
 * @param {Object} item - The filter object to check.
 * @returns {boolean} - True if any nested filters contain conjunctions other than "OR", otherwise false.
 */
function hasRegularConjunction(item) {
  return item.filters.some((nestedItem) =>
    nestedItem.filters.some((subItem) => subItem.conjunction !== "OR")
  );
}
