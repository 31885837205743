import GroupedBar from "./GroupedBar/GroupedBar";
import {
  groupByKey,
  pipe,
  assignAsKey,
  assignAsKeys,
  unique,
  mapToKey,
  doIf,
  sortByValues,
  makePercent,
  convertNumbersToBoolean,
  getWithOrOutNegatives,
  getDynamicData,
  filterOutNullValues,
} from "../utils/func";
import { scaleOrdinal } from "d3-scale";
import { schemeTableau10 } from "d3-scale-chromatic";
import Legend from "./Legend/Legend";
import VisualizationBase from "./BaseChart/VisualizationBase";
import Details from "../Layout/Details/Details";
import xKeyParser from "../utils/charts/xKeyParser";
import { sortByKey } from "../utils/dates/dateSorting";
import { reportDeprecated } from "../utils/errorHandling";

const applyFormula = (keys) => (arr) => {
  return arr.map((row) => {
    const k0 = keys[0];
    const k1 = keys[1];
    const value = row[k0] / row[k1];
    return { ...row, value };
  });
};

export default function MultiGroupedBarVisualization(props) {
  const { chart, details, dashboardId, term } = props;
  const {
    data,
    yKey,
    yKeys,
    xKey,
    groupBy: _groupBy,
    groupByFormat,
    groupByTerm,
    seriesItemKey,
    sort,
    xFormat,
    xDateFromMonth,
    booleanValues,
    percentLabel,
    xSort,
    ignoreDateTerm,
    yAxisFormat,
    seriesSorting,
    xKeySorting,
    xAxisDate,
    yFormula,
    yFormulaKeys,
    hideDetailLink,
    xQuarterFromDate,
    withNegative,
    hideLegend,
    title,
    titleColor,
    showLabel,
    overrideColor,
    colors,
    xAxisFormat,
    specialSortingTypes,
    regularFormatting,
    xKeySortingDirection,
    legendItems,
    fiscalQuarterStartOffset,
    legendTooltips,
    dynamicSortConfig,
    fixedSeriesSorting,
    allTicks,
    // noGroupingColors,
    forceRotateTicks,
    tooltipConfig,
    totalBar,
    legendHeight,
    relativeY,
  } = chart;

  let groupBy = _groupBy;
  if (seriesItemKey) {
    groupBy = seriesItemKey;
    reportDeprecated(
      `"seriesItemKey" is deprecated for MultiBar. Use "groupBy" instead.`
    );
  }

  function singleColor(index, _, topIndex) {
    if (Array.isArray(overrideColor)) {
      return overrideColor[topIndex];
    } else return overrideColor; // this can be used for flat for now
  }

  const defaultColorFunction = scaleOrdinal(schemeTableau10);

  function customColors(index) {
    return colors[index] ?? defaultColorFunction(index);
  }

  function setLegendColor(index, sik) {
    return (
      legendItems.find((item) => item.name === sik)?.color ??
      defaultColorFunction(index)
    );
  }

  const mappedXKey = xKeyParser(term, xKey, ignoreDateTerm || groupByTerm);

  let colorFn;

  if (colors) {
    colorFn = customColors;
  } else if (overrideColor) {
    colorFn = singleColor;
  } else if (legendItems) {
    colorFn = setLegendColor;
  } else {
    colorFn = defaultColorFunction;
  }

  const nonNullItems = filterOutNullValues(data, yKey, yKeys);

  const withOrOutNegatives = getWithOrOutNegatives(
    withNegative,
    nonNullItems,
    yKey
  );

  const dynamicData = getDynamicData(
    dynamicSortConfig,
    withOrOutNegatives,
    yKey
  );

  const converted = convertNumbersToBoolean(
    dynamicData,
    groupBy,
    booleanValues
  );

  const seriesItemKeys = booleanValues
    ? [true, false]
    : fixedSeriesSorting
    ? fixedSeriesSorting
    : pipe(
        mapToKey(groupBy, groupByTerm, term),
        doIf(seriesSorting, sortByKey(seriesSorting)),
        unique
      )(converted);

  const allXKeys = pipe(
    mapToKey(mappedXKey),
    unique,
    doIf(sort, sortByValues),
    doIf(
      xKeySorting,
      sortByKey(
        xKeySorting,
        xKeySortingDirection,
        regularFormatting,
        specialSortingTypes
      )
    )
  )(converted);

  const grouped = pipe(
    yKeys ? assignAsKeys(yKeys, "value") : assignAsKey(yKey, "value"),
    doIf(yFormula, applyFormula(yFormulaKeys)),
    groupByKey(mappedXKey)
  )(converted);

  if (booleanValues) {
    grouped.forEach((v) => {
      v.values = v.values.filter(
        (v) => v[groupBy] === true || v[groupBy] === false
      );
      v.values.sort((a, b) => b[groupBy] - a[groupBy]);
    });
  }

  if (xSort) {
    grouped.sort((a, b) => (a.key > b.key ? 1 : a.key < b.key ? -1 : 0));
    allXKeys.sort((a, b) => (a > b ? 1 : a < b ? -1 : 0));
  }

  if (percentLabel) {
    grouped.forEach((v) => {
      if (v.values[0] && v.values[1]) {
        v.label = makePercent(
          v.values[0].value,
          v.values[1].value + v.values[0].value
        );
      }
    });
  }

  function calcTotalBarValue(item) {
    return item.values.reduce((acc, curr) => (acc += curr[yKey]), 0);
  }

  function addTotalBar(item) {
    return {
      ...item,
      values: [
        ...item.values,
        {
          [yKey]: calcTotalBarValue(item),
          [groupBy]: totalBar,
          [xKey]: item.key,
          value: calcTotalBarValue(item),
        },
      ],
    };
  }

  const series = totalBar ? [...seriesItemKeys, totalBar] : seriesItemKeys;
  const withTotal = grouped.map((item) =>
    totalBar ? addTotalBar(item) : item
  );

  return (
    <>
      {title ? (
        <div
          style={{
            float: "left",
            fontSize: 16,
            fontWeight: 500,
            color: titleColor || null,
          }}
        >
          {title}
        </div>
      ) : null}
      <div id={chart.visualizationId} style={{ position: "relative" }}>
        <VisualizationBase {...{ ...props, margin, tooltipConfig }}>
          <GroupedBar
            {...chart}
            xKey={chart.xKey}
            yKey={chart.yKey}
            yKeys={yKeys}
            xType={chart.xType}
            allXKeys={allXKeys}
            groupBy={groupBy}
            seriesItemKeys={series}
            data={withTotal}
            colors={colorFn}
            xFormat={xFormat}
            yAxisFormat={yAxisFormat}
            term={term}
            xDateFromMonth={xDateFromMonth}
            xAxisDate={xAxisDate}
            meta={chart.meta}
            dateKey={chart.dateKey}
            xQuarterFromDate={xQuarterFromDate}
            withNegative={withNegative}
            showLabel={showLabel}
            xAxisFormat={xAxisFormat}
            fiscalQuarterStartOffset={fiscalQuarterStartOffset}
            allTicks={allTicks}
            forceRotateTicks={forceRotateTicks}
            relativeY={relativeY}
          />

          <Legend
            highTop
            foreignObject
            colors={colorFn}
            sections={yKeys || booleanValues || series}
            colorFunction
            horizontal
            wrapping
            hide={hideLegend}
            legendItems={legendItems}
            legendTooltips={legendTooltips}
            groupByFormat={groupByFormat}
            term={term}
            ignoreDateTerm={ignoreDateTerm}
            meta={chart.meta}
            legendHeight={legendHeight}
          />
        </VisualizationBase>
      </div>

      {details && chart.data.length > 0 && (
        <Details
          dashboardName={dashboardId}
          visualizationId={chart.visualizationId}
          hideDetailLink={hideDetailLink}
        />
      )}
    </>
  );
}

MultiGroupedBarVisualization.defaultValues = {
  data: [],
};

const margin = {
  top: 30,
  left: 46,
  bottom: 62,
  right: 20,
};
