import styled from "@emotion/styled";
import DrilldownDynamicFiltersMapping from "./DrilldownDynamicFiltersMapping";
import { Note } from "../../General/LimitRows/LimitRows";
import Select from "../../../../../UI/Form/Select/Select";
import Input from "../../../../../UI/Form/Input/Input";

const SelectGroup = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 25px;
`;

const menuHeight = 150;

export default function DrillownSelectContainer(props) {
  const {
    setParent,
    showParentKey,
    queries,
    currentQuery,
    parentQuery,
    onDynamicFiltersChange,
    filteredByOptions,
    filteredByOptionValues,
    currentChart,
    parent,
    onMappingChange,
    parentChart,
  } = props;

  const changeParentKey = (e) => {
    const val = e.target.value;
    if (!val) {
      return;
    }
    setParent(val);
  };

  return (
    <SelectGroup>
      <Select
        label="Query"
        options={queries}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.uuid}
        value={currentQuery}
        menuHeight={menuHeight}
        onChange={(options) =>
          onDynamicFiltersChange(options, "queryId", "uuid")
        }
        cy="edit-drill-down-query"
      />

      <br />
      <Select
        label="Filtered by"
        options={filteredByOptions}
        isMulti
        closeMenuOnSelect={false}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.name}
        value={filteredByOptionValues}
        menuHeight={menuHeight}
        onChange={(options) =>
          onDynamicFiltersChange(options, "dynamicFilters", "name")
        }
        isClearable={false}
        cy="edit-drill-down-filtering"
      />

      <DrilldownDynamicFiltersMapping
        currentQuery={currentQuery}
        parentQuery={parentQuery}
        currentChart={currentChart}
        onMappingChange={onMappingChange}
        parentChart={parentChart}
      />

      {showParentKey && (
        <div>
          <Input
            value={parent}
            onChange={changeParentKey}
            label="Parent Key (Expand Label)"
          />
          <Note color={"infoDark"}>example: "Location..."</Note>
        </div>
      )}
    </SelectGroup>
  );
}
