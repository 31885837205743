import { startCase } from "lodash-es";

/**
 * Formats a data source field name such that it shows both the friendly name
 * and column name if they differ, otherwise just one of the names.
 */
export function formatDataSourceFieldName(friendlyName, columnName) {
  return friendlyName && friendlyName !== columnName
    ? `${friendlyName} (${columnName})`
    : columnName;
}

export function generateHumanName(tableName) {
  tableName = tableName.split(".").at(-1);
  const humanName = startCase(tableName);

  // View
  if (humanName.startsWith("Vw ")) {
    return humanName.replace(/^Vw/, "VW");
  }

  // Materialized View
  if (humanName.startsWith("Mv ")) {
    return humanName.replace(/^Mv/, "MV");
  }

  // Materialized View
  if (humanName.startsWith("T ")) {
    return humanName.replace(/^T /, "");
  }

  return humanName;
}

const apiImageType = {
  key: "api-image",
  isFile: true,
};
const apiPdfType = {
  key: "pdf-image",
  isFile: true,
};
const apiExcelType = {
  key: "api-excel",
  isFile: true,
};
const apiWordType = {
  key: "api-word",
  isFile: true,
};

/**
 * Non-exhausted map of columns with their characteristics.
 */
export const DATA_SOURCE_COLUMN_TYPE_MAP = {
  "api-image": apiImageType,
  "api-pdf": apiPdfType,
  "api-excel": apiExcelType,
  "api-word": apiWordType,
};

export function isColumnTypeFile(columnType) {
  return !!DATA_SOURCE_COLUMN_TYPE_MAP[columnType]?.isFile;
}
