import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ColorModeItem } from "./ColorMode";
import { omit } from "lodash-es";

const Container = styled.div`
  font-size: 16px;
  display: flex;
  justify-content: space-around;
`;

export default function TextAlignOverride(props) {
  const { chart, setChartState, selectedColumn } = props;
  const { textAlignOverride, pinnedHeader, subTitles } = chart;
  const override = textAlignOverride ?? {};
  const { column } = selectedColumn ?? {};

  function overridePinnedHeaderAlign(align) {
    // Check if pinnedHeader and its columns exist
    if (!pinnedHeader?.columns) {
      return;
    }

    // Find the index of the column in the subtitles
    const index = (subTitles ?? []).flat().findIndex((sub) => sub === column);

    // Return a new pinnedHeader object with the updated column alignment
    return {
      ...pinnedHeader,
      columns: pinnedHeader.columns.map((col, i) =>
        i === index ? { ...col, align } : col
      ),
    };
  }

  function onTextAlignOverrideChange(align) {
    const newPinnedHeader = overridePinnedHeaderAlign(align);

    const newOverride = align
      ? { ...override, [column]: align }
      : omit(override, column);

    setChartState({
      ...chart,
      textAlignOverride: newOverride,
      ...(newPinnedHeader && { pinnedHeader: newPinnedHeader }),
    });
  }

  return (
    <>
      <br />
      <span>Text align override</span>
      <Container>
        <ColorModeItem
          title="Left alignment"
          isActive={override[column] === "left"}
          onClick={() => onTextAlignOverrideChange("left")}
        >
          <FontAwesomeIcon icon={["fas", "align-left"]} />
        </ColorModeItem>

        <ColorModeItem
          title="Center alignment"
          isActive={override[column] === "center"}
          onClick={() => onTextAlignOverrideChange("center")}
        >
          <FontAwesomeIcon icon={["fas", "align-center"]} />
        </ColorModeItem>

        <ColorModeItem
          title="Right alignment"
          isActive={override[column] === "right"}
          onClick={() => onTextAlignOverrideChange("right")}
        >
          <FontAwesomeIcon icon={["fas", "align-right"]} />
        </ColorModeItem>

        <ColorModeItem
          title="Default alignment"
          isActive={!override[column]}
          onClick={() => onTextAlignOverrideChange()}
        >
          <FontAwesomeIcon icon={["fas", "align-slash"]} />
        </ColorModeItem>
      </Container>
    </>
  );
}
