import React from "react";
import styled from "@emotion/styled";
import StaggeredOpacitySpring from "./StaggeredOpacitySpring";
import { lightenDarkenColor } from "../../styles/colorConvertor";
import mobileBreakpoints from "../../styles/mobileBreakpoints";
import InfoCardSecondaryValue from "./InfoCardSecondaryValue";
import Trend from "./Trend";
import Bands from "./Bands";
import getTargetRangeType from "./getTargetRangeType";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const InfoCardTitle = styled.div`
  color: ${(props) =>
    props.targetRangeType
      ? valueColor(props)
      : props.color
      ? props.color
      : props.theme.text.secondary};
  font-size: ${(props) => props.size || "14px"};
  font-weight: ${(props) => props.weight || "normal"};
  margin-left: 2px;
  margin-top: ${(props) => (props.wide ? 12 : 0)}px;
  @media (max-width: 800px) {
    margin-top: 12px;
  }
`;

function valueColor(props) {
  return props.primary
    ? lightenDarkenColor(props.theme.primary, 60)
    : props.successColor
    ? props.value.includes("-") && !props.value.includes("--")
      ? props.theme.notification.errorMain
      : props.theme.notification.successMain
    : props.targetRangeType
    ? props.targetRangeType === "neutral"
      ? props.theme.text.primary
      : "white"
    : props.color;
}

const InfoCardValue = styled.div`
  color: ${valueColor};
  font-weight: 700;
  font-size: ${(props) => (props.small ? 16 : props.large ? 36 : 22)}px;
  margin-top: ${(props) => (props.large ? 8 : 0)}px;
  position: relative;
  svg {
    position: absolute;
    right: 12px;
    top: 0;
    opacity: 0.5;
  }
`;

const TitleAppend = styled.span`
  color: ${(props) => props.theme.primary};
  padding-left: 4px;
  font-size: 11px;
`;

const Label = styled.span`
  color: ${(props) => props.theme.text.secondary};
  font-size: 12px;
  font-weight: normal;
`;

function getBackground(props) {
  const { backgroundType, theme } = props;
  if (!backgroundType) return "none";
  if (backgroundType === "lower") {
    return theme.notification.errorMain;
  } else if (backgroundType === "upper") {
    return theme.notification.successMain;
  } else {
    return theme.background.secondary;
  }
}

const Wrapper = styled.div`
  margin: 0 4px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
  height: ${(props) => props.height};
  width: ${(props) => (props.wide ? "100%" : "auto")};
  background: ${getBackground};
  border-radius: 2px;
  @media (max-width: ${mobileBreakpoints.mobile}) {
    margin: 0 12px;
  }
`;

export default function InfoCard(props) {
  const {
    title,
    value,
    titleColor,
    valueColor,
    i,
    append,
    titleAppend,
    primaryColor,
    small,
    lowerValue,
    titleSize,
    titleWeight,
    loaded,
    successColor,
    height,
    trendValues,
    data,
    drawZeros,
    chipStyle,
    targetRanges,
    rawValue,
    numberFormat,
    reverse,
    valueKey,
    arrayFormula,
    postfix,
  } = props;
  function renderValue() {
    if (value === "--" && drawZeros) return "0";
    return value || "n/a";
  }

  const targetRangeType = getTargetRangeType(
    targetRanges,
    rawValue,
    data,
    reverse
  );

  return (
    <Wrapper
      height={height}
      data-cy="header-kpi-block"
      data-cy-loaded={loaded ? "1" : "0"}
      wide={!!targetRanges}
      backgroundType={targetRangeType}
    >
      {title && (
        <InfoCardTitle
          data-cy="info-card-title"
          color={titleColor}
          size={titleSize}
          weight={titleWeight}
          wide={!!targetRanges}
          targetRangeType={targetRangeType}
        >
          {title}
          <TitleAppend>{dynamicTitleAppend(titleAppend)}</TitleAppend>
        </InfoCardTitle>
      )}

      <InfoCardValue
        data-cy="info-card-value"
        color={valueColor}
        targetRangeType={targetRangeType}
        primary={primaryColor}
        small={small}
        value={value}
        successColor={successColor}
        large={!!targetRanges}
      >
        <StaggeredOpacitySpring i={i} value={value} drawZeros={drawZeros}>
          {renderValue()}
          {value !== "--" ? append : ""}
        </StaggeredOpacitySpring>

        {targetRanges ? (
          <FontAwesomeIcon
            icon={[
              "fas",
              targetRangeType === "lower" ? "exclamation-circle" : "check",
            ]}
          />
        ) : null}
      </InfoCardValue>
      {postfix && <Label>{postfix}</Label>}
      {lowerValue && <InfoCardSecondaryValue value={lowerValue} />}
      {trendValues && (
        <Trend
          trendValues={trendValues}
          data={data}
          chipStyle={chipStyle}
          valueKey={valueKey}
          arrayFormula={arrayFormula}
        />
      )}

      {targetRanges && (
        <Bands
          ranges={targetRanges.bands}
          data={data}
          numberFormat={numberFormat}
          reverse={reverse}
        />
      )}
    </Wrapper>
  );
}

InfoCard.defaultProps = {
  valueColor: "black",
};

function dynamicTitleAppend(value) {
  switch (value) {
    case "[CURRENT_YEAR]":
      return new Date().getFullYear();

    default:
      return value;
  }
}
