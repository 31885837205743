import { laravelEchoWrapper } from "../utils/websockets";
import { useEffect, useState } from "react";

/**
 * @typedef {import('laravel-echo').Channel} LaravelEchoChannel
 */

/**
 * Listens to a pusher event for messages on a pusher channel.
 * @param {?LaravelEchoChannel} channel
 * @param {string} eventName
 * @param {Function} callback
 */
export function useLaravelEchoListen(channel, eventName, callback) {
  useEffect(() => {
    if (!channel) {
      return;
    }

    channel.listen(eventName, callback);

    return () => {
      if (channel) {
        channel.stopListening(eventName, callback);
      }
    };
  }, [callback, channel, eventName]);
}

/**
 * Subscribes to a private pusher channel for websocket events.
 *
 * @param {string} channelName
 * @return {?LaravelEchoChannel}
 */
export function useLaravelEchoPrivateChannel(channelName) {
  const [channel, setChannel] = useState(undefined);
  useEffect(() => {
    const channel = laravelEchoWrapper.private(channelName);

    setChannel(channel);

    return () => {
      try {
        laravelEchoWrapper.leave(channelName);
      } catch (e) {
        reportError(e);
      }
    };
  }, [channelName]);
  return channel;
}
