import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { addNewDrilldownLevel } from "../../../utils/tableEditorHelper";

const Container = styled.div(
  ({ theme, level }) => `
  margin-left: ${level * 30}px;
  margin-top: 62px;
  cursor: pointer;
  color: ${theme.primary};
  width: fit-content;
  white-space: nowrap;
  svg {
    margin: 0px 5px;
  }
`
);

export default function AddDrilldown({
  level,
  setEditLevel,
  chart,
  setTemporaryChartConfig,
  canAdd,
  drilldownParent,
  setDrilldownParent,
  setParent,
}) {
  if (!canAdd) {
    return null;
  }

  const addDrilldown = () => {
    const res = addNewDrilldownLevel(
      chart,
      null,
      level,
      drilldownParent,
      setDrilldownParent,
      setParent
    );
    setEditLevel(level);
    setTemporaryChartConfig({ ...res, addAction: true });
  };

  return (
    <Container level={level} onClick={addDrilldown} data-cy="add-drill-down">
      <FontAwesomeIcon icon={["fas", "plus"]} />
      Add Drilldown
    </Container>
  );
}
