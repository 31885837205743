/**
 * Gets the reducer function for gathering numbers or totals to appear on each stack component.
 *
 * @param yGroupValues
 * @param breakdownKey
 * @param yKeys
 * @returns {(function(*, *): (*))|*}
 */
export function getDataReducer(yGroupValues, breakdownKey, yKeys) {
  function dataReducer(acc, curr) {
    const ret = { ...acc };
    if (yKeys) {
      return multiKeyMode(acc, curr, yKeys);
    } else {
      return singleValueMode(acc, curr, ret, breakdownKey, yGroupValues);
    }
  }

  return dataReducer;
}

function multiKeyMode(acc, curr, yKeys) {
  const yKeyValues = yKeys.reduce((a, c) => {
    return { ...a, [c]: curr[c] };
  }, {});
  return { ...acc, ...yKeyValues };
}

function singleValueMode(acc, curr, ret, breakdownKey, yGroupValues) {
  if (Array.isArray(yGroupValues)) {
    for (const groupValue of yGroupValues) {
      const isObj = typeof groupValue === "object";
      const source = isObj ? groupValue.source : groupValue;
      const target = isObj ? groupValue.target : groupValue;

      ret[target] = (acc[source] || 0) + curr[source];
    }
    return ret;
  }

  const currKey = curr[breakdownKey];
  return { ...acc, [currKey]: (acc[currKey] || 0) + curr[yGroupValues] };
}
