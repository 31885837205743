import styled from "@emotion/styled";
import Th from "./Th";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTheme } from "emotion-theming";

const Tr = styled.tr(
  ({ theme, border }) => `
  ${
    border &&
    `
      outline: 1px solid orange;
      border: 2px solid transparent;
    `
  };
  color: white;
  min-height: 31px;
  position: sticky;
  z-index: 16;
  top: 1px;

  th {
    background: ${
      theme.type === "dark" ? theme.gray.gray900 : theme.gray.gray400
    };
  }
`
);

export default function PinnedHeaders({
  freezeLeft,
  freezeWidth,
  pinnedHeader,
  hasRowExpand,
}) {
  const theme = useTheme();

  if (!pinnedHeader) {
    return null;
  }

  return (
    <Tr border={pinnedHeader.border}>
      {(pinnedHeader.columns ?? []).map((column, index) => (
        <Th
          background={0}
          freeze={freezeLeft && index === 0}
          key={column.value + index}
          freezeWidth={freezeWidth}
          textAlign={column.align}
        >
          {column.icon && (
            <FontAwesomeIcon
              icon={column.icon}
              style={{
                marginRight: 10,
                color: theme.primary,
                rotate: "45deg",
                ...(hasRowExpand && { marginLeft: 20 }),
              }}
            />
          )}
          {column.value}
        </Th>
      ))}
    </Tr>
  );
}
