import { localTimeFormatsBasedOnDomain } from "./constants/constants";
import { format as dateFormat } from "date-fns";

function removeUnnesessarySigns(table, convertPercentsBack) {
  if (!table) {
    return;
  }

  const cloned = table
    .cloneNode(true)
    .innerHTML.replaceAll(/^[+-]?\d+(\.\d+)?%$/gm, (match) => {
      if (convertPercentsBack) {
        const replaced = match.replaceAll(/^[+-]?\d+(\.\d+)?%$/gm, "");
        return replaced;
      }

      return match;
    })
    // converting all yyyy-mm-dd dates to string
    .replaceAll(/\d{4}-\d{2}-\d{2}/gm, (match) => {
      return match + invisibleSymbol;
    })
    .replaceAll(/\$/gm, "")
    .replaceAll(/--/gm, "")
    .replaceAll(/,/gm, "")
    .replaceAll(/<title (.*?)>(.*?)<\/title>/gm, "");

  const html = document.createElement("table");
  html.innerHTML = cloned.trim();

  return html;
}

function clearFileName(name) {
  const cleared = name
    .replaceAll("/", " ")
    .replaceAll("\\", " ")
    .replaceAll("?", " ")
    .replaceAll("*", " ")
    .replaceAll(":", " ")
    .replaceAll("[", " ")
    .replaceAll("]", " ")
    .replaceAll("'", " ");

  return cleared.length > 30 ? cleared.substring(0, 29) : cleared;
}

export const exportXlsx = async (
  tableId,
  convertPercentsBack,
  filename,
  page = {},
  dates,
  filters,
  domain
) => {
  const table = document.getElementById(tableId);
  const formattedTable = removeUnnesessarySigns(table, convertPercentsBack);
  const name = filename || "Insight Out Data";

  if (!formattedTable) {
    return;
  }

  const { utils, writeFile } = await import("xlsx");

  appendPageName(formattedTable, page);

  if (dates && !dates.hideOnPages?.includes(page.uuid)) {
    appendDates(formattedTable, dates);
  }

  appendFilters(formattedTable, filters, page);

  appendCurrentDateTime(formattedTable, domain);

  const wb = utils.book_new();
  const ws = utils.table_to_sheet(formattedTable);

  utils.book_append_sheet(wb, ws, clearFileName(name));

  writeFile(wb, clearFileName(name) + ".xlsx", {
    bookType: "xlsx",
    bookSST: true,
    cellHTML: true,
    type: "binary",
    editable: true,
  });
};

function insertEmptyRow(table, emptyRows) {
  Array(emptyRows)
    .fill("")
    .forEach(() => {
      const tr = document.createElement("tr");
      const td = tr.appendChild(document.createElement("td"));
      td.innerHTML = "";
      table.appendChild(tr);
    });
}

function appendPageName(table, page) {
  if (!page) {
    return;
  }

  insertEmptyRow(table, 2);
  const tr = document.createElement("tr");

  ["Page:", page.displayName].forEach((item) => {
    const td = tr.appendChild(document.createElement("td"));
    td.innerHTML = item;
  });

  table.appendChild(tr);
}

function appendDates(table, dates) {
  if (!dates?.start) return;

  insertEmptyRow(table, 1);

  const tr = document.createElement("tr");
  const td = tr.appendChild(document.createElement("td"));
  td.innerHTML = "Dates";

  table.appendChild(tr);

  const start = new Date(dates.start.value);
  const end = new Date(dates.end.value);

  const timezoneOffset = start.getTimezoneOffset() / 60 + 1;

  start.setHours(start.getHours() + timezoneOffset);
  end.setHours(end.getHours() + timezoneOffset);

  [
    { value: start, label: "Start" },
    { value: end, label: "End" },
  ].forEach((item) => {
    const tr = document.createElement("tr");
    const label = tr.appendChild(document.createElement("td"));
    const value = tr.appendChild(document.createElement("td"));
    label.innerHTML = item.label;
    value.innerHTML = item.value;

    table.appendChild(tr);
  });
}

function appendFilters(table, filters = [], page) {
  const activeFilters = filters
    .filter((f) => {
      if (f.show) {
        return f.show.includes(page.uuid);
      }
      return !f.hide?.includes(page.uuid);
    })
    .reduce((acc, curr) => {
      const checked = curr.values.filter((v) => v.checked);
      if (checked.length) {
        acc.push({ ...curr, values: checked });
      }

      return acc;
    }, []);

  if (!activeFilters.length) {
    return;
  }

  insertEmptyRow(table, 1);

  const tr = document.createElement("tr");
  const td = document.createElement("td");

  td.innerHTML = "Filters";
  tr.appendChild(td);
  table.appendChild(tr);

  activeFilters.forEach((filter) => {
    const tr = document.createElement("tr");
    const td = document.createElement("td");

    td.innerHTML = filter.displayName;
    tr.appendChild(td);

    filter.values.forEach((v) => {
      const td = document.createElement("td");
      td.innerHTML = v.label;
      tr.appendChild(td);
    });

    table.appendChild(tr);
  });
}

function appendCurrentDateTime(table, domain) {
  const { format } = localTimeFormatsBasedOnDomain[domain] ?? {};

  if (!format) {
    return;
  }

  const date = dateFormat(new Date(), format);

  insertEmptyRow(table, 1);

  const tr = document.createElement("tr");
  const name = tr.appendChild(document.createElement("td"));
  name.innerHTML = `Local Date: `;

  const value = tr.appendChild(document.createElement("td"));
  value.innerHTML = `${date} ${invisibleSymbol}`;

  table.appendChild(tr);
}

// this character need to get rid of excel date conversion and save date as string
const invisibleSymbol = "&#8205;";
