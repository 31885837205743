import styled from "@emotion/styled";
import { Hr } from "../../../../../Layout/Filters/DependencyFilter/FilterInputs";
import Flex from "../../../../../UI/Flex/Flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IOButton from "../../../../../UI/Form/Button/IOButton";

const RemoveContainer = styled.div(
  ({ theme }) => `
  color: ${theme.notification.errorMain};
  font-weight: 500;
  cursor: pointer;
  font-size: 14px;
  svg {
    margin-right: 10px;
  }
  `
);

export default function BottomActions(props) {
  const {
    chart,
    confirm,
    saveDrilldown,
    disabled,
    level,
    addNewMapping,
    dynamicFilterValue,
  } = props;

  return (
    <>
      <Hr showOriginalDivider />
      <Flex
        justifyContent={chart.addAction ? "flex-end" : "space-between"}
        alignItems="center"
        mt2
      >
        {!chart.addAction && (
          <RemoveContainer onClick={confirm.setConfirming}>
            <FontAwesomeIcon icon={["fas", "trash"]} />
            Remove
          </RemoveContainer>
        )}
        <IOButton
          smallPadding
          add
          circle
          onClick={() =>
            addNewMapping({ ...(dynamicFilterValue ?? {}), key: "" })
          }
        >
          Add mapping
        </IOButton>
        <IOButton
          smallPadding
          onClick={() => saveDrilldown(level)}
          disabled={disabled}
          cy="save-drilldown-level"
        >
          Save
        </IOButton>
      </Flex>
    </>
  );
}
