import styled from "@emotion/styled";
import Modal from "../../../../../UI/Modal/Modal";
import useConfirmDelete from "../../../../../hooks/useConfirmDelete";
import Input from "../../../../../UI/Form/Input/Input";
import { scrollbarDe } from "../../../../../styles/styledSnippets";
import DrillownSelectContainer from "./DrilldownSelectContainer";
import BottomActions from "./BottomActions";

import {
  confirmation,
  getFilteredByOptions,
  getFilteredByOptionValues,
  getIsDisabled,
  getNestedDrilldownConfig,
} from "../../../utils/tableEditorHelper";

const Container = styled.div`
  height: ${(props) => (props.extended ? 500 : 400)}px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow-y: auto;
  ${(props) => scrollbarDe(props.theme)};
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 20px;
`;

export default function EditDrilldown({
  level,
  setEditLevel,
  queries,
  chart,
  deleteDrilldown,
  saveDrilldown,
  onDynamicFiltersChange,
  setTemporaryChartConfig,
  drilldownParent,
  setParent,
  parent,
  dropDynamicLevelIndicators,
  setDrilldownParent,
  onMappingChange,
}) {
  const { title, message, rounded, zIndexOverride } = confirmation;

  const confirm = useConfirmDelete(
    deleteDrilldown,
    title,
    message,
    rounded,
    zIndexOverride
  );

  if (!level) {
    return null;
  }

  const currentChart = getNestedDrilldownConfig(chart, level, drilldownParent);

  const parentChart = getNestedDrilldownConfig(
    chart,
    level - 1,
    drilldownParent
  );

  const showParentKey = parentChart.dynamicDrilldowns && drilldownParent[level];

  const getQueryByUuid = (uuid) => {
    return queries.find((query) => query.uuid === uuid);
  };

  const currentQuery = getQueryByUuid(currentChart.queryId);
  const parentQuery = getQueryByUuid(parentChart.queryId);

  const filteredByOptions = getFilteredByOptions(
    currentQuery,
    currentChart.parameterizedFilterPrefix
  );

  const filteredByOptionValues = getFilteredByOptionValues(
    filteredByOptions,
    currentChart.dynamicFilters
  );

  const disabled = getIsDisabled(
    filteredByOptionValues,
    currentQuery,
    parent,
    showParentKey
  );

  const closeModal = () => {
    setEditLevel(0);
    setTemporaryChartConfig(null);

    if (chart.addAction) {
      setDrilldownParent({});
    }

    if (level === 1 && chart.addAction) {
      dropDynamicLevelIndicators();
    }
  };

  return (
    <>
      <Modal rounded width={"450px"} showClose close={closeModal}>
        <Container extended={showParentKey}>
          <Title data-cy="modal-title-drilldown">
            {chart.addAction ? "Add " : "Edit "}Level {level}
          </Title>

          <DrillownSelectContainer
            setParent={setParent}
            showParentKey={showParentKey}
            queries={queries}
            currentQuery={currentQuery}
            parentQuery={parentQuery}
            onDynamicFiltersChange={onDynamicFiltersChange}
            filteredByOptions={filteredByOptions}
            filteredByOptionValues={filteredByOptionValues}
            currentChart={currentChart}
            parent={parent}
            onMappingChange={onMappingChange}
            parentChart={parentChart}
          />

          <Input
            type="color"
            value={currentChart.expandedRowBackground ?? ""}
            label="Level color"
            style={{ padding: 0, marginBottom: 20 }}
            onChange={(e) =>
              onDynamicFiltersChange(
                { color: e.target.value },
                "expandedRowBackground",
                "color"
              )
            }
          />
        </Container>

        <BottomActions
          chart={chart}
          confirm={confirm}
          saveDrilldown={saveDrilldown}
          disabled={disabled}
          level={level}
          addNewMapping={onMappingChange}
          dynamicFilterValue={currentChart.dynamicFilterValue}
        />
      </Modal>
      {confirm.confirming}
    </>
  );
}
