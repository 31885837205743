import axios from "../../axios";
import * as actionTypes from "./actionTypes";
import { showToast, showToastWithTimeout } from "./message";
import {
  normalizeError,
  VALIDATION_FAILURE,
  handleError,
} from "../../utils/errorHandling";
import { connectionFormatConverter } from "../../utils/connections";

export const loadConnections = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_CONNECTIONS_START });

  try {
    const res = await axios.get("/api/v1/connections");
    dispatch({
      type: actionTypes.GET_CONNECTIONS_SUCCESS,
      connections: res.data,
    });
    return res.data;
  } catch (err) {
    dispatch({ type: actionTypes.GET_CONNECTIONS_FAIL });
    throw err;
  }
};

// // Without middleware
// export const deleteConnection = (connection) => async (dispatch) => {
//   dispatch({ type: actionTypes.DELETE_CONNECTION_START });
//
//   try {
//     await axios.delete(`/api/v1/connections/${connection.uuid}`, {
//       headers: { Authorization: "Bearer " + getToken() },
//     });
//
//     dispatch({ type: actionTypes.DELETE_CONNECTION_SUCCESS, connection });
//   } catch (err) {
//     dispatch({ type: actionTypes.DELETE_CONNECTION_FAIL });
//     showToast(
//       actionTypes.DELETE_CONNECTION_FAIL,
//       dispatch,
//       ["Delete connection failed"],
//       "danger"
//     );
//   }
// };

// With middleware
export const deleteConnection = (connection) => {
  return {
    type: actionTypes.DELETE_CONNECTION_START,
    meta: {
      api: {
        method: "DELETE",
        endpoint: `api/v1/connections/${connection.uuid}`,
        toastOnFailure: true,
      },
    },
  };
};

// @todo this needs to
export const testConnection = (connection) => {
  return {
    type: actionTypes.TEST_CONNECTION_START,
    meta: {
      api: {
        method: "GET",
        endpoint: `api/v1/connections/${connection.uuid}/test`,
      },
    },
  };
};

export const createUpdateConnection =
  ({ edit, ...connection }) =>
  async (dispatch) => {
    dispatch({
      type: edit
        ? actionTypes.UPDATE_CONNECTION_START
        : actionTypes.CREATE_CONNECTION_START,
    });

    try {
      const data = connectionFormatConverter.form.toRequest(connection);
      const res = await axios({
        method: edit ? "PUT" : "POST",
        url: `/api/v1/connections${edit ? "/" + connection.uuid : ""}`,
        data,
      });

      const actionType = edit
        ? actionTypes.UPDATE_CONNECTION_SUCCESS
        : actionTypes.CREATE_CONNECTION_SUCCESS;
      dispatch({
        type: actionType,
        connection: res.data,
      });

      const message = "Connection was saved successfully.";
      showToastWithTimeout(dispatch, [message], "success");
      return true;
    } catch (err) {
      const message = err?.response?.data?.message
        ? err.response.data.message
        : "Failed to save connection";

      const actionType = edit
        ? actionTypes.UPDATE_CONNECTION_FAIL
        : actionTypes.CREATE_CONNECTION_FAIL;

      dispatch({
        type: actionType,
      });

      showToastWithTimeout(dispatch, [message], "danger");
    }
  };

export const createUpdateConnectionOverride =
  ({ edit, ...override }, newOverride) =>
  async (dispatch) => {
    dispatch({
      type: edit
        ? actionTypes.UPDATE_CONNECTION_OVERRIDE_START
        : actionTypes.CREATE_CONNECTION_OVERRIDE_START,
    });

    try {
      const res = await axios({
        method: edit ? "PUT" : "POST",
        url: `/api/v1/connections/${override.connectionUuid}/overrides${
          edit ? "/" + override.uuid : ""
        }`,
        data: {
          name: override.name,
          enabled: override.enabled,
          typeId: override.typeId,
          host: override.host,
          port: override.port,
          username: override.username,
          password: override.password,
          database: override.database,
        },
      });

      if (newOverride) {
        dispatch(createUpdateConnectionOverride({ ...newOverride, edit }));
      }

      dispatch({
        type: edit
          ? actionTypes.UPDATE_CONNECTION_OVERRIDE_SUCCESS
          : actionTypes.CREATE_CONNECTION_OVERRIDE_SUCCESS,
        override: res.data,
      });

      const message = edit ? "Override updated" : "Override created";

      !newOverride && showToastWithTimeout(dispatch, [message], "success");
      return true;
    } catch (err) {
      const normalizedError = normalizeError(err);
      const toastMessage =
        normalizedError.type === VALIDATION_FAILURE
          ? [
              edit ? "Update override failed:" : "Create override failed:",
              ...Object.values(normalizedError.data).flat(),
            ].join("\n")
          : normalizedError.message;

      dispatch({
        type: edit
          ? actionTypes.UPDATE_CONNECTION_OVERRIDE_FAIL
          : actionTypes.CREATE_CONNECTION_OVERRIDE_FAIL,
      });
      showToastWithTimeout(dispatch, toastMessage, "danger");
    }
  };

export const deleteConnectionOverride = (override) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE_CONNECTION_OVERRIDE_START });

  try {
    await axios.delete(
      `/api/v1/connections/${override.connectionUuid}/overrides/${override.uuid}`
    );

    dispatch({
      type: actionTypes.DELETE_CONNECTION_OVERRIDE_SUCCESS,
      override,
    });
    showToast(
      actionTypes.DELETE_CONNECTION_OVERRIDE_SUCCESS + "d",
      dispatch,
      ["Delete override success"],
      "success"
    );
  } catch (err) {
    dispatch({ type: actionTypes.DELETE_CONNECTION_OVERRIDE_FAIL });
    showToast(
      actionTypes.DELETE_CONNECTION_OVERRIDE_FAIL,
      dispatch,
      ["Delete connection override failed"],
      "danger"
    );
  }
};

export const loadDefaultConnection = (getState) => {
  const { list } = getState().connections;
  return list.find((v) => v.primaryWarehouse);
};

export const loadDefaultConnectionTables = () => (dispatch, getState) => {
  const defaultConnection = loadDefaultConnection(getState);

  dispatch(
    loadConnectionTables(defaultConnection?.uuid, defaultConnection?.database)
  );
};

export const loadConnectionTables =
  (connectionUuid, dbName, setError) => async (dispatch) => {
    dispatch({ type: actionTypes.GET_CONNECTION_TABLES_START });

    try {
      const res = await axios.get(
        `/api/v1/connections/${connectionUuid}/tables?withoutColumns=1&dbName=${dbName}`
      );

      dispatch({
        type: actionTypes.GET_CONNECTION_TABLES_SUCCESS,
        tables: res.data,
      });
    } catch (err) {
      const normalizedError = handleError(dispatch, err, {
        setError,
      });

      dispatch({
        type: actionTypes.GET_CONNECTION_TABLES_FAIL,
        error: normalizedError.message,
      });

      throw err;
    }
  };

export const getAllDataSources = (perPage) => {
  return {
    type: actionTypes.GET_ALL_DATA_SOURCES_START,
    meta: {
      api: {
        endpoint:
          "api/v1/data_sources" + (perPage ? "?perPage=" + perPage : ""),
        method: "get",
      },
    },
  };
};

export const getTableDataByTableName = (tableName) => (dispatch, getState) => {
  const { uuid, database } = loadDefaultConnection(getState) ?? {};

  dispatch(fetchTableDataByTableName(uuid, database, tableName));
};

const fetchTableDataByTableName = (connectionUuid, database, tableName) => {
  return {
    type: actionTypes.GET_TABLE_DATA_START,
    meta: {
      api: {
        endpoint: `/api/v1/connections/${connectionUuid}/columns?dbName=${database}&tableName=${tableName}`,
        method: "get",
      },
    },
  };
};
