/* eslint-disable no-console */
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { showToastWithTimeout } from "../../store/actions/message";
import axios from "../../axios";
import { normalizeError } from "../../utils/errorHandling";
import IOButton from "../../UI/Form/Button/IOButton";
import {
  useLaravelEchoPrivateChannel,
  useLaravelEchoListen,
} from "../../hooks/useLaravelEcho";

/**
 * Secret page for testing websockets.
 */
export default function WebsocketTestPage() {
  const dispatch = useDispatch();
  const listenCallback = useCallback(
    (message) => {
      console.info("message received", message);
      showToastWithTimeout(dispatch, [message.message], "success");
    },
    [dispatch]
  );
  const channel = useLaravelEchoPrivateChannel("test");
  useLaravelEchoListen(channel, "WebsocketTestSent", listenCallback);

  return (
    <div>
      <div style={{ marginBottom: 20 }}>
        Clicking on the button below should trigger an API call, and you should
        see a success toast message if the websocket message got received
        successfully.
      </div>

      <IOButton
        type="button"
        onClick={() =>
          axios.post("/api/v1/test_websockets").catch((e) => {
            console.error(e);
            showToastWithTimeout(
              dispatch,
              [normalizeError(e).message],
              "danger"
            );
          })
        }
      >
        Test websocket
      </IOButton>
    </div>
  );
}
