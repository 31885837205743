import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "../Flex/Flex";

const Container = styled.div`
  color: #ccc;
  position: relative;
  margin: 0;
  padding: 0;
  width: 90px;
  height: 16px;
  span {
    display: inline-block;
    width: 90px;
  }
`;

const CheckedStars = styled.div(
  ({ percent }) => `
    color: #e7711b;
    padding: 0;
    position: absolute;
    z-index: 1;
    display: block;
    top: 0;
    left: 0;
    overflow: hidden;
    width: ${percent}%;
    svg {
      font-size: 16px;
    }
`
);

const EmptyStars = styled(CheckedStars)`
  width: 100%;
  color: #ccc;
  z-index: 0;
`;

const StarValue = styled.span`
  margin-right: 10px;
  color: ${(props) => props.theme.text.secondary};
`;

export default function StarRating({ value, showValue }) {
  const percent = (value * 100) / 5 - 1;
  const starsArray = [1, 2, 3, 4, 5];

  const stars = (
    <span>
      {starsArray.map((val) => (
        <FontAwesomeIcon icon={["fas", "star"]} key={val} />
      ))}
    </span>
  );

  const convertedValue = +value < 1 ? parseFloat(value) : value;

  return (
    <Flex justifyContent="flex-end">
      {showValue ? <StarValue>{convertedValue}</StarValue> : null}
      <Container>
        <CheckedStars percent={percent < 0 ? 0 : percent}>{stars}</CheckedStars>
        <EmptyStars>{stars}</EmptyStars>
      </Container>
    </Flex>
  );
}
