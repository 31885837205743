import React, { useState } from "react";
import MultipleLineChart from "./MultipleLineChart/MultipleLineChart";
import Details from "../Layout/Details/Details";
import LineLegend from "./MultipleLineChart/LineLegend";

import {
  assignAsKey,
  groupByKey,
  pipe,
  dateSort,
  groupUnder,
  doIfElse,
  doIf,
  addScaleDate,
  switchAxes,
} from "../utils/func";
import VisualizationBase from "./BaseChart/VisualizationBase";
import MarkerToggleSwitch from "./LineChart/MarkerToggleSwitch";
import { chartSectionsLimit } from "../utils/constants/constants";
import { orderBy } from "lodash-es";
import { toDateType } from "../utils/dates/dateFunc";
import ChartActionsToggle from "./ChartActions/ChartActionsToggle";
import styled from "@emotion/styled";
import mobileBreakpoints from "../styles/mobileBreakpoints";

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  @media (max-width: ${mobileBreakpoints.mobile}) {
    padding-top: 35px;
  }
`;

const hasAttr = (attrs, key) => attrs && attrs.find((a) => a === key);

function getIsBandsFormat(xAxisDate) {
  return xAxisDate === "date-week";
}

const LineVisualization = (props) => {
  const { chart, details, dashboardId, QO, term } = props;

  const {
    data,
    xKey,
    xKeyFormat,
    colors,
    xTickCount,
    valueKeys = [],
    dashedKeys,
    curvedKeys,
    meta,
    xDateKey,
    groupBy,
    legendItems,
    trendLine,
    dateParserStyle,
    dateParserYearKey,
    yAxisFormat,
    yAxisPad,
    yAxisActualRange,
    lineLabelFormat,
    lineLabelPrecision,
    nullLegend,
    verticalLegend,
    legendSize,
    defaultColors,
    splitedDateFormat,
    legendLinkKey,
    lineLabelUnit,
    relativeY,
    xAxisDate,
    bands,
    hideLegend,
    hideCircles,
    yRightAxis,
    yRightAxisFormat,
    valueKeysRightAxis = [],
    showLabel,
    yearOverYear,
    yAxisTypeLabel,
    yRightAxisTypeLabel,
    disableLegendSizeOrder,
    variableValueKeys,
    showQuarterShade,
    showFancyWeeks,
    xKeyDomain,
    formatBands,
    tooltipForceType,
    tooltipDateFormat,
    fiscalQuarterStartOffset,
    fixedYTickStep,
    labelFormat,
    forceRotateLabels,
    ignoreDateTerm,
    rightAxisGroupByValues,
    margin = {},
    withNegative,
    chartSectionsLimitOverride,
    tooltipConfig,
  } = chart;

  const [showCircles, setShowCircles] = useState(!hideCircles);
  const newBands = getIsBandsFormat(xAxisDate) || bands;

  const [activeValueKey, setActiveValueKey] = useState(
    variableValueKeys?.length && variableValueKeys[0]
  );
  const [detailsMarginTop, setDetailsMarginTop] = useState(0);

  const dated = filterOutW();
  const chartSectionsCount = chartSectionsLimitOverride || chartSectionsLimit;

  function filterNegatives(item) {
    const allNonNegative = !Object.values(item).some(
      (val) => !isNaN(+val) && +val < 0
    );
    return withNegative || allNonNegative;
  }

  function filterOutW() {
    return data.filter((d) => d[xKey] !== " W").filter(filterNegatives);
  }

  const initialLineGroups = pipe(
    assignAsKey(xKey, "xValue"),
    doIf(!newBands, toDateType("xValue", dateParserStyle, dateParserYearKey)),
    doIf(yearOverYear, addScaleDate()),
    dateSort("xValue"),
    doIf(
      groupBy,
      assignAsKey(activeValueKey?.valueKey || valueKeys[0], "value")
    ),
    doIfElse(
      groupBy,
      groupByKey(groupBy),
      groupUnder(valueKeys, valueKeysRightAxis)
    )
  )(dated);

  const preFormulaLineGroup = switchAxes(
    initialLineGroups,
    rightAxisGroupByValues
  ).map((l) => ({
    ...l,
    curved: hasAttr(curvedKeys, l.key),
    dashed: hasAttr(dashedKeys, l.key),
    link: l[legendLinkKey],
    values: (l.values ?? []).map((v) => ({ ...v, value: +v.value })),
    sum: (l.values ?? []).reduce((acc, curr) => acc + (curr.value ?? 0), 0),
    rightAxisValues: l.rightAxisValues?.map((v) => ({
      ...v,
      value: +v.value,
    })), // if has right Y axis
  }));

  const ordered = disableLegendSizeOrder
    ? preFormulaLineGroup
    : orderBy(preFormulaLineGroup, "summ", "desc");

  const chartMargin = {
    left: 44,
    top: 20,
    right: yRightAxis ? 80 : 30,
    bottom: 24,
    ...margin,
  };

  // do not draw line chart with wrong not number values
  if (!activeValueKey?.valueKey && !valueKeys.length) {
    return null;
  }

  return (
    <>
      <Container id={chart.visualizationId} offset-height="true">
        <VisualizationBase
          {...{ ...props, margin: chartMargin }}
          tooltipConfig={tooltipConfig}
        >
          <MultipleLineChart
            {...chart}
            lineGroups={ordered.slice(0, chartSectionsCount)}
            legendItems={legendItems}
            legendSize={legendSize}
            colors={colors || chartColors}
            markers
            sectionKey="segment"
            xKeyFormat={xKeyFormat}
            tickCount={xTickCount || 12}
            isLinear
            xKey={xKey}
            xDateKey={xDateKey}
            valueKeys={valueKeys}
            dashedKeys={dashedKeys}
            curvedKeys={curvedKeys}
            meta={meta}
            QO={QO}
            trendLine={trendLine}
            yAxisFormat={yAxisFormat}
            yAxisPad={yAxisPad}
            yAxisActualRange={yAxisActualRange}
            showCircles={showCircles}
            defaultColors={defaultColors}
            splitedDateFormat={splitedDateFormat}
            lineLabelFormat={lineLabelFormat}
            lineLabelPrecision={lineLabelPrecision}
            lineLabelUnit={lineLabelUnit}
            relativeY={relativeY}
            xAxisDate={xAxisDate}
            bands={newBands}
            yRightAxis={yRightAxis}
            yRightAxisFormat={yRightAxisFormat}
            showLabel={showLabel}
            yearOverYear={yearOverYear}
            yAxisTypeLabel={activeValueKey?.displayName || yAxisTypeLabel}
            yRightAxisTypeLabel={yRightAxisTypeLabel}
            showQuarterShade={showQuarterShade}
            showFancyWeeks={showFancyWeeks}
            activeValueKey={activeValueKey?.valueKey || valueKeys[0]}
            xKeyDomain={xKeyDomain}
            formatBands={formatBands}
            dateParserStyle={dateParserStyle}
            tooltipForceType={tooltipForceType}
            tooltipDateFormat={tooltipDateFormat}
            fiscalQuarterStartOffset={fiscalQuarterStartOffset}
            fixedYTickStep={fixedYTickStep}
            labelFormat={labelFormat}
            forceRotateLabels={forceRotateLabels}
            term={ignoreDateTerm ? null : term}
          />

          <LineLegend
            foreignObject
            legendItems={legendItems}
            lineGroups={ordered.slice(0, chartSectionsCount)}
            chartColors={colors || chartColors}
            fieldMapping={meta && meta.fields}
            nullLegend={nullLegend}
            verticalLegend={verticalLegend}
            defaultColors={defaultColors}
            hide={hideLegend}
            legendSize={legendSize}
            QO={QO}
            trendLine={trendLine}
            setDetailsMarginTop={setDetailsMarginTop}
          />
        </VisualizationBase>
        <MarkerToggleSwitch
          onClick={() => setShowCircles(!showCircles)}
          active={showCircles}
        />
        <ChartActionsToggle
          variableValueKeys={variableValueKeys}
          setVariableValueKey={setActiveValueKey}
          activeValueKey={activeValueKey}
        />
      </Container>
      {details ? (
        <Details
          dashboardName={dashboardId}
          visualizationId={chart.visualizationId}
          marginTop={detailsMarginTop}
        />
      ) : null}
    </>
  );
};

export default React.memo(LineVisualization);

const chartColors = [
  "#4caf50",
  "#00aff1",
  "#f28e2c",
  "#e15759",
  "#76b7b2",
  "#edc949",
  "#af7aa1",
  "#ff9da7",
  "#9c755f",
  "#bab0ab",
  "#439878",
  "#256653",
  "#ffaa56",
  "#ff7656",
  "#64c4c2",
  "#ffeb89",
  "#c59cc5",
  "#ffacb0",
  "#a28160",
  "#c3b8b2",
];
