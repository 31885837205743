import React from "react";
import ChartContainer from "./ChartContainer";
import ResizeBaseChart from "./ResizeBaseChart";
import PropTypes from "prop-types";

export default function VisualizationBase(props) {
  const { height: rootHeight, margin, tooltipConfig } = props;
  const {
    height,
    data,
    loading = true,
    refreshing,
    error,
    visualizationId,
    canvasOff,
    hideTooltip,
    offsetHeight,
    offsetWidth,
    restricted,
    noResultsMessageOverride,
    runQueryOnFiltersMessage,
  } = props.chart;

  const overrideHeight = height || rootHeight;
  const metaFields = props.chart?.meta?.fields;

  return (
    <ChartContainer
      height={overrideHeight}
      hasData={!!(data && data.length)}
      loading={loading}
      refreshing={refreshing}
      error={error}
      restricted={restricted}
      noResultsMessageOverride={noResultsMessageOverride}
      runQueryOnFiltersMessage={runQueryOnFiltersMessage}
    >
      <ResizeBaseChart
        tooltipConfig={tooltipConfig}
        metaFields={metaFields}
        visualizationId={visualizationId}
        margin={margin}
        height={height}
        canvasOff={canvasOff}
        hideTooltip={hideTooltip}
        offsetHeight={offsetHeight}
        offsetWidth={offsetWidth}
      >
        {React.Children.map(props.children, (child) =>
          !child.props.foreignObject ? React.cloneElement(child) : null
        )}
      </ResizeBaseChart>
      {React.Children.map(props.children, (child) =>
        child.props.foreignObject ? React.cloneElement(child) : null
      )}
    </ChartContainer>
  );
}

VisualizationBase.defaultProps = {
  chart: {
    data: [],
    loading: true,
    margin: { top: 0, right: 0, bottom: 0, left: 0 },
  },
};

VisualizationBase.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.array,
};
