export const connectionFormatConverter = {
  form: {
    toRequest: convertFormToRequest,
  },
};

function convertFormToRequest(form) {
  const request = { ...form };
  if ("primaryWarehouse" in request) {
    request.primaryWarehouse = !!request.primaryWarehouse;
  }
  return request;
}
