import * as actions from "../actions/actionTypes";
import produce from "immer";

const user = JSON.parse(localStorage.getItem("user"));
const permissions = JSON.parse(localStorage.getItem("myPermissions")) ?? [];

const emptyMenuFilterSettings = {
  data: null,
  loading: false,
  error: null,
};

const initialState = {
  name: user ? user.name : null,
  email: user ? user.email : null,
  uuid: user ? user.uuid : null,
  tenantUuid: user ? user.tenantUuid : null,
  accountUuid: user ? user.accountUuid : null,
  isAuthenticated: !!(user && user.name),
  isAuthenticationComplete: false,
  loading: false,
  role: user ? user.role : null,
  avatar: user ? user.avatar : null,
  authErrors: null,
  error: null, // Normalized error.
  forgotComplete: false,
  ttg: !!user?.ttg,
  permissions,
  /** @deprecated You should use "groups" instead. */
  group: user?.group ?? null,
  groups: [],
  hasPages: false,
  menuFilterSettings: emptyMenuFilterSettings,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.LOGIN_START:
      return {
        ...state,
        loading: true,
        authErrors: null,
      };

    case actions.CHECK_AUTH_SUCCESS:
    case actions.LOGIN_SUCCESS:
      const {
        user,
        twoFactor,
        dashboardName,
        tenantConfig,
        menuFilterSettings,
      } = action.results;
      // @todo @Attila, this is being called on save site config and blowing up.
      if (!user) return state;
      return {
        ...state,
        uuid: user.uuid,
        name: user.name,
        email: user.email,
        role: user.role,
        accountUuid: user.accountUuid,
        avatar: user.avatar,
        loading: false,
        twoFactor,
        dashboardName,
        ttg: user.ttg,
        group: user.group,
        groups: user.groups.map((v) => v.uuid),
        hasPages: !!tenantConfig?.pages?.length,
        menuFilterSettings: {
          ...emptyMenuFilterSettings,
          data: menuFilterSettings,
        },
      };

    case actions.LOGIN_COMPLETE:
      return {
        ...state,
        isAuthenticated: true,
        isAuthenticationComplete: true,
      };

    case actions.LOGIN_FAIL:
      return {
        ...state,
        loading: false,
        authErrors: action.errors,
      };

    case actions.LOGOUT_START:
      return {
        ...state,
        twoFactor: false,
        isAuthenticated: false,
        isAuthenticationComplete: false,
        authErrors: null,
      };

    case actions.CLEAR_AVATAR_ON_DELETE:
      return { ...state, avatar: null };

    case actions.GET_PUBLIC_CONFIG_SUCCESS: {
      return {
        ...state,
        tenantUuid: action.results.data.tenant.uuid,
      };
    }

    case actions.FORGOT_PASSWORD_START: {
      return { ...state, authErrors: null };
    }

    case actions.FORGOT_PASSWORD_FAIL: {
      return { ...state, authErrors: [action.error.message] };
    }

    case actions.FORGOT_PASSWORD_SUCCESS: {
      return { ...state, forgotComplete: true };
    }

    case actions.RESET_PASSWORD_START: {
      return { ...state, loading: true, authErrors: null };
    }

    case actions.RESET_PASSWORD_FAIL: {
      return {
        ...state,
        loading: false,
        authErrors: [action.error.message],
        error: action.error,
      };
    }

    case actions.RESET_PASSWORD_SUCCESS: {
      return { ...state, loading: false, resetComplete: true };
    }

    case actions.ACTIVATE_INVITE_START: {
      return { ...state, loading: true, error: null };
    }

    case actions.ACTIVATE_INVITE_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    case actions.ACTIVATE_INVITE_SUCCESS: {
      return { ...state, loading: false, resetComplete: true, error: null };
    }

    case actions.SAVE_MENU_FILTER_SETTINGS_START:
      return produce(state, (draft) => {
        draft.menuFilterSettings.loading = true;
        draft.menuFilterSettings.error = null;
      });

    case actions.SAVE_MENU_FILTER_SETTINGS_FAIL: {
      return produce(state, (draft) => {
        draft.menuFilterSettings.loading = false;
        draft.menuFilterSettings.error = action.error;
      });
    }

    case actions.SAVE_MENU_FILTER_SETTINGS_SUCCESS: {
      return produce(state, (draft) => {
        draft.menuFilterSettings.loading = false;
        draft.menuFilterSettings.error = null;
        draft.menuFilterSettings.data = action.results.data;
      });
    }

    case actions.CLEAR_LOGIN_ERRORS:
      return { ...state, authErrors: null, forgotComplete: false };

    case actions.GET_MY_PERMISSIONS_SUCCESS:
      localStorage.setItem(
        "myPermissions",
        JSON.stringify(action.results.data)
      );
      return {
        ...state,
        permissions: action.results.data,
      };

    default:
      return state;
  }
};
