import React, { useMemo, useCallback, useState } from "react";
import MenuFilter from "./MenuFilter";
import {
  FILTER_TYPE_CHECKBOX,
  emptyArray,
  FILTER_TYPE_DATE_PRESETS,
  FILTER_TYPE_MULTI_RANGE,
} from "../../utils/constants/constants";
import { useSelector } from "react-redux";
import useMemoDeepCompare from "../../utils/useMemoDeep";
import { buckets, TYPE_GROUPS } from "../../utils/constants/dataTypes";

export default function SingleDataSourceMenuFiltersItem({
  item,
  queryUuid,
  additionalTheme,
  dataSourceAccessConfig,
  select,
  loadSingleFieldByName,
  selectedValuesOfFields,
}) {
  const selectedValues = useSelector(
    (state) =>
      state.layout.singleQueryFiltersByQueryUuid[queryUuid]?.fieldsByName[
        item.fieldName
      ]?.selectedValues ?? emptyArray
  );
  const typeGroup = buckets[item.fieldType];
  const isDateLike = typeGroup === TYPE_GROUPS.date;
  const isNumberLike = typeGroup === TYPE_GROUPS.number;
  const [loading, setLoading] = useState(false);

  const loadValues = useMemo(() => {
    return !isDateLike
      ? async (otherFieldValues) => {
          try {
            setLoading(true);
            await loadSingleFieldByName(item.fieldName, otherFieldValues);
          } finally {
            setLoading(false);
          }
        }
      : null;
  }, [isDateLike, item.fieldName, loadSingleFieldByName]);

  const otherFieldValues = useMemoDeepCompare(
    useMemo(() => {
      return isNumberLike
        ? emptyArray
        : selectedValuesOfFields.filter(
            (selectedValuesOfField) =>
              selectedValuesOfField.key !== item.fieldName
          );
    }, [isNumberLike, item.fieldName, selectedValuesOfFields])
  );

  const filterType = useMemo(() => {
    const values =
      item.values?.map((value) => ({
        key: item.fieldName,
        type: item.fieldName,
        value,
        label: value,
        checked: selectedValues.includes(value),
      })) ?? (isDateLike ? [{ key: item.fieldName }] : null);

    return {
      type: isDateLike
        ? FILTER_TYPE_DATE_PRESETS
        : isNumberLike
        ? FILTER_TYPE_MULTI_RANGE
        : FILTER_TYPE_CHECKBOX,
      name: item.fieldName,
      displayName: item.fieldDisplayName,
      totalCount: values?.length ?? 0,
      values,
      loading,
      showEmptyValues: true,
    };
  }, [
    isDateLike,
    isNumberLike,
    item.fieldDisplayName,
    item.fieldName,
    item.values,
    loading,
    selectedValues,
  ]);

  // We wrap the select function to ensure that the
  // singleQueryFiltersUuid option is forwarded.
  const selectSingleQuery = useCallback(
    (filter, type, shouldReloadCharts, options = {}) => {
      return select(filter, type, shouldReloadCharts, {
        ...options,
        singleQueryFiltersUuid: queryUuid,
      });
    },
    [queryUuid, select]
  );

  return (
    <MenuFilter
      filterType={filterType}
      additionalTheme={additionalTheme}
      dataSourceAccessConfig={dataSourceAccessConfig}
      select={selectSingleQuery}
      loadValues={loadValues}
      selectedValues={selectedValues}
      otherFieldValues={otherFieldValues}
    />
  );
}
