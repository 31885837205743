import { stringify } from "qs";
import octane from "../octane";

export async function downloadFileFromOctane(fileUrl, signal) {
  const queryString = stringify({
    path: fileUrl,
  });

  return octane.get(`/api/warehouse-image?${queryString}`, {
    responseType: "arraybuffer",
    signal,
  });
}

/**
 * Source: https://stackoverflow.com/a/64908345/1381550
 *
 * @param {ArrayBuffer} data
 * @param {string} filename
 */
export function downloadFile(data, filename) {
  const a = document.createElement("a"); // Create "a" element
  const blob = new Blob([data], { type: "binary/octet-stream" }); // Create a blob (file-like object)
  const url = URL.createObjectURL(blob); // Create an object URL from blob
  a.setAttribute("href", url); // Set "a" element link
  a.setAttribute("download", filename); // Set download filename
  a.click(); // Start downloading
}
