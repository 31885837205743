import { useDispatch, useSelector } from "react-redux";
import useMemoDeepCompare from "../utils/useMemoDeep";
import { useMemo, useEffect, useRef } from "react";
import {
  cacheMenuFilterSelectedValues,
  loadMenuFilterValues,
  resetMenuFilterValues,
} from "../store/actions/layout";
import { pick } from "lodash-es";
import { emptyArray } from "../utils/constants/constants";

/**
 * Aka. menu filter dependencies.
 *
 * This hook needs to be called for each menu filter. When called, it makes
 * sure that if any parent dependencies' selected values change, an API call
 * is made to get the shortlist of items that is possible to select based on
 * the selected values from parents.
 *
 * @param menuFilter
 */
export default function useConditionalFilters(menuFilter) {
  const dispatch = useDispatch();
  const checkedValues = useMemoDeepCompare(
    useMemo(() => {
      return (
        menuFilter.values?.filter((v) => v.checked).map((v) => v.value) ?? []
      );
    }, [menuFilter.values])
  );

  useEffect(() => {
    dispatch(cacheMenuFilterSelectedValues(menuFilter.uuid, checkedValues));
  }, [checkedValues, dispatch, menuFilter.uuid]);

  const dependencyParentValuesRef = useRef([]);

  const dependencyParents = menuFilter.dependencyParents ?? emptyArray;
  const allMenuFiltersSelectedValuesByMenuFilterUuid = useSelector(
    (state) => state.layout.menuFiltersSelectedValuesByMenuFilterUuid
  );
  const menuFiltersSelectedValuesByMenuFilterUuid = useMemoDeepCompare(
    useMemo(() => {
      return pick(
        allMenuFiltersSelectedValuesByMenuFilterUuid,
        dependencyParents.map((v) => v.parentMenuFilterUuid)
      );
    }, [allMenuFiltersSelectedValuesByMenuFilterUuid, dependencyParents])
  );

  const dependencyParentValues = useMemo(() => {
    return dependencyParents
      .filter(
        (v) =>
          menuFiltersSelectedValuesByMenuFilterUuid[v.parentMenuFilterUuid]
            ?.length
      )
      .map(({ parentMenuFilterUuid }) => ({
        parentMenuFilterUuid,
        values:
          menuFiltersSelectedValuesByMenuFilterUuid[parentMenuFilterUuid].map(
            String
          ),
      }));
  }, [dependencyParents, menuFiltersSelectedValuesByMenuFilterUuid]);

  useEffect(() => {
    dependencyParentValuesRef.current = dependencyParentValues;

    if (dependencyParentValues.length) {
      dispatch(
        loadMenuFilterValues(menuFilter.uuid, dependencyParentValuesRef)
      );
    } else {
      dispatch(resetMenuFilterValues(menuFilter.uuid));
    }
  }, [dependencyParentValues, dispatch, menuFilter.uuid]);
}
